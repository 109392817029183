// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pids-wrapper {
  height: 100%;
  width: 100%;
  margin: 1px 0 1px 0;
}

.pid {
  height: 10%;
  width: 10px;
}

.inactive {
  background-color: transparent;
}

.active {
  background-color: #69ce2b;
}`, "",{"version":3,"sources":["webpack://./src/app/views/learn/volume-meter/volume-meter.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EAEA,mBAAA;AAAF;;AAGA;EACE,WAAA;EACA,WAAA;AAAF;;AAGA;EACE,6BAAA;AAAF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":[".pids-wrapper {\n  height: 100%;\n  width: 100%;\n\n  margin: 1px 0 1px 0;\n}\n\n.pid {\n  height: calc(10%);\n  width: 10px;\n}\n\n.inactive {\n  background-color: transparent;\n}\n\n.active {\n  background-color: #69ce2b;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
