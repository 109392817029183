// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  display: table;
  margin-top: 30px;
}

div.main {
  display: table-row;
}

div.main .nav {
  padding-top: 30px;
  height: max-content;
  position: static;
  vertical-align: top;
  display: table-cell;
  min-width: 15rem;
}

.status {
  font-size: 18pt;
  position: fixed;
  top: 10px;
}

div.main .content {
  padding-top: 30px;
  display: table-cell;
}

.tab-content {
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
}

.content div {
  align-items: flex-start;
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/app/views/main/main.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,iBAAA;EAEA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;;AAIA;EACI,eAAA;EACA,eAAA;EACA,SAAA;AADJ;;AAKA;EACI,iBAAA;EAEA,mBAAA;AAHJ;;AAMA;EACI,YAAA;EACA,WAAA;EAEA,uBAAA;EACA,2BAAA;EAEA,cAAA;AALJ;;AAQA;EACI,uBAAA;EACA,2BAAA;AALJ","sourcesContent":["body {\n    display: table;\n    margin-top: 30px;\n}\n\ndiv.main {\n    display: table-row;\n}\n\ndiv.main .nav {\n    padding-top: 30px;\n    \n    height: max-content;\n    position: static;\n    vertical-align: top;\n    display: table-cell;\n    min-width: 15rem;\n}\n\n\n.status {\n    font-size: 18pt;\n    position: fixed;\n    top: 10px;\n  }\n\n  \ndiv.main .content {\n    padding-top: 30px;\n    \n    display: table-cell;\n}\n\n.tab-content {\n    height:100%;\n    width: 100%;\n    \n    align-items: flex-start;\n    justify-content: flex-start;\n\n    overflow: auto;\n}\n\n.content div {\n    align-items: flex-start;\n    justify-content: flex-start;\n}\n\n.tab-pane {\n    @extend .flex-fill !optional;\n }\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
