import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FirmwareInfo } from '../../model/firmware-info';
import { FlasherComponent } from './flasher.component';

export enum FlasherType {
  SAMBA,

  NINA
}

@Injectable({
  providedIn: 'root'
})
export class FlasherService {

  constructor(
    public modalService: NgbModal) { }

  get isFlasherOpen() : boolean {
    return (this._modalRef != undefined);
  }

  private _modalRef? : NgbModalRef = undefined;
  
  async openFlasher(firmwares: FirmwareInfo[], type: FlasherType = FlasherType.SAMBA) : Promise<FlasherComponent> {

    if (!this._modalRef) {

      this._modalRef = this.modalService.open(FlasherComponent, { centered: true, size: 'xl', backdrop  : 'static', container: "#fullscreenContainer"  });
      let instance = <FlasherComponent>this._modalRef.componentInstance;

      instance.flasherType = type;
      instance.closeCallback = this.closeFlasher.bind(this);
      instance.availableFirmwares = firmwares;
      instance.prepare();
      
      this._modalRef.result.then(
        () => { 
          this._modalRef = undefined; 
        }, 
        () => { 
          this._modalRef = undefined; 
        });

      return instance;
    }

    throw Error("Flasher-Modal is already open.");
  }  

  async closeFlasher() {
    if (this._modalRef) {
      this._modalRef.close();
      this._modalRef = undefined;
    }
  }


}
