import { rootModel } from "../helpers/log-config";
import { UNCType } from "./uncsettings";
import { Type, instanceToPlain, plainToInstance, Expose } from 'class-transformer';

const log = rootModel.getChildCategory("recording");

export interface RecordingEvent {
  
  readonly time: DOMHighResTimeStamp;

  get startTime() : DOMHighResTimeStamp;

  get endTime(): DOMHighResTimeStamp;

  readonly measure: number;

  setMeasure(measure: number) : number;

  // Perform post-recording operations on the recorded events
  process(index: number, events: RecordingEvent[]) : void;
}

export abstract class EventBase implements RecordingEvent {

  protected _measure: number = -1;

  get measure(): number {
    return this._measure;
  }

  constructor(time: DOMHighResTimeStamp = performance.now()) {
    this.time = time;
    this._startTime = time;
  }

  protected _startTime: number;

  get startTime() : number {
    return this.time;
  }

  protected _duration: number = 0;

  get duration() : number {
    return this._duration;
  }

  get endTime(): number {
    return this._startTime + this._duration;
  }

  setMeasure(measure: number): number {
    this._measure = measure;

    return this.measure;
  }

  abstract process(index: number, events: RecordingEvent[]): void;

  readonly time: DOMHighResTimeStamp;
}

export abstract class NoteBaseEvent extends EventBase {

  readonly note: number;

  get isTone() : boolean {
    return this.note != -1;
  }

  constructor(note: number = -1, time: DOMHighResTimeStamp = performance.now()) {
    super(time);

    this.note = note; 
  }
}

export class NoteEvent extends NoteBaseEvent {

  readonly isNoteOn: boolean;

  private _startNoteIndex: number = -1;

  public get startNoteIndex(): number {
    return this._startNoteIndex;
  }

  public set startNoteIndex(value: number) {
    this._startNoteIndex = value;
  }

  constructor(note: number = -1, time: DOMHighResTimeStamp = performance.now()) {
    super(note, time);

    this.isNoteOn = (note != -1);
  }

  process(index: number, events: RecordingEvent[]): void {

    if (this.isNoteOn) {
      var noteOffIndex = events.findIndex( (value, valueIndex) => {
        return (valueIndex > index) 
            && (value instanceof NoteEvent) 
            && (!value.isNoteOn);
      });

      if (noteOffIndex != -1) {
        var eventNoteOff = events[noteOffIndex] as NoteEvent;
        
        this._duration = eventNoteOff.time - this.time; 

        eventNoteOff.startNoteIndex = index;
        eventNoteOff._duration = this._duration;
        eventNoteOff._startTime = this._startTime;
      }
    }
  }
}

export class ListenedNoteEvent extends NoteBaseEvent {

  constructor(note: number, time: DOMHighResTimeStamp = performance.now(), duration: number = -1) {
    super(note, time);

    this._duration = duration;
  }

  process(index: number, events: RecordingEvent[]): void {
  }
}

export class MetronomeEvent extends EventBase {

  readonly tick: number;

  setMeasure(measure: number): number {
    return this.measure;
  }

  constructor(measure: number, tick: number, time: DOMHighResTimeStamp = performance.now()) {
    super(time);

    this._measure = measure;
    this.tick = tick;
  }

  get endTime(): number {
    return this.time;
  }

  process(index: number, events: RecordingEvent[]): void {
  }
}

export class BreathEvent extends EventBase {

  readonly pressure: number;

  constructor(pressure: number, time: DOMHighResTimeStamp = performance.now()) {
    super(time);

    this.pressure = pressure;
  }

  process(index: number, events: RecordingEvent[]): void {

    var nextChangeIndex = events.findIndex( (value, valueIndex) => {
        return (valueIndex > index) 
            && (value instanceof BreathEvent); 
      });

    if (nextChangeIndex != -1) {
      var nextBreath = events[nextChangeIndex] as BreathEvent;
        
      this._duration = nextBreath.time - this.time; 
    }    
  }
}

export class ScoreEvent extends NoteBaseEvent {

  constructor(note: number, duration: number, time: DOMHighResTimeStamp = performance.now()) {
    super(note, time);

    this._duration = duration;
  }

  process(index: number, events: RecordingEvent[]): void {
  }
}

export class KeyEvent extends EventBase {

  readonly keys: string;

  readonly uncType: UNCType;

  constructor(keys: string, uncType: UNCType, time: DOMHighResTimeStamp = performance.now()) {
    super(time);

    this.keys = keys;
    this.uncType = uncType;
  }

  get endTime(): number {
    return this.time;
  }

  process(index: number, events: RecordingEvent[]): void {

    var nextChangeIndex = events.findIndex( (value, valueIndex) => {
      return (valueIndex > index) 
          && (value instanceof KeyEvent); 
    });

    if (nextChangeIndex != -1) {
      var nextEvent = events[nextChangeIndex] as KeyEvent;
        
      this._duration = nextEvent.time - this.time; 
    } 
  
  }
}

export class AnnotationEvent extends EventBase {

  readonly annotation: string;

  constructor(annotation: string, fromTime: DOMHighResTimeStamp = performance.now(), toTime: DOMHighResTimeStamp = performance.now() + 1000) {
    super(fromTime);

    this.annotation = annotation;
    this._duration = toTime - fromTime;
  }

  process(index: number, events: RecordingEvent[]): void {

  }
}

export class Recording {

  @Expose({ name: 'id' })
  private _id: string = "";
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  
  @Expose({ name: 'score_id' })
  private _scoreId: string = "";
  public get scoreId(): string {
    return this._scoreId;
  }
  public set scoreId(value: string) {
    this._scoreId = value;
  }

  @Expose({ name: 'date' })
  private _date: Date = new Date();
  public get date(): Date {
    return this._date;
  }
  public set date(value: Date) {
    this._date = value;
  }

  // Sequential list of events
  @Type(() => EventBase, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: NoteEvent, name: 'note' },
        { value: MetronomeEvent, name: 'metronome' },
        { value: KeyEvent, name: 'key' },
        { value: BreathEvent, name: 'breath' },
        { value: ScoreEvent, name: 'score' },
        { value: ListenedNoteEvent, name: 'listen' },
        { value: AnnotationEvent, name: 'anno' },
        { value: String, name: 'str' }
      ],
    },
  })
  @Expose({name: 'events'})
  private _events : RecordingEvent[] = [];

  get events(): ReadonlyArray<RecordingEvent> {
    return this._events;
  }

  interval(start: number, duration: number) : ReadonlyArray<RecordingEvent> {
    return this._events.filter( event => ((event.startTime <= (start + duration)) && (event.endTime >= start)) );
  }
  
  private _isProcessed : boolean = false;

  private _isRecording : boolean = false;

  get isProcessed(): boolean {
    return this._isProcessed;
  }

  get isRecording(): boolean {
    return this._isRecording;
  }

  private _firstTimestamp: number = -1;

  public get firstTimestamp(): number {
    return this._firstTimestamp;
  }
  
  private _lastTimestamp: number = -1;

  public get lastTimestamp(): number {
    return this._lastTimestamp;
  }

  public get duration(): number {
    if (this._firstTimestamp == -1) {
      return -1;
    }

    return (this._lastTimestamp - this._firstTimestamp);
  }

  private _lastNote : NoteEvent | null = null;

  public toJSON() : string {
    return JSON.stringify(instanceToPlain(this));
  }

  public static fromJSON(js : string): Recording | null {
    let result = plainToInstance(Recording, js);

    if (result) {
      result._isProcessed = false;
      result.postProcess();
    }
    return result;
  }

  // Put an event into the event queue and maintain
  // additional indexing.
  public addEvent(event: RecordingEvent) {

    if (!this._isRecording) {
      return;
    }

    if (event instanceof NoteEvent) {
      let ne = event as NoteEvent;

      if (ne.isNoteOn) {
        // Double NOTE ON, need to store a NOTE OFF a little beforehand
        if (this._lastNote) {
          this.addEvent(new NoteEvent(-1, ne.time - 0.00000000001));
        }
        else {
          this._lastNote = ne;
        }
      }
      else {
        // Ignore a NOTE OFF without a current NOTE
        if (this._lastNote == null) {
          return;
        }
        else {
          this._lastNote = null;
        }
      }
    }

    if (this._firstTimestamp === -1) {
      this._firstTimestamp = event.time;
    }
    else {
      this._firstTimestamp = Math.min(this._firstTimestamp, event.time);
    }

    this._lastTimestamp = Math.max(this._lastTimestamp, event.time);

    this._events.push(event);
  }

  public startRecording() : boolean {
    if (this._isProcessed) {
      return false;
    }

    if (!this._isRecording) {
      // this._firstTimestamp = performance.now();
    }

    this._lastNote = null;
    this._isRecording = true;

    return true;
  }

  // Post-Processing of the stored data to provide 
  // duration values and associations between the events.
  public stopRecording() {

    // Protect against double stops
    if (!this._isRecording) {
      return;
    }

    // Still on NOTE ON, need to store a NOTE OFF beforehand
    if (this._lastNote) {
      this.addEvent(new NoteEvent());
    }
    
    this._isRecording = false;
    this._lastTimestamp = performance.now();

    this.postProcess();
  }

  protected postProcess() {
    if (this._isProcessed) {
      return;
    }

    // Ensure all events are in the right sequence
    this._events.sort((a, b) => a.time - b.time);

    var measure = 0;
    this._events.forEach( (event, index, events) => {
      measure = event.setMeasure(measure);
      event.process(index, events);
    });

    this._isProcessed = true;
  }
}
