import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { rootService } from 'src/app/helpers/log-config';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { VERSION } from 'src/environments/version';
import { compareVersions } from '../../helpers/version-helpers';


const log = rootService.getChildCategory("connmainection");

interface ChangeLogEntry {
  version: string;
  name?: string;
  description?: string;
  
  changes?: string[];
  internal?: string[]; 
}

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {

  url: string = '/assets/revision-log.json';

  private _changeInfos: ChangeLogEntry[] = [];

  version = VERSION;

  constructor(
    public fs: FeatureSupportService,
    protected cd: ChangeDetectorRef,
    protected http: HttpClient,
    protected modalService: NgbModal) { }

  ngOnInit(): void {
      this.http.get<ChangeLogEntry[]>(this.url).subscribe(res => {
        this._changeInfos = res;
        this.cd.detectChanges();
      });
  }

  get changes() : ChangeLogEntry[] {

    let result = this._changeInfos;

    if (!this.disclosureMode) {
      // Remove internal information
      result = this._changeInfos.map<ChangeLogEntry>( value => { return { version: value.version, name: value.name, description: value.description, changes: value.changes }});
    } 

    result = result.sort( (a, b) => { return this.compareVersions(a.version, b.version) });

    return result;
  }

  private compareVersions(left: string, right: string) : number {

    if (left == 'latest') return -1;
    if (right == 'latest') return 1;
  
    return compareVersions(left, right);
  }

  get disclosureMode(): boolean {
    return this.fs.developmentMode || this.fs.maintenanceMode;
  }
  
  close() {
    this.modalService.dismissAll();
  }
}
