export interface CCType {

    name: string;
    
    code: number;
} 

export const MidiCCTypes: CCType[] = [

    { name: 'Bank Select (MSB)', code: 0 },
    { name: 'Modulation Wheel', code: 1 },
    { name: 'Breath controller', code: 2 },
    { name: 'Foot Pedal (MSB)', code: 4 },
    { name: 'Portamento Time (MSB)', code: 5 },
    { name: 'Data Entry (MSB)', code: 6 },
    { name: 'Volume (MSB)', code: 7 },
    { name: 'Balance (MSB)', code: 8 },
    { name: 'Pan position (MSB)', code: 10 },
    { name: 'Expression (MSB)', code: 11 },
    { name: 'Effect Control 1 (MSB)', code: 12 },
    { name: 'Effect Control 2 (MSB)', code: 13 },
    { name: 'General Purpose', code: 16 },
    { name: 'General Purpose', code: 17 },
    { name: 'General Purpose', code: 18 },
    { name: 'General Purpose', code: 19 },
    { name: 'Controller 0', code: 32 },
    { name: 'Controller 1', code: 33 },
    { name: 'Controller 2', code: 34 },
    { name: 'Controller 3', code: 35 },
    { name: 'Controller 4', code: 36 },
    { name: 'Controller 5', code: 37 },
    { name: 'Hold Pedal (on/off)', code: 64 },
    { name: 'Portamento (on/off)', code: 65 },
    { name: 'Sostenuto Pedal (on/off)', code: 66 },
    { name: 'Soft Pedal (on/off)', code: 67 },
    { name: 'Legato Pedal (on/off)', code: 68 },
    { name: 'Hold 2 Pedal (on/off)', code: 69 },
    { name: 'Sound Variation', code: 70 },
    { name: 'Resonance (Timbre)', code: 71 },
    { name: 'Sound Release Time', code: 72 },
    { name: 'Sound Attack Time', code: 73 },
    { name: 'Frequency Cutoff (Brightness)', code: 74 },
    { name: 'Sound Control 6', code: 75 },
    { name: 'Sound Control 7', code: 76 },
    { name: 'Sound Control 8', code: 77 },
    { name: 'Sound Control 9', code: 78 },
    { name: 'Sound Control 10', code: 79 },
    { name: 'Decay or General Purpose Button 1 (on/off) Roland Tone level 1', code: 80 },
    { name: 'Hi Pass Filter Frequency or General Purpose Button 2 (on/off) Roland Tone level 2', code: 81 },
    { name: 'General Purpose Button 3 (on/off) Roland Tone level 3', code: 82 },
    { name: 'General Purpose Button 4 (on/off) Roland Tone level 4', code: 83 },
    { name: 'Portamento Amount', code: 84 },
    { name: 'Reverb Level', code: 91 },
    { name: 'Tremolo Level', code: 92 },
    { name: 'Chorus Level', code: 93 },
    { name: 'Detune Level', code: 94 },
    { name: 'Phaser Level', code: 95 },
    { name: 'Data Button increment', code: 96 },
    { name: 'Data Button decrement', code: 97 },
    { name: 'Non-registered Parameter (LSB)', code: 98 },
    { name: 'Non-registered Parameter (MSB)', code: 99 },
    { name: 'Registered Parameter (LSB)', code: 100 },
    { name: 'Registered Parameter (MSB)', code: 101 },
    { name: '-119 = Undefined', code: 102 },
    { name: 'All Sound Off', code: 120 },
    { name: 'All Controllers Off', code: 121 },
    { name: 'Local Keyboard (on/off)', code: 122 },
    { name: 'All Notes Off', code: 123 },
    { name: 'Omni Mode Off', code: 124 },
    { name: 'Omni Mode On', code: 125 },
    { name: 'Mono Operation', code: 126 },
    { name: 'Poly Mode', code: 127 },
];
