// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fingering {
  height: 90vh;
  display: block;
  margin: 20px;
}

.closeButton {
  position: absolute;
  right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/fingering-modal/fingering-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;AACF","sourcesContent":[".fingering {\n  height: 90vh;\n  display: block;\n  margin: 20px;\n}\n\n.closeButton {\n  position: absolute;\n  right: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
