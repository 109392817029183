import { Injectable } from '@angular/core';
import { FeatureSupportService } from '../feature-support.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SerialSelectionComponent } from 'src/app/views/electron/serial-selection/serial-selection.component';
import { PreparationCallback } from 'src/app/views/report/report.component';

@Injectable({
  providedIn: 'root'
})
export class SerialSelectionService {

  constructor(
    protected fs: FeatureSupportService,
    protected ms: NgbModal) { 

  }


  get isFlasherOpen() : boolean {
    return (this._modalRef != undefined);
  }

  private _modalRef? : NgbModalRef = undefined;

  async openSelectionDialog(portList: PortInfo[]) : Promise<SerialSelectionComponent> {
    return this.openDialog(portList);
  }

  private async openDialog(portList: PortInfo[]) : Promise<SerialSelectionComponent> {

    if (!this._modalRef) {

      this._modalRef = this.ms.open(SerialSelectionComponent, { centered: true, size: 'md', backdrop  : 'static', container: "#fullscreenContainer"  });
      let instance = <SerialSelectionComponent>this._modalRef.componentInstance;

      instance.closeCallback = this.closeDialog.bind(this);
      instance.setPortList(portList);

      this._modalRef.result.then( 
        () => { 
          this._modalRef = undefined; 
        }, 
        () => { 
          this._modalRef = undefined; 
        });

      return instance;
    }

    throw Error("Serial-Selection-Modal is already open.");
  }  

  async closeDialog() {
    if (this._modalRef) {
      this._modalRef.close();
      this._modalRef = undefined;
    }

    // Notifiy the main loop that no port was selected
    window.ipcRenderer.send('serial:portSelected', '');
  }
}
