import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ListenerService } from 'src/app/services/listener.service';

@Component({
  selector: 'app-visualizer',
  templateUrl: './visualizer.component.html',
  styleUrls: ['./visualizer.component.scss']
})
export class VisualizerComponent implements AfterViewInit {

  constructor(
    protected ls: ListenerService) {
  }

  @ViewChild('visualizer')
  visualizer!: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit(): void {  
    const canvas = this.visualizer.nativeElement;

    let width = canvas.clientWidth;
    let height = canvas.clientHeight;

    if (width * height == 0) {

      let self = this;
      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          this.ls.targetCanvas = canvas;
        });
      });
      
      observer.observe(canvas);
      return;
    } 

    this.ls.targetCanvas = canvas;
  }

  toggleStyle() {
    this.ls.visualizerStyle = (this.ls.visualizerStyle === 'sine' ? 'bar' : 'sine' );
  }
}
