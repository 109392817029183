import { EmeoDiagramClarinetV1Component } from "src/app/svg/emeo-clarinet-v1/emeo-diagram-clarinet-v1.component";
import { EmeoClarinetComponent } from "src/app/views/emeo-clarinet/emeo-clarinet.component";
import { InstrumentInfoEx } from "../instrument-info-ex";
import { EMEO_CLARINET_V1_INFO } from "./emeo-clarinet-v1";


export const EMEO_CLARINET_V1_INFO_EX : InstrumentInfoEx = {
  
  ...EMEO_CLARINET_V1_INFO,

  fingeringDiagram: EmeoDiagramClarinetV1Component,
  instrumentComponent: EmeoClarinetComponent,
} 