
export class VolumeNode extends AudioWorkletNode {


  constructor(context: AudioContext) {
    super(
      context, 
      "volume-processor", 
      {
        numberOfInputs: 1,
        outputChannelCount: [1],
      });
  }

}

export interface VolumeMessage {
  volume: number;
}