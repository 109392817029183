import { EMEO_SAX_V1 } from "../device-info"
import { FingeringInfo, InstrumentInfo, KeyMap } from 'src/app/model/unc-types';

const DefaultFingerings_Sax_V1: FingeringInfo = {
  "XX0" : 44,                       
  "B:C:D:E:F:LowC:PinkyBb": 45, // A#, Bb
  "B:C:D:E:F:G:LowC:PinkyBb": 46,
  "B:C:D:E:F:G:LowC:PinkyB": 47,
  "B:C:D:E:F:G:LowC": 48,
  "B:C:D:E:F:G:LowC:PinkyCSharp": 49,
  "B:C:D:E:F:G": 50,
  "B:C:D:DSharp:E:F:G": 51,
  "B:C:E:F:G": 52,
  "B:C:F:G": 53,
  "B:C:E:G": 54,
  "B:C:G": 55,
  "B:C:E:F:G:PinkyGSharp": 56,
  "B:C": 57,
  "B:BFLat": 58,
  "B:C:PalmBFlat": 58,
  "B": 59,
  "C": 60,
  "": 61,
  "B:C:D:E:F:G:Octave": 62,
  "B:C:D:DSharp:E:F:G:Octave": 63,
  "B:C:E:F:G:Octave": 64,
  "B:C:F:G:Octave": 65,
  "B:C:E:G:Octave": 66,
  "B:C:G:Octave": 67,
  "B:C:G:Octave:PinkyGSharp": 68,
  "B:C:Octave": 69,
  "B:BFLat:Octave": 70,
  "B:C:PalmBFlat:Octave": 70,  
  "B:Octave": 71,
  "C:Octave": 72,
  "Octave": 73,
  "HighD:Octave": 74,
  "HighEFlat:Octave": 75,
  "HighF:Octave": 76,
  "HighD:HighE:HighEFlat:HighF:Octave": 77,
  "HighD:HighE:HighEFlat:HighF:AddtlFSharp:Octave": 78,                      // fis’’’/ges’’’
  "B:D:F:G:Octave:PalmBFlat": 79,
  "B:D:F:G:Octave:PalmC": 80,
  "B:C:G:Octave:PalmC": 81,
  "C:G:HighD:Octave": 82,
  "PalmC:Octave:G": 83, // ais'''
}


const KeyMap_Sax_V1: KeyMap = {
  0: { name: 'PalmC', active: true, sensor: 17 },
  1: { name: 'Octave', active: false, sensor: 18 },
  2: { name: 'HighD', active: true, sensor: 21 },
  3: { name: 'HighEFlat', active: true, sensor: 20 },
  4: { name: 'AddtlFSharp', active: true, sensor: 19 },
  5: { name: 'PalmBFlat', active: true, sensor: 16 },
  6: { name: 'F', active: false, sensor: 10 },
  7: { name: 'PinkyCSharp', active: false, sensor: 12, connectedKeys: [ 'PinkyGSharp' ] },
  8: { name: 'D', active: false, sensor: 13 },
  9: { name: 'LowC', active: false, sensor: 14 },
  10: { name: 'E', active: false, sensor: 11 },
  11: { name: 'DSharp', active: true, sensor: 15 },
  12: { name: 'G', active: false, sensor: 8 },
  13: { name: 'PinkyGSharp', active: true, sensor: 9 },
  14: { name: 'Dunno', active: false },
  15: { name: 'B', active: false, sensor: 5 },
  16: { name: 'BFlat', active: false, sensor: 6 },
  17: { name: 'C', active: false, sensor: 7, connectedKeys: [ 'BFlat' ] },
  18: { name: 'HighE', active: true, sensor: 3 },
  19: { name: 'HighF', active: true, sensor: 2 },
  20: { name: 'PinkyB', active: false, sensor: 1, connectedKeys: [ 'PinkyGSharp' ] },
  21: { name: 'PinkyBb', active: false, sensor: 0, connectedKeys: [ 'PinkyGSharp', 'PinkyB' ] },
  22: { name: 'AddtlHighF', active: true, connectedKeys: [ 'HighF', 'B' ] },
}

export const EMEO_SAX_V1_INFO : InstrumentInfo = {

  name: "EMEO Practice Saxophone",

  hardware: EMEO_SAX_V1,

  keyMap: KeyMap_Sax_V1,

  defaultFingerings: DefaultFingerings_Sax_V1,

  devices: [ EMEO_SAX_V1 ],
}