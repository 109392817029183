// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1, h2, h3 {
  padding: 20px;
  background-color: #444;
}

.card-footer {
  padding: 20px;
  align-items: flex-end;
  vertical-align: bottom !important;
  background-color: transparent;
}

.card-footer button {
  align-items: flex-end;
}

.card-header {
  display: none;
}

.card-body {
  height: 17rem;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/report/report.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;AAAF;;AAIA;EACE,aAAA;EAEA,qBAAA;EACA,iCAAA;EACA,6BAAA;AAFF;;AAKA;EACE,qBAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,aAAA;EACA,eAAA;AAFF","sourcesContent":["\nh1, h2, h3  {\n  padding: 20px;\n  background-color: #444;\n}\n\n\n.card-footer {\n  padding: 20px;\n  \n  align-items: flex-end;\n  vertical-align: bottom !important;\n  background-color: transparent;\n}\n\n.card-footer button {\n  align-items: flex-end;\n}\n\n.card-header {\n  display: none;\n}\n\n.card-body {\n  height: 17rem;\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
