
import { Md5 } from 'ts-md5/dist/md5';
import { InstrumentConnection, InstrumentInterface, MidiTimeoutError } from '../model/instrument-connection';
import { hex } from './helper';

// Timeouts
const DEFAULT_TIMEOUT = 3000; // timeout for most flash operations
const SYNC_TIMEOUT = 100; // timeout for syncing with bootloader

const TIMEOUT_QUICK   = 100;
const TIMEOUT_NORMAL  = 1000;
const TIMEOUT_LONG    = 5000;

export type LoaderOptions = {
  logger: Logger;
  debug: boolean;
  trace: boolean;
};

export interface Logger {
  debug(message?: unknown, ...optionalParams: unknown[]): void;
  log(message?: unknown, ...optionalParams: unknown[]): void;
  error(message?: unknown, ...optionalParams: unknown[]): void;
}

type hashResolve = (hash: Uint8Array | null) => void;
type hashReject = (reason?: any) => void;

class Uint8Buffer {
  private readOffset = 0;
  private writeOffset = 0;
  private size: number;

  private _buffer: ArrayBuffer;
  private _view: Uint8Array;

  constructor(size = 64) {
    this.size = size;
    this._buffer = new ArrayBuffer(this.size);
    this._view = new Uint8Array(this._buffer);
  }

  get length(): number {
    return this.writeOffset - this.readOffset;
  }

  shift(): number | undefined {
    if (this.length <= 0) {
      return undefined;
    }
    return this._view[this.readOffset++];
  }

  private grow(newSize: number) {
    const newBuffer = new ArrayBuffer(newSize);
    const newView = new Uint8Array(newBuffer);
    this._view.forEach((v, i) => (newView[i] = v));
    this.size = newSize;
    this._buffer = newBuffer;
    this._view = newView;
  }

  fill(element: number, length = 1): void {
    this.ensure(length);
    this._view.fill(element, this.writeOffset, this.writeOffset + length);
    this.writeOffset += length;
  }

  private ensure(length: number) {
    if (this.size - this.writeOffset < length) {
      const newSize = this.size + Math.max(length, this.size);
      this.grow(newSize);
    }
  }

  pushBytes(value: number, byteCount: number, littleEndian: boolean) {
    for (let i = 0; i < byteCount; i++) {
      if (littleEndian) {
        this.push((value >> (i * 8)) & 0xff);
      } 
      else {
        this.push((value >> ((byteCount - 1 - i) * 8)) & 0xff);
      }
    }
  }

  reset(): void {
    this.writeOffset = 0;
    this.readOffset = 0;
  }

  push(...bytes: number[]): void {
    this.ensure(bytes.length);
    this._view.set(bytes, this.writeOffset);
    this.writeOffset += bytes.length;
  }

  copy(bytes: Uint8Array): void {
    this.ensure(bytes.length);
    this._view.set(bytes, this.writeOffset);
    this.writeOffset += bytes.length;
  }

  view(): Uint8Array {
    return new Uint8Array(this._buffer, this.readOffset, this.writeOffset);
  }
}

export class NINAError extends Error {

  constructor(msg: string) {
    super(msg);
  }
}

export interface NINAObserver {
  onStatus(message: string): void;
  onProgress(num: number, div: number): void;
}

export class NINAFlasher {

  private nina: NINA;

  private logger: Logger;

  private observer: NINAObserver;
  
  constructor(nina: NINA, logger: Logger, observer: NINAObserver) {
    this.nina = nina;
    this.logger = logger;
    this.observer = observer;
  }

  async flashFirmware(firmware: Uint8Array) : Promise<void> {
    
    this.progress(5, "Connecting to programmer...");

    try {
      this.nina.startFlashing();;

      await this.nina.hello();
      let maxPayload = await this.nina.getMaximumPayload();
  
      let size = firmware.length;
      var address = 0x00000000;
      var written = 0;
  
      this.progress(10, "Erasing target...");
      await this.nina.eraseFlash(address, size);
  
      while (written < size) {
        this.progress(10 + written * 70 / size, "Programming " + size + " bytes ...");
        var len = maxPayload;
        if ((written + len) > size) {
          len = size - written;
        }
  
        await this.nina.writeFlash(address, firmware.slice(written, written + len));
        written += len;
        address += len;
      }
      
      address = 0x00000000;
      let md5rec = await this.nina.md5Flash(address, size);
      this.progress(85, "Verifying...");
      let md5Checksum = getMD5Checksum(firmware);
  
      this.progress(95, "Verifying...");
      if (this.arraysEqual(md5rec, new Uint8Array(md5Checksum.buffer))) {
        this.progress(100, "Done!");
      } 
      else {
         throw new NINAError("Error validating flashed firmware");
      }
    }
    catch (e) {
      console.log("Error executing NINA flasher. ", e);
    }
    finally {
      this.nina.leaveNinaFlasher();
    }
  }

  private arraysEqual(a: Uint8Array | null, b: Uint8Array | null) : boolean {
    if (a === b) return true;

    if (a === null) return false;
    if (b === null) return false;

    return (a.length === b.length)
        && (a.every((el, ix) => el === b[ix]));
  }

  private progress(percent: number, action: string) {
    this.logger.log("Flashing NINA at " + percent + "%: " + action);
    this.observer.onProgress(percent, 100);
    this.observer.onStatus(action);
  }
}

export function getMD5Checksum(buffer: Uint8Array) : Int32Array {
  let md5 = new Md5();
  md5.appendByteArray(buffer);
  // Generate the MD5 value 
  return md5.end(true) as Int32Array;
}

type progressCallback = (i: number, total: number) => void;

enum NINACommand {

  CMD_READ_FLASH = 0x01,
  CMD_WRITE_FLASH = 0x02,
  CMD_ERASE_FLASH = 0x03,
  CMD_MD5_FLASH = 0x04,
  CMD_MAX_PAYLOAD_SIZE = 0x50,
  CMD_HELLO = 0x99,

  // EMEO Specific command
  CMD_EXIT_FLASHER = 0x40
}

export class NINA {

  private options: LoaderOptions;

  get writeBufferSize() { return 4096; }

  constructor(
    private connection: InstrumentConnection, 
    options?: Partial<LoaderOptions>) {

    this.options = Object.assign(
      {
        logger: console,
        debug: false,
        trace: false
      },
      options || {}
    );
  }

  private get logger(): Logger {
    return this.options.logger;
  }

  startFlashing() {
    this.connection.blockRefresh = true;
  }

  finishFlashing() {
  }

  /*
  [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x7f, 0xf7 ] FAILED
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x01, 0xf7  READY 
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x02, 0xf7  QUIT - RESET
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x03, 0xf7  HELLO  (V10000)
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x04, 0xf7  ERROR READ FLASH
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x05, 0xf7  ERROR WRITE FLASH
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x06, 0xf7  OK WRITE FLASH
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x07, 0xf7  ERROR ERASING FLASH
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x08, 0xf7  OK ERASING FLASH
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x09, 0xf7  ERROR MD5 CRC FLASH
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x0A, 0xf7  ERROR ESP32ROM SERIAL
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x0B, 0xf7  ERROR FLASHING MEMORY
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x0C, 0xf7  SUCCES FLASHING MEMORY
  0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x0D, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0xf7 CHECKSUM
*/

	async hello() : Promise<void> {

    return new Promise( async (resolve, reject) => {

      this.connection.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x03 ],
        (data: Uint8Array, receivedTime: number | undefined) => {
          resolve();
        },
        () => {
          reject(new MidiTimeoutError("NINA-HELLO"));
        },
        DEFAULT_TIMEOUT);

        await this.sendCommand(NINACommand.CMD_HELLO, 0x11223344, 0x55667788);
    });
	}

  async writeFlash(address: number, data: Uint8Array) : Promise<void> {

    return new Promise( async (resolve, reject) => {

      this.connection.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x06 ],
        (data: Uint8Array, receivedTime: number | undefined) => {
          resolve();
        },
        () => {
          reject(new NINAError("Error while writing flash memory."));
        },
        1000);

        await this.sendCommand(NINACommand.CMD_WRITE_FLASH, address, 0, data);
    });
	}

	async eraseFlash(address: number, size: number) : Promise<void> {

    return new Promise( async (resolve, reject) => {

      this.connection.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x08 ],
        (data: Uint8Array, receivedTime: number | undefined) => {
          resolve();
        },
        () => {
          reject(new NINAError("Error while erasing flash memory."));
        },
        20000);

        await this.sendCommand(NINACommand.CMD_ERASE_FLASH, address, size, undefined);
    });
	}

  async leaveNinaFlasher() : Promise<void> {
    await this.sendCommand(NINACommand.CMD_EXIT_FLASHER, 0x11223344, 0x55667788);

    this.connection.blockRefresh = false;
  }

  private decode7BitHash( encoded: Uint8Array) : Uint8Array {

    let result = new Uint8Buffer();
    var bits = 7;
  
    for (var i = 0; i < encoded.length - 1; i++) {
      let value = (encoded[i] >> (7-bits)) | (encoded[i+1] & (0xff >> bits)) << bits;
  
      if (bits != 0) {
        result.push(value);
      }
  
      bits = (bits + 7) & 0x07;
    }
  
    return result.view();
  }

  private waitForMd5Hash(resolve: hashResolve, reject: hashReject) {

    this.connection.onceSysexWithHeader(
      [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x0d ],
      (data: Uint8Array, receivedTime: number | undefined) => {

        if (data.length >= 26) {

          let result = this.decode7BitHash(data.slice( 0x0a, 0x0a + 19 ));

          resolve( result );
        }
        else {
          reject(new NINAError("Invalid response for md5 checksum."));
        }
      },
      () => {
        reject(new NINAError("Error while computing md5 checksum."));
      },
      12000);

  }
  async md5Flash(address: number, length: number) : Promise<Uint8Array | null> {
		
    return new Promise( async (resolve, reject) => {

      this.connection.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x0c ],
        (data: Uint8Array, receivedTime: number | undefined) => {
          this.waitForMd5Hash(resolve, reject);
        },
        () => {
          reject(new NINAError("Error finishing flash operation."));
        },
        6000);

        await this.sendCommand(NINACommand.CMD_MD5_FLASH, address, length, undefined);
    });    

		return null;
	}

	async getMaximumPayload() : Promise<number> {
		
    return new Promise( async (resolve, reject) => {

      this.connection.onceSysexWithHeader(
        [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x20, 0x00, 0x0e ],
        (data: Uint8Array, receivedTime: number | undefined) => {

          if (data.length >= 12) {
            resolve( data[ 0x0a ] << 7 + data[ 0x0b ]);
          }
          else {
            reject(new NINAError("Invalid response for maximum payload."));
          }
        },
        () => {
          reject(new NINAError("Error while getting maximum payload."));
        },
        500);

        await this.sendCommand(NINACommand.CMD_MAX_PAYLOAD_SIZE, 0, 0, undefined);
    });    
	}

  private async sendCommand(cmd: NINACommand, address: number, value: number, data: Uint8Array | undefined = undefined) : Promise<void> {

    var payloadLen = (data ? data.length : 0);

    const packet = this._sendCommandBuffer;

    packet.reset();
    packet.push(cmd & 0xff);
    packet.pushBytes(address, 4, false);
    packet.pushBytes(value, 4, false);
    packet.pushBytes(payloadLen, 2, false);

    if (data) {
      packet.copy(data);
    }

    const res = packet.view();
    // if (this.options.trace) {
    //   this.logger.debug("Writing " + this.hex(res.length) + " byte" + (res.length == 1 ? "" : "s") + ":" + hex(res.slice(0, packet.length)));
    // }

    await this.connection.sendMessage( Array.from(res) )
  }

  private hex(value: number, digits: number = 8) : string {
    var result = value.toString(16);

    while (result.length < digits) {
      result = '0' + result;
    }

    return result;
  }

  private _sendCommandBuffer = new Uint8Buffer();
}
