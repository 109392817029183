import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuning-marker',
  templateUrl: './tuning-marker.component.svg',
  styleUrls: ['./tuning-marker.component.scss']
})
export class TuningMarkerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  @Input()
  resistance : number = 10;

  @Input()
  pitchShift: number = 0;
  
  @Input()
  isOverblow: boolean = false;
  
  get markerX(): number {
    let offset = (this.resistance === 10 ? 0 : 
                 (this.resistance < 5 ? - this.resistance / 5 :
                 (this.resistance - 5)/5 )) * 20

    return 42 - offset;
  }

  get markerY(): number {
    let offset = (-this.pitchShift / 8192) * 20; 

    return 42 - offset;
  }
}
