import { LogLevel } from "typescript-logging";
import { CategoryProvider } from "typescript-logging-category-style";
import { environment } from '../../environments/environment';

let defaultLogLevel = (environment.production ? LogLevel.Warn : LogLevel.Debug );

const provider = CategoryProvider.createProvider("EMEO-Web", {
  level: defaultLogLevel,
});

export const rootConnection = provider.getCategory("connection");
provider.updateRuntimeSettingsCategory(rootConnection, { level: LogLevel.Warn });

export const rootModel = provider.getCategory("model");
export const rootViews = provider.getCategory("views");
export const rootService = provider.getCategory("service");
export const rootMain = provider.getCategory("main");
export const rootInstrument = provider.getCategory("instrument");
