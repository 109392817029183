import { EmeoDiagramSaxophoneV1Component } from "src/app/svg/emeo-saxophone-v1/emeo-diagram-saxophone-v1.component"
import { EmeoSaxophoneComponentV1 } from "src/app/views/emeo-saxophone-v1/emeo-saxophone-v1.component"
import { InstrumentInfoEx } from "../instrument-info-ex";
import { EMEO_SAX_V1_INFO } from "./emeo-sax-v1";

export const EMEO_SAX_V1_INFO_EX : InstrumentInfoEx = { 

  ...EMEO_SAX_V1_INFO,

  fingeringDiagram: EmeoDiagramSaxophoneV1Component,
  instrumentComponent: EmeoSaxophoneComponentV1,
}
