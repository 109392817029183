import { FeatureSupportService } from './../../services/feature-support.service';
import { BackupService } from './../../services/backup.service';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SamBA, Device, Logger, FlasherObserver, sleep, Flasher } from 'bossa-web';
import { FirmwareInfo } from 'src/app/model/firmware-info';
import { FirmwareService } from 'src/app/services/firmware.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Backup } from 'src/app/model/backup';
import { buf } from 'crc-32';
import { ConfirmationDialogService } from 'src/app/views/confirmation-dialog/confirmation-dialog.service';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { InstrumentConnection, InstrumentConnectionMode, InstrumentInterface } from 'src/app/model/instrument-connection';
import { DeviceRegistryService } from 'src/app/services/device-registry.service';
import { EMEO_SAX_V1, HardwareInfo, HardwareTypes, NO_DEVICE } from 'src/app/model/device-info';
import { FlasherType } from 'src/app/views/flasher/flasher.service';
import { rootViews } from 'src/app/helpers/log-config';
import { NINA, NINAFlasher } from 'src/app/helpers/nina';
import { InstrumentViewComponentBaseComponent } from 'src/app/helpers/instrument-view-component-base.component';
import { INSTRUMENT_INFOS_EX } from 'src/app/model/instruments/device-definitions';
import { InstrumentInfoEx } from 'src/app/model/instrument-info-ex';

const log = rootViews.getChildCategory("flasher");

export type CloseCallback = () => Promise<void>;

enum FlasherSteps {
  Welcome = 0,
  DeviceSelection = 1,
  RecoveryDeviceConnection = 2,
  FirmwareSelection = 3,
  ConfigurationBackup = 4,
  DeviceConnection = 5,
  BootloaderActivation = 6,
  FirmwareFlash = 7,
  DeviceReconnect = 8,
  ConfigurationRestore = 9,
  Completion = 10
}

interface DeviceSelectionInfo {

  index: string;

  hardware: HardwareInfo;

  name: string; 

  component: Type<InstrumentViewComponentBaseComponent>;
}

@Component({
  selector: 'app-flasher',
  templateUrl: './flasher.component.html',
  styleUrls: ['./flasher.component.scss'],
})
export class FlasherComponent extends InstrumentComponentBase implements OnInit {

  @ViewChild("nav") // Get a reference to the ngbNav
  nav! : any;

  @HostBinding('class.is-fullscreen') isFullscreen = false;

  flasherType: FlasherType = FlasherType.SAMBA;

  closeCallback: CloseCallback | undefined;

  get isNINAFlasher() : boolean {
    return (this.flasherType === FlasherType.NINA);
  }

  protected serialPort: SerialPort | undefined;

  protected nina: NINA | undefined;
  
  protected samba: SamBA | undefined;
  protected device: Device | undefined;

  protected logger: Logger;
  protected observer: FlasherObserver;

  protected actionProgress: string = "0%";

  private circuitId?: string;

  get hasCircuitId() : boolean {
    return (this.circuitId != undefined);
  }
  
  steps: number[] = [
    FlasherSteps.Welcome,
    FlasherSteps.DeviceSelection,
    FlasherSteps.FirmwareSelection,
    FlasherSteps.ConfigurationBackup,
//    FlasherSteps.DeviceConnection,
    FlasherSteps.BootloaderActivation,
    FlasherSteps.FirmwareFlash,
    FlasherSteps.DeviceReconnect,
    FlasherSteps.ConfigurationRestore,
    FlasherSteps.Completion
  ];

  progress: any = {};

  get isActive() : boolean {

    if (this.progress) {

      for (var key of Object.keys(this.progress)) {
        if (this.progress[key] === 'active') {
          return true;
        }
      }
    }

    return false;
  }

  private _availableFirmwares: FirmwareInfo[] = [];

  public set availableFirmwares(value: FirmwareInfo[]) {
    this._availableFirmwares = value;

    this.populateFirmwares();
  }

  private populateFirmwares() {
    let hw = this.deviceType;

    this.updateFirmwares = [];

    if (hw) {
      this.updateFirmwares = this._availableFirmwares.filter( fw => (fw.hardwareTypes.contains( hw.type ) && fw.hardwareVersions.contains( hw.version)));
    }
    
    if (this.updateFirmwares.length == 0) {
      this.updateFirmwares = this._availableFirmwares;
    }
  }

  updateFirmwares: FirmwareInfo[] = [];
  
  nextCaption: string = "Next";

  protected _canMoveToNextStep: boolean = false;

  public buildDescription(info : FirmwareInfo) : string {

    var summary = '';

    for (var type of info.hardwareTypes) {

      switch (type) {
        case HardwareTypes.Sax:
          summary += 'EMEO Saxophone ';
          break;

        case HardwareTypes.Clarinet:
          summary += 'EMEO Clarinet ';
          break;

        case HardwareTypes.NINA:
          summary += 'Bluetooth ';
          break;

        default:
      }

      summary += info.version;

      switch (info.type) {
        case 'release':
          break;
  
        default:
          summary += ' ' + info.type;
      }
    }

    return summary;
  }

  set canMoveToNextStep(flag: boolean) {

    this._canMoveToNextStep = flag;

    if (flag) {
      this.nextCaption = "Continue";
    }

    this.cd.detectChanges();
  }

  get canMoveToNextStep() : boolean {
    return this._canMoveToNextStep;
  }

  protected _currentStep: number = 0;

  protected nextStep?: number;

  set currentStep(value: number) {
    this._currentStep = value; 

    this.canMoveToNextStep = false;
    this.nextStep = undefined;

    this.prepareStep(this._currentStep);
    this.nav?.select(this._currentStep);
  }

  get currentStep() : number {
    return this._currentStep;
  }

  protected isInBootloader: boolean = false;
  protected isConnected: boolean = false;
  
  firmwareSelection: UntypedFormControl;

  devices: DeviceSelectionInfo[] = [];

  deviceSelection: UntypedFormControl;

  constructor(
    public fs: FeatureSupportService,
    public deviceRegistry: DeviceRegistryService,
    public confirmService: ConfirmationDialogService,
    private backupService: BackupService,
    public activeModal: NgbActiveModal,
    public cs: ConnectionService,
    private cd: ChangeDetectorRef,
    private firmwareLoadService: FirmwareService) { 

    super(cs);

    this.firmwareSelection = new UntypedFormControl('firmwareVersion');
    this.firmwareSelection.valueChanges.subscribe( (value:any) => {
        comp.firmwareSelected();
    });

    var usableDevices: InstrumentInfoEx[] = [];
    
    if (this.deviceType) {
      let hw = this.deviceType;

      usableDevices = INSTRUMENT_INFOS_EX.filter( (value) => { return value.devices.find( value => ((value.type == hw.type) && (value.version == hw.version))); })
    }

    if (usableDevices.length == 0) {
      usableDevices = INSTRUMENT_INFOS_EX;
    }

    this.devices = usableDevices.map( (instrument, index) => {
      return { 
        index: 'device-' + index,
        name: instrument.name,
        hardware: instrument.hardware,
        component: instrument.instrumentComponent
      }
    })

    this.deviceSelection = new UntypedFormControl('deviceSelection');
    this.deviceSelection.setValue(this.devices[0].index);
    this.deviceSelection.valueChanges.subscribe( (value:any) => {
        comp.deviceSelected();
    });



    let comp = this;

    this.logger = <Logger>{

      withDebug: false,

      debug(message?: unknown, ...optionalParams: unknown[]): void {
        log.debug(String(message), optionalParams);
      },

      log(message?: unknown, ...optionalParams: unknown[]): void {
        log.info(String(message), optionalParams);
      },

      error(message?: unknown, ...optionalParams: unknown[]): void {
        log.error(String(message), optionalParams);
      }
    }

    this.observer = <FlasherObserver> {

      onStatus(message : string) : void {
        comp.write(message, []);
      },

      onProgress(num: number, div: number) : void {
        comp.actionProgress = String(Math.trunc(100 * num / div)) + '%';
        comp.cd.detectChanges();
      }

    }
  }

  deviceSelected() {
    let newValue = this.deviceSelection.value;
    log.debug("Selected device " + newValue);

    let selectedDevice = this.devices.find( device => device.index == newValue );

    this._selectedHardware = selectedDevice?.hardware || null

    // this.firmwareName = newValue;
    this.cd.detectChanges();
  }

  firmwareSelected() {
    let newValue = this.firmwareSelection.value;
    if (newValue != "firmwareVersion") {
      log.debug("Selected firmware " + newValue);
      this.firmwareName = newValue;
      this.cd.detectChanges();
    }
  }

  protected rebooting : boolean = false;

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    if (this.currentStep === FlasherSteps.DeviceReconnect) {

      if (!current && this.rebooting) {
        this.setProgress("rebootDevice", 'done');
        return;
      }

      if (current) {
        this.setProgress("connectDevice", 'done');
      }
      else {
        this.setProgress("connectDevice", 'error');
      }

      if (this.currentInstrument && this.circuitId) {
        this.setProgress('configDevice', 'active');

        let deviceInfo = await this.deviceRegistry.getDeviceInfoForCircuit(this.circuitId, Object.assign({}, this.deviceType));

        if (deviceInfo) {
          // deviceInfo = Object.assign({}, EMEO_DEVICE);
          this.currentInstrument.sendHardwareInfo(deviceInfo);
        }

        this.setProgress('configDevice', 'done');
      }
      else {
        this.setProgress('configDevice', 'done');
      }

      await this.refreshInstrumentInfo();
      this.canMoveToNextStep = true;

      if (this.autobackup) {
        this.currentStep = FlasherSteps.ConfigurationRestore;
      }
      else {
        this.currentStep = FlasherSteps.Completion;
      }
    }
  }

  protected write(message: unknown, optionalParams: unknown[]): void {
    log.debug(<string>message, ...optionalParams);
  }

  ngOnInit(): void {
  }

  autobackup?: Backup;

  allowSelection: boolean = true;

  async restoreBackup() : Promise<void> {

    if (this.currentInstrument) {
      
      if (this.autobackup) {
  
        this.setProgress('restore', 'active');
        this.allowSelection = false;
        this.actionProgress = '0%';

        this.logger.debug("Starting restore of global settings.")
        await this.backupService.restoreGlobalSettings(this.currentInstrument, this.autobackup);

        this.logger.debug("Starting restore of UNC tables.")
        await this.backupService.restoreUNC(this.currentInstrument, this.autobackup, { progress: (step: number, total: number) => {
          this.actionProgress = String(Math.trunc(100 * step / total)) + '%';
          this.cd.detectChanges();
        }});

        this.logger.debug("Refreshing instrument info the receive new settings.")
        await this.currentInstrument.refreshInstrumentInfo();

        this.actionProgress = '100%';
        this.setProgress('restore', 'done');
        this.canMoveToNextStep = true;
        this.currentStep = FlasherSteps.Completion;
      }
    }
  }

  /**
   * Create a backup of the connected EMEO
   */
  async performBackup() : Promise<void> {

    if (this.currentInstrument) {

      this.actionProgress = "0%";
      this.setProgress('backup', 'active');
      this.cd.detectChanges();

      let backup = await this.backupService.backupEverything(this.currentInstrument, { progress: (step: number, total: number) => {
        this.actionProgress = String(Math.trunc(100 * step / total)) + '%';
        this.cd.detectChanges();
      }});

      if (backup) {
        log.debug("Backup completed");

        backup.name = "Before firmware update";
        backup.automatic = true;
        this.backupService.addLocalBackup(backup);
        this.autobackup = backup;

        this.canMoveToNextStep = true;

        this.actionProgress = "100%";
        this.setProgress('backup', 'done');

        this.cd.detectChanges();
      }
    }
  }

  requiresBootloader: boolean = true;
  
  // This will allow to skip the flash screen and commence flashing
  directFlash: boolean = this.fs.hasFeature('Updater');

  prepare() {

    this.steps = [];

    this.steps.push( FlasherSteps.Welcome );

    if (this.devices.length > 1) {
      this.steps.push( FlasherSteps.DeviceSelection );
    }
    else {
    }

    this.steps.push( FlasherSteps.FirmwareSelection );
    
    // this.steps.push( FlasherSteps.DeviceConnection );

    if (this.flasherType === FlasherType.SAMBA) {
      this.steps.push( FlasherSteps.BootloaderActivation );
    }
    this.steps.push( FlasherSteps.FirmwareFlash );

    if (this.flasherType === FlasherType.SAMBA) {
      this.steps.push( FlasherSteps.DeviceReconnect );
    }

    if (this.cs.hasEMEOConnection 
      && this.fs.can({ connection: this.cs.emeoConnection }, 'BackupRestore')
      && (this.flasherType === FlasherType.SAMBA)) {
      // We cannot pull a backup without EMEOConnection
      this.steps.push( FlasherSteps.ConfigurationBackup );

      // But hopefully we can restore ;-)
      this.steps.push( FlasherSteps.ConfigurationRestore );
    }


    this.steps.push( FlasherSteps.Completion );
    this.steps.sort( (a:number, b: number ) => {return a-b;});

    this.currentStep = this.steps[0] || FlasherSteps.Welcome;
    // this.currentStep = FlasherSteps.DeviceReconnect;
    this.actionProgress = '0%';

    if (this.updateFirmwares.length == 1) {
      this.firmwareSelection.setValue( this.updateFirmwares[0].version );
    }
    else {
      this.firmwareSelection.setValue( this.updateFirmwares[0].version );
    }

    this.nav?.select(this._currentStep);    
  }

  nextTab() {
    let newSteps = this.steps.filter( (step: number) => { return (step > this.currentStep); } );
    let nextStep = newSteps.sort((a: number, b: number) => { return b-a; } ).pop();

    if (nextStep) {
      this.currentStep = nextStep;
    }
  }

  skipBackup() {
    this.autobackup = undefined;
    
    this.nextTab();
  }

  private prepareStep(step: number) {

    this.progress = undefined;

    switch (step) {
      case FlasherSteps.Welcome:
        this.canMoveToNextStep = true;
        this.nextCaption = "Start";
        break;
      
      case FlasherSteps.FirmwareSelection:
        
        this.populateFirmwares();

        if (this.updateFirmwares.length == 1) {
          this.firmwareName = this.updateFirmwares[0].version;
        }
        else {
          this.firmwareName = undefined;
        }

        this.actionProgress = '0%';
        this.firmwareSelection.enable();
        this.setProgress('loadFirmware', 'wait');
        this.setProgress('verifyFirmware', 'wait');

        if (this.directFlash && !(this.fs.developmentMode || this.fs.maintenanceMode) && this.updateFirmwares.length === 1) {
          this.downloadFirmware().then( () => { this.nextTab() });
        }

        break;

      case FlasherSteps.ConfigurationBackup:
        this.actionProgress = '0%';
        this.setProgress('backup', 'wait');
        this.performBackup();
        break;
        
      case FlasherSteps.BootloaderActivation:
        this.circuitId = undefined;

        this.setProgress('enterBootloader', 'wait');  
        this.setProgress('connect', 'wait');  
        this.setProgress('identify', 'wait');
        break;  

      case FlasherSteps.DeviceConnection:
        break;

      case FlasherSteps.FirmwareFlash:
        this.actionProgress = '0%';
        this.setProgress('activateFlashMode', 'wait');
        this.setProgress('loadFirmware', 'wait');  
        this.setProgress('flashFirmware', 'wait');  
        break;

      case FlasherSteps.DeviceReconnect:
        if (this.currentInstrument?.connectionMode === InstrumentConnectionMode.EMEO) {
          this.rebooting = false;
          this.setProgress('rebootDevice',  'done');
          this.setProgress('connectDevice', 'done');
          this.setProgress('configDevice', 'wait');  
        }
        else {
          this.rebooting = true;
          this.setProgress('rebootDevice', 'wait');
          this.setProgress('connectDevice', 'wait');
          this.setProgress('configDevice', 'wait');
        }

        break;
  
      case FlasherSteps.ConfigurationRestore:
        this.actionProgress = '0%';
        this.allowSelection = true;
        this.setProgress('restore', 'wait');
        break;

      case FlasherSteps.Completion:
        this.disconnect().then();
        break;
    }
  }

  finishWizard() {
    this.activeModal.close();
  }

  onSerialPortConnect(event: Event) {
    this.logger.debug("onSerialPortConnect");
    this.isConnected = true;
  }

  onSerialPortDisconnect(event: Event) : any {
    this.logger.debug("onSerialPortDisconnect");
    this.isConnected = false;

    this.samba = undefined;
    this.device = undefined;
    this.serialPort = undefined;
    this.isInBootloader = false;
  }

  async disconnect() {

    if (this.device) {
      await this.device.reset();
      this.device = undefined;
    }

    if (this.samba) {
      // await this.samba.go(0x00002000);
      this.samba = undefined;
    }

    if (this.serialPort) {

      let port = this.serialPort
      this.serialPort = undefined;

      port.close()
        .catch( (e) => { log.error(JSON.stringify(e));})
        .finally( () => { this.isConnected = false; });
    }

    this.isInBootloader = false;
  }  

  async connectDevice() {

    this.setProgress('connect', 'active');
      
    if (this.currentInstrument) {

      let connection = this.currentInstrument.connection;

      if (this.flasherType === FlasherType.NINA) {

        await this.currentInstrument.enterNinaFlasher();
        await sleep(250);

        this.currentStep = FlasherSteps.FirmwareFlash;

        if (this.directFlash) {
          this.performFlash();
        }            
      }
      else {
        this.setProgress('connect', 'active');

        if (connection.mode === InstrumentConnectionMode.FLASH) {
          this.isInBootloader = true;
          this.canMoveToNextStep = true;
          this.currentStep = FlasherSteps.BootloaderActivation;
          await this.connectBootloader(connection);
        }
        else {
          this.setProgress('connect', 'done');

          this.isConnected = true;
          this.serialPort = connection.port as SerialPort;

          this.canMoveToNextStep = true;

          this.currentStep = FlasherSteps.BootloaderActivation;
        }
      }
    }
  }  


  private setProgress(key: string, state: 'active'|'wait'|'done'|'error') {
    this.progress = this.progress || {};
    this.progress[key] = state;
    this.cd.detectChanges();
  }

  async enterBootloader() {

    this.setProgress('enterBootloader', 'active');  
    
    let rebootWaitMs = 3000;

    if (this.currentInstrument) {

      let connection = this.currentInstrument.connection;

      if (connection.mode != InstrumentConnectionMode.FLASH) {
        this.logger.debug('Entering bootloader mode');
  
        await connection.close();
  
        
        // Enter bootloader mode
        try {
          this.logger.debug('Enabling flash mode.');
          await connection.enableFlashMode();
        }
        catch (e) {
          this.logger.error("Cannot open serial port for reset.", e);
        }
        finally {
          this.setProgress('enterBootloader', 'done');
      
          await connection.close();
          await sleep(rebootWaitMs);
    
          this.logger.debug('Device should have rebooted by now. Starting re-connection');

          var connected = await this.cs.tryReconnection();

          if (!connected) {
            this.logger.debug("Manual connection");
            connected = await this.cs.establishSerialConnection();
          }

          this.logger.debug("Re-Connection success: " + connected);

           
          if (connected && (this.currentInstrument.connection.mode === InstrumentConnectionMode.FLASH)) {
              await this.connectBootloader(this.currentInstrument.connection);
          }
          else {
            this.logger.debug("Not in FLASH Mode " + connected);
          }
        }

      }
      else {
        this.setProgress('enterBootloader', 'done');
        this.setProgress('connect', 'done');
        await this.connectBootloader(connection);
      }

    }
    else {
      this.setProgress('enterBootloader', 'done');
      var connected = await this.cs.establishSerialConnection();

      this.logger.debug("Re-Connection success: " + connected);

      if (connected) {
        this.setProgress('connect', 'active');
        if (this.currentInstrument) {
          var connection = (this.currentInstrument as InstrumentConnection).connection;
          await this.connectBootloader(connection);
          this.setProgress('connect', 'done');
        }
      }
    }
  }

  private firmwareImage?: Uint8Array

  firmwareName?: string = undefined;

  async downloadFirmware() {

    this.setProgress('loadFirmware', 'active');
    this.firmwareSelection.disable();

    let version = this.firmwareSelection.value;
    this.firmwareName = version;

    let firmware = this.updateFirmwares.find( (fw: FirmwareInfo) => { return (fw.version == version); } );

    if (firmware) {
      try {
        this.setProgress('loadFirmware', 'active');
        this.firmwareImage = await this.loadFirmware(firmware);
        this.canMoveToNextStep = true;
      }
      catch (e) {
        this.setProgress('loadFirmware', 'error');
      }
    }
    else {
      this.setProgress('loadFirmware', 'error');
    }

    if (!this.canMoveToNextStep) {
      this.firmwareSelection.enable();
    }
  }

  async resetDevice() {
    if (this.flasherType === FlasherType.SAMBA)  {
      await this.device!.reset();
    }
    else {
      await this.nina?.leaveNinaFlasher();
      this.nina = undefined;
    }
  }

  async performFlash() {

    if (this.firmwareImage) {

      let success = await this.flashImage(this.firmwareImage);

      this.canMoveToNextStep = true;
      this.nextTab();

      if (success && this.device) {
        try {
          this.setProgress('rebootDevice', 'active');
          this.rebooting = true;
          await this.resetDevice();
        }
        catch (error) {
          this.setProgress('rebootDevice', 'error');
        }
      }
      else {
        this.setProgress('rebootDevice', 'error');
      }

      this.canMoveToNextStep = true;
    }
  }

  async flashImage(data: Uint8Array) : Promise<boolean> {

    switch (this.flasherType) {
    case FlasherType.SAMBA:
      return await this.flashSambaImage(data);

    case FlasherType.NINA: 
      return await this.flashNinaImage(data);
    }

    return false;
  }


  private async flashNinaImage(data: Uint8Array) : Promise<boolean> {

    if (this.currentInstrument) {

      this.setProgress('activateFlashMode', 'active');
      await this.currentInstrument.enterNinaFlasher();
      await sleep(250);
      this.setProgress('activateFlashMode', 'done');

      let connection = this.currentInstrument.connection;

      this.setProgress('flashFirmware', 'active');

      let nina = new NINA(
                        this.currentInstrument,
                        {
                          logger: this.logger,
                          debug: true,
                          trace: true
                        });

      try {
        let flasher = new NINAFlasher(nina, this.logger, this.observer);

        await flasher.flashFirmware(data);
        this.setProgress('flashFirmware', 'done');
        return true;
      }
      catch (error) {
        log.error("Failed NINAFlasher", error);
        this.setProgress('flashFirmware', 'error');
      }
      finally {
          await nina?.leaveNinaFlasher();
      }
    }

    return false;
  }

 /**
   * Send a byte stream to the device
   */
  private async writeToStream(msg: string) : Promise<void> {

    if (this.serialPort?.writable) {

      const writer = this.serialPort.writable.getWriter();
      let chunkSize = 63;

      var enc = new TextEncoder(); // always utf-8
      let ua = enc.encode(msg);
      
      try {
        await sleep(10);
        await writer.write(ua);
      }
      finally {
        writer.releaseLock();
      }
    }
  }

  private async flashSambaImage(data: Uint8Array) : Promise<boolean> {

    this.setProgress('flashFirmware', 'active');

    if (this.device && this.samba && this.device.flash) {

      try {
        var flasher = new Flasher(this.samba, this.device.flash, this.observer);
        let offset = 0x00002000;
  
        await flasher.erase(offset);
        await flasher.write(data, offset);

        this.setProgress('flashFirmware', 'done');

        // Kick EMEO back to connection mode
        // await this.writeToStream("RST_");
        // await this.closePort();

        // await this.samba.go(offset);

        return true;
      }
      catch (error) {
        this.setProgress('flashFirmware', 'error');
      }
    }

    return false;
  }

  /**
   * Download a firmware image from the server
   * 
   * @param firmware Firmware info 
   */
  async loadFirmware(firmware: FirmwareInfo) : Promise<Uint8Array | undefined> {

    this.setProgress('loadFirmware', 'active');
    this.firmwareSelection.disable();

    this.canMoveToNextStep = false;

    let flasher = this;

    let response = await this.firmwareLoadService.getFirmwareImage(firmware.file);

    if (response) {

      this.logger.log('Firmware was successfully downloaded');
      let buffer = await response.arrayBuffer();
      let data = new Uint8Array(buffer);
      this.setProgress('loadFirmware', 'done');
  
      this.setProgress('verifyFirmware', 'active');
      let checksum = buf(data); 
      let stringChecksum = this.formatChecksum(checksum);
      let requiredChecksum = firmware.checksum.toLowerCase();
  
      if (stringChecksum !== requiredChecksum) {
        this.setProgress('verifyFirmware', 'error');
        this.firmwareSelection.enable();
        throw Error(`Cannot verify firmware. Checksum for ${firmware.file} is ${stringChecksum}, expected checksum is ${requiredChecksum}`);
      }
      else {
        this.setProgress('verifyFirmware', 'done');
        this.canMoveToNextStep = true;
        return data;
      }
    }
    else {
      this.setProgress('loadFirmware', 'error');
    }

    return undefined;
  }

  private formatChecksum(checksum: number): string {
    return (checksum >>> 0).toString(16).padStart(8, '0').toLowerCase();
  }

  async loadAndFlashFirmware(firmware: FirmwareInfo) {
    let firmwareImage = await this.loadFirmware(firmware);
    
    if (firmwareImage) this.flashImage(firmwareImage);
  }

  async connectBootloader(connection: InstrumentInterface) {

    this.logger.debug('Attaching bootloader');

    this.setProgress('enterBootloader', 'done');
    this.setProgress('connect', 'done');
    this.setProgress('identify', 'active');
    this.canMoveToNextStep = false;

    let serialPort = connection.port as SerialPort;

    this.cs.disableAutoconnect();

    this.logger.debug('Closing serial connection');
    await connection.close();
    await sleep(500);

    serialPort.onconnect = this.onSerialPortConnect.bind(this);
    serialPort.ondisconnect = this.onSerialPortDisconnect.bind(this);

    this.logger.debug('Setting up SAM-BA connection');
    this.samba = new SamBA(serialPort, {
      logger: this.logger,
      debug: true
    });

    this.serialPort = serialPort;
    let theSamba = this.samba;

    this.logger.debug('Connecting SAM-BA layer');
    await theSamba.connect(1000);

    this.isConnected = true;
    this.cs.enableAutoconnect();
    
    var dev = new Device(theSamba);
    await dev.create();

    let id = dev.chipUniqueId;
    if (id) {
      id = id.substring(0, 30);
    }

    this.circuitId = id;

    this.device = dev;

    this.setProgress('identify', 'done');
    this.canMoveToNextStep = true;

    this.isInBootloader = true;

    this.currentStep = FlasherSteps.FirmwareFlash;

    if (this.directFlash) {
      this.performFlash();
    }
  }

  async cancelFlasher() {
    let response = await this.confirmService.confirm(
      "Abort Wizard?",
      "Do you really want to abort the firmware update wizard?",
      "Yes", "No"
    );

    if (response) {
      if (this.closeCallback) {
        await this.closeCallback()
      }
    }
  }

  isProgress(key: string, state: string): boolean {
    return (this.progress ? this.progress[ key ] == state : false);
  }

  progressStyles(key: string): any {

    let styles = { 
        'bg-success': this.isProgress(key, 'done'), 
        'bg-danger': this.isProgress(key, 'error'), 
        'bg-primary': this.isProgress(key, 'active'),
        'progress-bar-animated': this.isProgress(key, 'active') 
      }

      return styles;
  }

  @ViewChild('fullscreenElement') fsElement?: ElementRef;

  openFullscreen() {
    this.isFullscreen = true;

    const el = this.fsElement?.nativeElement;
    let doc: any = document;

    if (!doc.fullscreenElement) {  // current working methods
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } 
      else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } 
      else if (el.webkitRequestFullscreen) {
        // el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } 
      else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      }
    }

    // setTimeout(() => {
    //   this.isActive = true;
    // }, 500);    
  }

  async connectSerial() {
    log.info("User clicked serial connection");
    let connect = await this.cs.establishSerialConnection()
  }
  
  
  get hasEMEOConnection(): boolean {
    // log.debug("hasEmeoConnection: " + this.hardwareSupport.bluetoothIsSupported);
    return this.cs.hasEMEOConnection;

    return false;
  }

  async uploadFirmware() {

    this.setProgress('loadFirmware', 'active');
    this.firmwareSelection.disable();

    try {
      this.setProgress('loadFirmware', 'active');

      this.firmwareImage = await this.uploadFirmwareFile();
      this.canMoveToNextStep = true;

      this.setProgress('loadFirmware', 'done');
      this.setProgress('verifyFirmware', 'done');
    }
    catch (e) {
      this.setProgress('loadFirmware', 'error');
    }

    if (!this.canMoveToNextStep) {
      this.firmwareSelection.enable();
    }
  }

  async uploadFirmwareFile() : Promise<Uint8Array> {

    const pickerOpts = {
      types: [
        {
          description: 'Firmware Images',
          accept: {
            'application/*': ['.bin']
          }
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false
    };

    // open file picker
    let [fileHandle] = await (<any>window).showOpenFilePicker(pickerOpts);

    // get file contents
    if (fileHandle) {
      this.firmwareName = fileHandle.name;
     
      const fileData = await fileHandle.getFile();
      const data = await fileData.arrayBuffer();
  
      return data;
    }

    throw Error("Could not upload firmware image");
  }  

  private _selectedHardware: HardwareInfo | null = null;

  get deviceType() : HardwareInfo {
    return this._selectedHardware || this.currentInstrument?.deviceInfo.hardware || (this.fs.can({ connection: this.cs.emeoConnection }, 'Clarinet') ? NO_DEVICE : EMEO_SAX_V1);
  }

}
