
export class EmeoSetting {

  private _code : number;
  private _name : string;

  private _min : number;
  private _max : number;

  get code() : number {
    return this._code;
  }

  get name() : string {
    return this._name;
  }

  constructor(name: string, code: number, min: number = 0, max: number = 0x7f) {
    this._name = name;
    this._code = code;

    this._min = min;
    this._max = max;
  }

  get default() : number {
    return this._min;
  }

  validate(value: number) : boolean {
    return (value >= this._min) && (value <= this._max);
  }
}

export const EmeoGlobalSettings = {

  midiChannel:              new EmeoSetting("midiChannel",              0x00,  0,  5),
  keyShift:                 new EmeoSetting("keyShift",                 0x01,  0,  2),
  octaveShift:              new EmeoSetting("octaveShift",              0x02,  0,  2),

  breathResistanceLevel:    new EmeoSetting("breathResistanceLevel",    0x03,  0,  3),
  
  breathAftertouchEnabled:  new EmeoSetting("breathAftertouchEnabled",  0x04,  0,  1),
  breathVolumeEnabled:      new EmeoSetting("breathVolumeEnabled",      0x05,  0,  1),
  breathExpressionEnabled:  new EmeoSetting("breathExpressionEnabled",  0x06,  0,  1),
  
  pressureResistanceOffset: new EmeoSetting("pressureResistanceOffset", 0x07,  0,  0x7f),
  
  tonguingDetectionEnabled: new EmeoSetting("tonguingDetectionEnabled", 0x08,  0,  1),
  tonguingCCNumber:         new EmeoSetting("tonguingCCNumber",         0x09),
  tonguingSensitivity:      new EmeoSetting("tonguingSensitivity",      0x0a,  0,  4),
  
  goSleepMultiplier:        new EmeoSetting("goSleepMultiplier",        0x0b,  0,  0x7f),
  
  vocalDetectionEnabled:    new EmeoSetting("vocalDetectionEnabled",    0x0c,  0,  1),
  vocalCCNumber:            new EmeoSetting("vocalCCNumber",            0x0d),

  overblowDetectionEnabled: new EmeoSetting("overblowDetectionEnabled", 0x10,  0,  1),
  overblowCCNumber:         new EmeoSetting("overblowCCNumber",         0x11),
  overblowMidiChannel:      new EmeoSetting("overblowMidiChannel",      0x12,  0,  15),
  overblowPressure:         new EmeoSetting("overblowPressure",         0x13,  0,  2),
  invalidNoteFeedback:      new EmeoSetting("invalidNoteFeedback",      0x14,  0,  2),

  playWithoutBlowLevel:     new EmeoSetting("playWithoutBlowLevel",     0x15,  0,  0x7f),

  glitchLearnEnabled:       new EmeoSetting("glitchLearnEnabled",       0x16,  0,  1),
  glitchReducerEnabled:     new EmeoSetting("glitchReducerEnabled",     0x17,  0,  1),

  getSetting(name: string) : EmeoSetting | undefined {
    return (<any>this)[ name ];
  },

  getSize() : number {
    return 0x18
  }
}

export class GlobalSettings {

  /**
   * Midi Channel ranges from 0..15
   */
  midiChannel: number = 0;

  keyShift: number = 0;
  octaveShift: number = 0;

  breathResistanceLevel: number = 0;
  pressureResistanceOffset: number = 63;

  breathAftertouchEnabled: boolean = false;
  breathVolumeEnabled: boolean = false;
  breathExpressionEnabled: boolean = false;

  tonguingDetectionEnabled: boolean = false;
  tonguingCCNumber: number = 73;
  tonguingSensitivity: number = 1;

  goSleepMultiplier: number = 0;
  vocalDetectionEnabled: boolean = false;
  vocalCCNumber: number = 70;

  overblowDetectionEnabled: boolean = false;
  overblowCCNumber: number = 71;
  overblowMidiChannel: number = 0;
  overblowPressure: number = 1;

  playWithoutBlowLevel: number = 60;

  glitchReducerEnabled: boolean = false;
  glitchLearnEnabled: boolean = false;

  invalidNoteFeedback: number = 0;

  advancedModeEnabled: boolean = false;
}
