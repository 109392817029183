import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FeatureSupportService } from './feature-support.service';
import { ConnectionService } from './connection.service';
 
@Injectable()
export class HardwareSupportGuard  {

  /**  */
  platformSupported: boolean = true;

  /** Indicates that the browser supports serial connectivity */
  serialIsSupported: boolean = false;

  constructor(
    private _router:Router,
    private fs: FeatureSupportService,
    private midiService: ConnectionService) {

    this.serialIsSupported = ('serial' in navigator);
  }
 
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
 
    if (this.platformSupported && this.serialIsSupported) {
      return true;
    }

    // this._router.navigate(['/'], { queryParamsHandling: "preserve" });
    return false;
  }
}
