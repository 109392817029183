
export function hex(data: Uint8Array | number[]) : string {

    let msg = "[";
    data.forEach( d => { msg += d.toString(16).padStart(2,'0') + " "; });
    msg += "]";

    return msg;
}

export async function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function ggt(...c : number[]) : number | undefined {

    let a = c.pop();
    let b = c.pop();

    if (a === undefined) {
        return undefined;
    }

    if (b === undefined) {
        return a;
    }

    // Swap order
    if (a < b) {
        let c = b; 
        b = a; 
        a = c;
    }

    if (a === 0) {
        return b;
    }
    if (b === 0) {
        return a;
    }

    let result = a % b;
    if (result == 0) {
        return b;
    }

    return ggt(b, result);
}


export function kgv(...c : number[]) : number | undefined {
    let a = c.pop();
    let b = c.pop();

    if (a === undefined) {
        return undefined;
    }

    if (b === undefined) {
        return a;
    }

    var theGgt = ggt(a, b);
    var result : number | undefined = undefined;

    if (theGgt) {
        result = (a * b) / theGgt;
    }

    if (c.length > 0 && result) {
        c.push(result);
        result = kgv(...c);
    }

    return result;
}

export function bin(value: number | number[], length: number) : string {

    let result = '';

    if (Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
            result += value[i].toString(2).padStart(length, '8');
        }

        result = result.padStart(length, '0');
        result = result.substr(result.length - length);

        return result;
    }
    else {
        result = value.toString(2).padStart(length, '0');
    }

    return result;
}

export function fit(contains: boolean) {
	return function (parentWidth: number, parentHeight: number, childWidth: number, childHeight: number) {
		var doRatio = childWidth / childHeight;
		var cRatio = parentWidth / parentHeight;
		var width = parentWidth;
		var height = parentHeight;

		if (contains ? (doRatio > cRatio) : (doRatio < cRatio)) {
			height = width / doRatio;
		} 
        else {
			width = height * doRatio;
		}

		return {
			width: width,
			height: height,
			x: (parentWidth - width) / 2,
			y: (parentHeight - height) / 2
		};
	};
}

export const contain = fit(true);
export const cover = fit(false);
