import { Component, OnInit } from '@angular/core';
import { rootViews } from 'src/app/helpers/log-config';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ScoreDialogComponent } from '../score-dialog/score-dialog.component';
import { firstValueFrom } from 'rxjs';
import { Score } from 'src/app/model/score';
import { RecordingsService } from 'src/app/services/recordings.service';

const log = rootViews.getChildCategory("emeo");


@Component({
  selector: 'app-playasong',
  templateUrl: './playasong.component.html',
  styleUrls: ['./playasong.component.scss']
})
export class PlayasongComponent implements OnInit {

  constructor(
    protected http: HttpClient,
    protected rs: RecordingsService,
    protected mds: NgbModal) {

    const url = `assets/scores/index.json`;
    let self = this;

    rs.getLocalRecordings().then( async (recordings) => {

      let request = this.http
        .get<string>(url, { responseType: 'json' })
        .pipe(
          map( (json: any) => {
            return <Score[]>json || [];
          }),
          map( (scores : Score[]) => {
            return scores.map( score => Object.assign(new Score("","",""), score))
          }),
          map( (scores : Score[]) => {
            return scores.map( (score) =>  {
              score.recordings = recordings.filter( (recording) => (recording.scoreId == score.id));
              return score;
            })
          })
        );
  
      firstValueFrom(request)
        .then( (value) => {
          if (value) {
            self.scores = value;
          }
        });
    });

  }

  scores: Score[] = [];

  ngOnInit(): void {
  }

  showScore(id: string) {

    let score = this.scores.find( (score) => (score.id === id));

    if (score) {

      let dialogOptions: NgbModalOptions = { 
        animation: true, 
        centered: true, 
        size: 'xl', 
        backdrop  : 'static', 
        scrollable: true, 
        modalDialogClass: 'rounded-4 shadow-2-strong',
        container: "#fullscreenContainer" 
      } 
  
      let dialog = this.mds.open(ScoreDialogComponent, dialogOptions);
      let comp = <ScoreDialogComponent>dialog.componentInstance;
      comp.score = score;
    }
  }
}
