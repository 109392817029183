import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { rootViews } from 'src/app/helpers/log-config';
import { Backup } from 'src/app/model/backup';
import { BackupService } from 'src/app/services/backup.service';

const log = rootViews.getChildCategory("backupInfo");

@Component({
  selector: 'app-backup-info',
  templateUrl: './backup-info.component.html',
  styleUrls: ['./backup-info.component.scss']
})
export class BackupInfoComponent implements OnInit {

  @Input()
  backup: Backup | undefined;

  @Input()
  canEditName: boolean = true;
  
  formGroup: UntypedFormGroup;

  restoreUnc: boolean = true;
  restoreSettings: boolean = true;
  
  @Input()
  isSelecting: boolean = false;

  @Input()
  isRestoring: boolean = false;

  private _progress: number = 0;

  @Input()
  set progress(percent : number) {
    this._progress = percent;
  } 

  get progress() : number {
    return this._progress;
  } 

  get actionProgress(): string {
    return this._progress + "%";
  }
  
  @ViewChild('nameedit') 
  editElement?: ElementRef<HTMLInputElement> = undefined;

  private _editing: boolean = false;

  isEditingName() {
    return this._editing;
  }

  saveName() {
    this._editing = false;

    if (this.backup) {
      this.backup.name = this.editElement?.nativeElement.value;

      this.bs.addLocalBackup(this.backup);
    }
  }

  checkSave(e : KeyboardEvent) {

    if (e.key === "Enter") {
      // Cancel the default action, if needed
      e.preventDefault();   
    
      this.saveName();
    }

  }

  editName() {
    this._editing = true;

    setTimeout( () => { // this will make the execution after the above boolean has changed
      this.editElement?.nativeElement.focus();
    },0);
  }

  constructor(
    protected bs: BackupService,
    protected cd : ChangeDetectorRef) {

    this.formGroup = new UntypedFormGroup(
      {
        settings: new UntypedFormControl(true),
        unc: new UntypedFormControl(true)
      }
    );

    let comp = this;

    Object.keys(this.formGroup.controls).forEach( key => {
      let control = this.formGroup.get(key);

      if (control instanceof UntypedFormControl) {
        let formControl = <UntypedFormControl>control;
        formControl.valueChanges.subscribe( (value:any) => {
          comp.valueChanged(key, value);
        });
      }
    });
  }

  valueChanged(settingName: string, newValue: any) {
    
    switch (settingName) {
      case "unc":
        this.restoreUnc = newValue;
        break;

      case "settings":
        this.restoreSettings = newValue;
        break;
    }
  }

  ngOnInit(): void {
  }

  
}
