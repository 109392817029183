import { Component, HostListener, OnDestroy } from "@angular/core";
import { DeviceInfo } from "../model/device-info";
import { InstrumentState } from "../model/instrument-state";
import { InstrumentConnection } from "../model/instrument-connection";
import { ConnectionService } from "../services/connection.service";
import { InstrumentComponentBase } from "./instrument-component-base";

@Component({
    template: ''
})
export class InstrumentStatusComponentBase extends InstrumentComponentBase implements OnDestroy {

    hasStatus: boolean = false;

    batteryLevel: number = 0;

    usbConnection: boolean = false;

    bleConnection: boolean = false;

    state?: InstrumentState = undefined;

    deviceInfo?: DeviceInfo = undefined;

    private listener: (connection: InstrumentConnection, state: InstrumentState) => void;

    constructor(
        protected cs: ConnectionService) { 

        super(cs);

        this.listener = this.stateUpdated.bind(this);

        this.connectListeners(this.currentInstrument, undefined);
    }

    stateUpdated(connection: InstrumentConnection, state: InstrumentState) {

        this.hasStatus = true;

        this.batteryLevel = state.batteryPercent;
        this.usbConnection = state.usbPowered;
        this.bleConnection = state.bleConnected;

        this.state = state;
        this.deviceInfo = connection?.deviceInfo;

        this.onStateUpdated(state);
    }

    @HostListener('window:beforeunload')
    async ngOnDestroy() {
        if (this.listener) {
            if (this.currentInstrument) {
                this.currentInstrument.removeListener('updated-devicestate', this.listener);
            }
        } 
    }
    
    async onStateUpdated(state: InstrumentState) {

    }

    async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

        this.connectListeners(current,  before);
    }

    private connectListeners(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) {
        
        if (this.listener) {

            if (before) {
                before.removeListener('updated-devicestate', this.listener);
                this.deviceInfo = undefined;
                this.state = undefined;
            }

            if (current) {
                current.addListener('updated-devicestate', this.listener);
            }
        }

        this.hasStatus = false;
    }

    async pingInstrument() {
        if (this.currentInstrument) {
        this.currentInstrument.blinkDevice('green', 5, 100);
        }
    }

    async calibrateSensor() {
        if (this.currentInstrument) {
            this.currentInstrument.calibrateSensor();
        }
    }
}
