import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { rootViews } from 'src/app/helpers/log-config';
import { ListenerService } from 'src/app/services/listener.service';

const log = rootViews.getChildCategory('tuner');

const noteStrings = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

@Component({
  selector: 'app-tuner',
  templateUrl: './tuner.component.html',
  styleUrls: ['./tuner.component.scss']
})
export class TunerComponent implements OnInit {

  constructor(
    protected ls: ListenerService) {

    ls.on('pitch-detected', (pitch) => {
      if (pitch)
        this.displayValue = this.smoothNote(noteStrings[ pitch.index % 12 ]);
      else 
        this.displayValue = "No Note"
    });
  }
    
  ngOnInit(): void {
  }



  displayValue: String = "";

  protected smoothingValue: 'none' | 'basic' | 'very' = 'very';

  protected smoothingCount: number = 0;
  protected lastNote: string = "";
  protected currentNote: string = "";

  protected smoothNote(newNote: string) : string {

    let smoothingCountThreshold = 0;

    if (this.smoothingValue === 'none') {
      smoothingCountThreshold = 0;
    } 
    else if (this.smoothingValue === 'basic') {
      smoothingCountThreshold = 5;
    } 
    else if (this.smoothingValue === 'very') {
      smoothingCountThreshold = 10;
    }
  
    // Check if this value has been within the given range for n iterations
    if (newNote === this.lastNote) {
      if (this.smoothingCount < smoothingCountThreshold) {
        this.smoothingCount++;
      } 
      else {
        this.currentNote = newNote;
        this.smoothingCount = 0;
      }
    } 
    else {
      this.lastNote = newNote;
      this.smoothingCount = 0;
    }

    return this.currentNote;
  }

}

