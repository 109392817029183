import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { BackupService } from './../../services/backup.service';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Backup } from 'src/app/model/backup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestoreComponent } from 'src/app/views/restore/restore.component';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { PlatformLocation } from '@angular/common';
import { ReportComponent } from '../report/report.component';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-backup-list',
  templateUrl: './backup-list.component.html',
  styleUrls: ['./backup-list.component.scss']
})
export class BackupListComponent implements OnInit, OnDestroy {

  private _showAutomaticBackups: boolean = false

  @Input()
  set showAutomaticBackups(value: boolean ) {

    if (this._showAutomaticBackups != value) {
      this._showAutomaticBackups = value;
      this.reloadBackups();
    }
  }

  get showAutomaticBackups(): boolean {
    return this._showAutomaticBackups;
  }

  constructor(
    private modalService: NgbModal,
    private confirmationService: ConfirmationDialogService,
    private cd: ChangeDetectorRef,
    private fs: FeatureSupportService,
    private rs: ReportService,
    private backupService: BackupService) { }

  backups: Backup[] = [];

  private _backupListener = this.updatedBackups.bind(this);

  ngOnInit(): void {

    this.backupService.on('backups-changed', this._backupListener);

    this.reloadBackups();
  }

  ngOnDestroy() {
    this.backupService.off('backups-changed', this._backupListener);
  }
  
  updatedBackups(backups: Backup[]) : void {
    this.assignBackups(backups);
    this.cd.detectChanges();
  }


  private assignBackups(backups: Backup[]) {
    if (this._showAutomaticBackups) {
      this.backups = backups;
    }
    else {
      this.backups = backups.filter( backup => !backup.automatic );
    }
  }

  async reloadBackups() {

    this.backupService.getLocalBackups().then( (backups: Backup[]) => {
      this.updatedBackups(backups);
    });

  }

  async export(index: number) : Promise<void> {
    let backup = this.backups[index];

    if (backup) {
      this.backupService.export(backup);
    }
  }

  async details(index: number) : Promise<void> {

    let backup = this.backups[index];

    if (backup) {

      const modalRef = this.modalService.open(RestoreComponent, { centered: true, size: 'md', backdrop  : 'static', container: "#fullscreenContainer"  });
      let instance = <RestoreComponent>modalRef.componentInstance;

      instance.backup = backup;
    } 
  }

  async delete(id: string) : Promise<void> {
    
    this.confirmationService.confirm(
      'Delete Backup',
      'Do you want to delete the backup?',
      'Yes', 'No'
    )
    .then( result => {
      if (result) {
        this.backupService.deleteLocalBackup(id);
      }
    })
  }

  async exportPdf(index: number) {

    let backup = this.backups[index];

    if (backup) {
      this.rs.openReportDialogWithBackup(backup);
    }
  }

  can(...features: string[]) : boolean {
    return this.fs.can({}, ...features);
  }
}
