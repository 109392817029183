import { ChangeDetectorRef, Component,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { HardwareSupportGuard } from '../../services/hardware-support-guard';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
    public cd: ChangeDetectorRef,
    public fs: FeatureSupportService,
    private router: Router,
    public activeModal: NgbActiveModal,
    public hardwareSupport: HardwareSupportGuard) {}

  ngOnInit(): void {
  }

  can(...features: string[]) : boolean {
    return this.fs.can({  }, ...features);
  }

  clickedStart() {
    this.activeModal.close('main');
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl], { queryParamsHandling: "preserve" });
  }
}
