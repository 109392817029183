import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { DynamicComponentDirective } from '../dynamic-component.directive';
import { InstrumentViewComponentBaseComponent } from '../helpers/instrument-view-component-base.component';

@Component({
  selector: 'app-dynamic-component',
  templateUrl: './dynamic-component.component.html',
  styleUrls: ['./dynamic-component.component.scss']
})
export class DynamicComponentComponent implements OnInit {

  constructor() { 
  }

  @ViewChild(DynamicComponentDirective, {static: true}) componentHost!: DynamicComponentDirective;

  @Input()
  type?: Type<InstrumentViewComponentBaseComponent>;
  
  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {

    if (this.type) {
      const viewContainerRef = this.componentHost.viewContainerRef;
      viewContainerRef.clear();
  
      const componentRef = viewContainerRef.createComponent<InstrumentViewComponentBaseComponent>(this.type);

      componentRef.setInput("printing", false);
    }
  }
}
