// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backup {
  display: table;
  margin-bottom: 1rem;
}

.backup > div {
  display: table-row;
  vertical-align: baseline;
}

label {
  min-width: 6rem;
  vertical-align: baseline;
}

label,
span {
  display: table-cell;
  vertical-align: baseline;
}

input {
  width: 100%;
  vertical-align: baseline;
}

span {
  font-weight: bold;
}

.backup-content {
  margin-bottom: 2rem;
}

.selectable li {
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/app/views/backup-info/backup-info.component.scss"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,wBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,wBAAA;AAAJ;;AAGA;;EAEI,mBAAA;EACA,wBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,wBAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ","sourcesContent":["\n.backup {\n    display: table;\n    margin-bottom: 1rem;\n}\n\n.backup > div {\n    display: table-row;\n    vertical-align: baseline;\n}\n\nlabel {\n    min-width: 6rem;\n    vertical-align: baseline;\n}\n\nlabel,\nspan {\n    display: table-cell;\n    vertical-align: baseline;\n}\n\ninput {\n    width: 100%;\n    vertical-align: baseline;\n}\n\nspan {\n    font-weight: bold;\n}\n\n.backup-content {\n    margin-bottom: 2rem;\n}\n\n.selectable li {\n    list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
