import { Injectable } from '@angular/core';
import { TypedEmitter } from 'tiny-typed-emitter';
import { Recording } from '../model/recording';
import { v4 as uuid } from 'uuid'; 
import { instanceToPlain, plainToInstance } from 'class-transformer';


export interface RecordingServiceEvents {
  'recordings-changed': (recordings: Recording[]) => void;
}

@Injectable({
  providedIn: 'root'
})
export class RecordingsService extends TypedEmitter<RecordingServiceEvents> {


  constructor() {
    super();

    window.addEventListener('storage', this.onStorageChangeEvent.bind(this));
  }

  async deleteRecording(id: string) : Promise<Recording[]> {

    let recordings = await this.getLocalRecordings();

    recordings = recordings.filter( recording => {
      return (recording.id != id);
    });

    window.localStorage.recordings = JSON.stringify(instanceToPlain(recordings));

    this.emit('recordings-changed', recordings);

    return recordings;

  }

  async addLocalRecordings(newRecordings: Recording[]) : Promise<Recording[]> {

    let recordings = await this.getLocalRecordings();

    for (let recording of newRecordings) {

      if (recording.id == undefined || recording.id == '') {
        recording.id = uuid();
      }

      recordings = recordings.filter(item => (item.id != recording.id));
      recordings.push(recording);
    }

    recordings.sort( (a, b) => (new Date(b.date).getTime() - new Date(a.date).getTime()));


    let data = JSON.stringify(instanceToPlain(recordings));
    window.localStorage.recordings = data;

    this.emit('recordings-changed', recordings);

    return recordings;
  }

  async addLocalRecording(recording: Recording) : Promise<Recording[]> {
    return await this.addLocalRecordings([ recording ]);
  }

  async getLocalRecordings() : Promise<Recording[]> {

    if (window.localStorage) {

      let json = window.localStorage.recordings;

      if (json) {
        let recordings = JSON.parse(json).map((value: any) => plainToInstance(Recording, value));
        let modified = false;

        if (modified) {
          let data = JSON.stringify(instanceToPlain(recordings));
          window.localStorage.recordings = data;
        }
        
        return recordings;
      }

    }

    return [];
  }

  async onStorageChangeEvent(ev: StorageEvent) {

    if (ev.key == "recordings") {
      let recordings = await this.getLocalRecordings();
      this.emit('recordings-changed', recordings);
    }
  }

  async export(recording: Recording) : Promise<void> {

    let str = JSON.stringify(instanceToPlain(recording), undefined, 3);
    this.download("recording", "emeo", str);
  }

  async import() : Promise<void> {
    let recordings = await this.getTheFile("EMEO Recording", "emeo");
    
    if (recordings) {
      this.addLocalRecordings(recordings);
    }
  }  

  async getTheFile(description: string, extension: string) : Promise<Recording[] | undefined> {

    const pickerOpts = {
      types: [
        {
          description: description,
          accept: {
            'application/*': [`.${extension}`]
          }
        },
      ],
      excludeAcceptAllOption: true,
      multiple: true
    };

    let result: Recording[] = [];

    try {
      // open file picker
      let fileHandles = await (<any>window).showOpenFilePicker(pickerOpts);

      for (var fileHandle of fileHandles) {
        // get file contents
        const fileData = await fileHandle.getFile();
        const text = await fileData.text();
        const json = JSON.parse(text);

        const recording = plainToInstance(Recording, json);

        result.push(recording);
      }
      
      return result;
    }
    catch (e) {
      return undefined;
    }
  }  

  download(filename: string, extension: string, data: string) {

    const suffix = new Date().toISOString();

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', `${filename}-${suffix}.${extension}`);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }  

  async getRecordingsForScore(id: string): Promise<Recording[]> {
    let recordings = await this.getLocalRecordings();
    return recordings.filter( (recording) => recording.scoreId == id);
  }
}


