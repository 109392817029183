// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tapeBand {
  width: 100%;
}

.dialog {
  margin: 5px;
}

.recordings {
  min-height: 5em;
  max-height: 15em;
  overflow-y: scroll;
}

.control-group {
  margin-left: 3em;
}`, "",{"version":3,"sources":["webpack://./src/app/views/learn/tape-dialog/tape-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAGA;EACE,WAAA;AAAF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":[".tapeBand {\n  width: 100%;\n}\n\n\n.dialog {\n  margin: 5px;\n}\n\n\n.recordings {\n  min-height: 5em;\n  max-height: 15em;\n  overflow-y: scroll;\n}\n\n.control-group {\n  margin-left: 3em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
