import { Recording } from "./recording";

export class Score {

  id: string;

  title: string;

  score: string;

  recordings: Recording[] = [];
  
  constructor(id: string, title: string, score: string) {
    this.id = id;
    this.title = title;
    this.score = score;
  }
}
