import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { rootViews } from 'src/app/helpers/log-config';
import { MidiNoteConfig, MIDI_NOTES } from 'src/app/helpers/notes';
import { AnnotationEvent, BreathEvent, KeyEvent, ListenedNoteEvent, MetronomeEvent, NoteBaseEvent, NoteEvent, Recording, ScoreEvent } from 'src/app/model/recording';
import { decodeKeys, UNCType } from 'src/app/model/uncsettings';
import { InstrumentInfo } from 'src/app/model/unc-types';

import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import { EMEO_SAX_V1_INFO } from 'src/app/model/instruments/emeo-sax-v1';

const log = rootViews.getChildCategory("learning");

const keyOrder = [
  'Octave',
  
  'HighEFlat',
  'HighD',
  'HighF',
  
  'AddtlHighF',
  'B',
  'BFlat',
  'C',
  'G',

  'PinkyGSharp',
  'PinkyB',
  'PinkyCSharp',
  'PinkyBb',
  
  'F',
  'E',
  'D',
  
  'HighE',
  'PalmC',
  'PalmBFlat',
  
  'AddtlFSharp',
  'DSharp',
  'LowC',
];

const SCORE_REFERENCE_NOTE : number = 65; // F2

const DISTANCE_SCORE_LINES : number = 10;

const SCORE_TOP_SPACE         = 3 * DISTANCE_SCORE_LINES;
const SCORE_LINES_HEIGHT      = 5 * DISTANCE_SCORE_LINES;
const SCORE_BOTTOM_SPACE      = 3 * DISTANCE_SCORE_LINES;

const SCORE_HEIGHT            = SCORE_TOP_SPACE + SCORE_LINES_HEIGHT + SCORE_BOTTOM_SPACE;

const MIDI_SCORE_TOP          = 20;
const OFFSET_MIDI_SCORE       = MIDI_SCORE_TOP + SCORE_TOP_SPACE;
const MIDI_SCORE_BOTTOM       = MIDI_SCORE_TOP + SCORE_HEIGHT;

const WIDTH_MAX_PRESSURE_LINE = 30;
const OFFSET_PRESSURE_LINE    = MIDI_SCORE_BOTTOM + 10 + WIDTH_MAX_PRESSURE_LINE / 2;

const LISTENED_SCORE_TOP      = OFFSET_PRESSURE_LINE + 10 + WIDTH_MAX_PRESSURE_LINE / 2;
const OFFSET_LISTENED_SCORE   = LISTENED_SCORE_TOP + SCORE_TOP_SPACE;
const LISTENED_SCORE_BOTTOM   = LISTENED_SCORE_TOP + SCORE_HEIGHT;

const OFFSET_KEYS             = LISTENED_SCORE_BOTTOM + 10;
const DISTANCE_KEYS           = 10  ;

@Component({
  selector: 'app-tape-slice',
  templateUrl: './tape-slice.component.html',
  styleUrls: ['./tape-slice.component.scss']
})
export class TapeSliceComponent implements AfterViewInit, OnChanges {

  /** Template reference to the canvas element */
  @ViewChild('imageContainer') 
  container!: ElementRef<HTMLDivElement>;

  protected _stage?: Konva.Stage;

  protected _backgroundLayer?: Konva.Layer;

  protected _linesLayer?: Konva.Layer;

  protected _contentLayer?: Konva.Layer;

  protected _annoLayer?: Konva.Layer;

  constructor() { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit(): void {

    if (this.container?.nativeElement) {

      let width = this.container.nativeElement.clientWidth;
      let height = this.container.nativeElement.clientHeight;

      if (width * height == 0) {
        let self = this;
        const observer = new ResizeObserver(entries => {
          entries.forEach(entry => {
            log.debug("resize detected");
            self.prepareLayers();
            this.render();
          });
        });
        
        observer.observe(this.container.nativeElement);
        return;
      }

      this.prepareLayers();
      this.render();
    }
    else {
      log.error("Could not initialize slice #" + this.slice);
    }
  }

  protected prepareLayers() {

    log.debug("Preparing layers for slice #" + this.slice);

    if (this._stage) return;

    let width = this.container.nativeElement.clientWidth;
    let height = this.container.nativeElement.clientHeight;

    this._stage = new Konva.Stage({
      container: this.container.nativeElement,
      width: width,
      height: height,
    });

    this._backgroundLayer = new Konva.Layer({
      clearBeforeDraw: true,
      listening: false,
      offsetX: 0.0,
      offsetY: 0.5,
    });

    this._linesLayer = new Konva.Layer({
      clearBeforeDraw: true,
      listening: false,
      offsetX: 0.0,
      offsetY: 0.5,
    });

    this._contentLayer = new Konva.Layer({
      clearBeforeDraw: true,
      listening: true,
      offsetX: 0.0,
      offsetY: 0.5,
    });

    this._annoLayer = new Konva.Layer({
      clearBeforeDraw: true,
      listening: true,
      offsetX: 0.0,
      offsetY: 0.5,
      opacity: 0.4,
    });

    this._stage
      .add(this._backgroundLayer)
      .add(this._linesLayer)
      .add(this._contentLayer)
      .add(this._annoLayer);
  }

  private render() {
      
    if (this._backgroundLayer?.children?.length == 0 && this._linesLayer) {
      this.doRenderBackground(this._backgroundLayer, this._linesLayer);        
    }

    if (this._contentLayer && this._linesLayer) {
      this.doRender(this._contentLayer, this._linesLayer);
    }

    if (this._annoLayer) {
      this.doRenderAnnotations(this._annoLayer);
    }

    this._stage?.draw();
  }

  debug: boolean = false;

  //#region Rendering

  private doRenderBackground(layer: Konva.Layer, linesLayer: Konva.Layer) {

    layer.destroyChildren();

    let height = layer.height();

    if (this.debug) {
      layer.add(new Konva.Line({
        strokeWidth: 1,
        stroke: "white",
        points: [0, 0, 0, height], 
      }));
    }
    
    this.doRenderScoreLines(layer);
    this.doRenderScoreLines(layer, OFFSET_LISTENED_SCORE);
    
    this.doRenderBreathLine(layer);
    
    if (this.slice != 0) this.doRenderKeyLines(layer);
  }
  
  private doRenderAnnotations(layer: Konva.Layer) {

    let recording = this._recording;

    if (recording)
      recording
        .interval(this.startTime - this.interval, 2 * this.interval)
        .filter( event => (event instanceof AnnotationEvent))
        .forEach( event => this.doRenderAnnotation(layer, event as AnnotationEvent));
  }

  private doRender(layer: Konva.Layer, linesLayer: Konva.Layer) {

    layer.destroyChildren();
    linesLayer.destroyChildren();

    let width = layer.width();
    let height = layer.height();
 
    if (this.debug) {
      layer
        .add(new Konva.Text({
          x: width / 2,
          y: height / 2,
          text: "" + this.slice,
          fontFamily: "Arial",
          fontSize: 10,
          align: "center",
          fill: "yellow"
        }))
        .add(new Konva.Text({
          x: 0,
          y: 15,
          text: Math.floor(this.startTime).toString(),
          fontFamily: "Arial",
          fontSize: 10,
          align: "left",
          fill: "yellow"
        }))
        .add(new Konva.Text({
          x: width - 5,
          y: height - 15,
          text: Math.floor(this.startTime + this.interval).toString(),
          fontFamily: "Arial",
          fontSize: 10,
          align: "right",
          fill: "yellow"
        }))
        .add(new Konva.Line({
          points: [0, 0, width, height],
          stroke: "yellow",
          strokeWidth: 1
        }));
    }

    if (this._slice == 0) {
      this.doRenderHeads(layer);
    }
    else if (this._recording) {
      this.doRenderSlice(layer, linesLayer, this._recording);
    }
  }

  private doRenderHeads(layer: Konva.Layer) {
    this.doRenderKeyNames(layer);

    this.doRenderScoreHeads(layer, OFFSET_MIDI_SCORE, DISTANCE_SCORE_LINES);
    this.doRenderScoreHeads(layer, OFFSET_LISTENED_SCORE, DISTANCE_SCORE_LINES);
  }

  private doRenderScoreHeads(layer: Konva.Layer, initialOffset: number = OFFSET_MIDI_SCORE, lineDistance: number = DISTANCE_SCORE_LINES) {

    let width = layer.width();
    let height = layer.height();

    Konva.Image.fromURL('assets/score/violinKey.svg', (shape: Shape) => {

      shape.attrs.x = 10;
      shape.attrs.y = initialOffset - 20;

      layer.add(shape);
    });

  }

  private doRenderScoreLines(layer: Konva.Layer, initialOffset: number = OFFSET_MIDI_SCORE, lineDistance: number = DISTANCE_SCORE_LINES) {
    
    var y: number = initialOffset;

    let width = layer.width();

    for(var i = 0; i < 5; i++) {
      layer.add(new Konva.Line({
        points: [0, y, width, y],
        strokeWidth: 1.5,
        stroke: "white"
      }));

      y += lineDistance;
    }
  }


  private doRenderSlice(layer: Konva.Layer, linesLayer: Konva.Layer, recording: Recording) {

    recording
        .interval(this.startTime - this.interval, 2 * this.interval)
        .filter( event => (event instanceof MetronomeEvent))
        .forEach( event => this.doRenderTimeBar(layer, event as MetronomeEvent));

    recording
        .interval(this.startTime - this.interval, this.interval)
        .filter( event => (event instanceof MetronomeEvent) && event.tick == 1)
        .forEach( event => this.doRenderTimeBar(layer, event as MetronomeEvent));

    recording
        .interval(this.startTime, this.interval)
        .filter( event => (event instanceof ScoreEvent))
        .forEach( event => this.doRenderNote(layer, linesLayer, event as ScoreEvent, DISTANCE_SCORE_LINES / 2, "lightblue", "lightgray"));

    recording    
        .interval(this.startTime, this.interval)
        .filter( event => (event instanceof NoteEvent))
        .forEach( event => this.doRenderNote(layer, linesLayer, event as NoteEvent, DISTANCE_SCORE_LINES / 2, "white", "orange", this.transposeInstrument));
    
    recording    
        .interval(this.startTime, this.interval)
        .filter( event => (event instanceof ListenedNoteEvent))
        .forEach( event => this.doRenderNote(layer, linesLayer, event as ListenedNoteEvent, DISTANCE_SCORE_LINES / 2, "white", "orange", this.transposeVoice, OFFSET_LISTENED_SCORE));

    let breaths = recording
      .interval(this.startTime, this.interval)
      .filter( event => (event instanceof BreathEvent));

    if (breaths.length > 1) {
      breaths.reduce( (previous, current) => { this.doRenderBreath(layer, previous as BreathEvent, current as BreathEvent); return current; });
    }

    let keys = recording
      .interval(this.startTime, this.interval)
      .filter( event => (event instanceof KeyEvent));

    if (keys.length > 1) {
      keys.reduce( (previous, current, index) => { this.doRenderKeys(layer, previous as KeyEvent, current as KeyEvent); return current; });
    }

  }

  private doRenderAnnotation(layer: Konva.Layer, annotation: AnnotationEvent) {

    let width = layer.width();
    let height = layer.height();

    let annoWidth = this.sc(annotation.startTime + annotation.duration, width) - this.sc(annotation.startTime, width);

    var annoShape = new Konva.Shape({
      x: this.sc(annotation.startTime, width),
      y: height - 15,
      fill: 'blue',

      // a Konva.Canvas renderer is passed into the sceneFunc function
      sceneFunc (context, shape) {
        context.beginPath();
        context.moveTo(0, 5);
        context.lineTo(annoWidth, 5);
        context.closePath();
        // Konva specific method
        context.strokeShape(shape);
      }
    });
    
    layer.add(annoShape);
    
  }

  private doRenderKeyNames(layer: Konva.Layer, instrument: InstrumentInfo = EMEO_SAX_V1_INFO) {

    var y = OFFSET_KEYS;
    var h = DISTANCE_KEYS;

    let width = layer.width();

    keyOrder.forEach( key => {

      var i = Object.values(instrument.keyMap).findIndex( v => v.name == key );
      let keyInfo = instrument.keyMap[i];

      layer.add(new Konva.Text({
        text: keyInfo.name,
        x: 10,
        y: y - h/2,
        width: width - 30,
        height: h,
        align: "right",
        verticalAlign: "middle",
        fill: "white",
        fontFamily: "Arial",
        fontSize: 10
      }));

      layer.add(new Konva.Line({
        points: [width - 15, y, width, y],
        strokeWidth: 0.5,
        stroke: "lightgray"
      }));

      y += h;
    });
  }

  private doRenderBreathLine(layer: Konva.Layer) {

    var y = OFFSET_PRESSURE_LINE;

    let width = layer.width();

    layer.add(new Konva.Line({
      points: [0, y, width, y],
      strokeWidth: 0.5,
      stroke: "#eeeeee",
      closed: true
    }));

  }
  
  private doRenderKeyLines(layer: Konva.Layer) {

    var y = OFFSET_KEYS;
    var h = DISTANCE_KEYS;

    let width = layer.width();

    keyOrder.forEach( key => {

      layer.add(new Konva.Line({
        points: [0, y, width, y],
        strokeWidth: 0.5,
        stroke: "lightgray"
      }));

      y += h;
    });
  }

  private doRenderKeys(layer: Konva.Layer, previous: KeyEvent, current: KeyEvent, instrument: InstrumentInfo = EMEO_SAX_V1_INFO) {

    var y = OFFSET_KEYS;
    var h = DISTANCE_KEYS;

    let width = layer.width();

    let currentKeys = decodeKeys(current.keys);
    let previousKeys = decodeKeys(previous.keys);

    var fillStyle = "#ffdd33";


    switch (current.uncType) {
      case UNCType.FACTORY:
        fillStyle = "yellow";
        break;
      case UNCType.USER:
        fillStyle = "blue";
        break;
      case UNCType.INVALID:
        fillStyle = "orange";
        break;

      default:
        fillStyle = "yellow";
        break;
    }

    keyOrder.forEach( key => {

      var i = Object.values(instrument.keyMap).findIndex( v => v.name == key );
      let keyInfo = instrument.keyMap[i];

      let inPrevious = (previousKeys.findIndex( value => value === keyInfo.name ) != -1);
      let inCurrent = (currentKeys.findIndex( value => value === keyInfo.name ) != -1);

      if (inPrevious && inCurrent) {
        var radius = DISTANCE_KEYS / 2 - 1;

        layer.add(new Konva.Rect({
          x: this.sc(previous.time, width),
          y: y - radius,
          width: this.sc(current.time, width) - this.sc(previous.time, width),
          height: radius * 2,
          fill: fillStyle,
          fillEnabled: true,
          strokeEnabled: false,
          cornerRadius: radius / 3,
        }));
      }

      y += h;
    });
  }

  private doRenderBreath(layer: Konva.Layer, previous: BreathEvent, current: BreathEvent) {

    var y = OFFSET_PRESSURE_LINE;
    var h = WIDTH_MAX_PRESSURE_LINE;

    let width = layer.width();

    layer.add(new Konva.Line({
      points: [
          this.sc(previous.time, width), y,
          this.sc(previous.time, width), y + previous.pressure / 127.0 * h,
          this.sc(current.time, width), y + current.pressure / 127.0 * h,
          this.sc(current.time, width), y,
          this.sc(current.time, width), y - current.pressure / 127.0 * h,
          this.sc(previous.time, width), y - previous.pressure / 127.0 * h,
          this.sc(previous.time, width), y
      ],
      fillEnabled: true,
      closed: true,
      stroke: "#eeeeee",
      fill: "#eeeeee",
    }));
  }

  private getNoteConfig(note: number) : MidiNoteConfig | undefined {

    let index = MIDI_NOTES.findIndex( (value) => value.code == note );
    let noteInfo = MIDI_NOTES[index];

    return noteInfo;
  }

  private calcNoteOffset(note: number, referenceNote: number = 48 /* C */) : number | undefined {

    let refInfo = this.getNoteConfig(referenceNote);
    let targetInfo = this.getNoteConfig(note);

    if (refInfo && targetInfo) {

      // Distance 7 notes per octave
      let distance = 7 * (refInfo.oct - targetInfo.oct);

      distance += (refInfo.offset - targetInfo.offset); 

      return distance;
    }

    return undefined;
  }

  private doRenderNote(
    layer: Konva.Layer, 
    linesLayer: Konva.Layer, 
    event: NoteBaseEvent, 
    radius: number = DISTANCE_SCORE_LINES / 2, 
    strokeStyle: string = "#bbbbbb", fillStyle: string = "white", 
    transpose: number = 0, 
    topOffset: number = OFFSET_MIDI_SCORE) {

    if (!event.isTone) {
      return;
    }

    let width = layer.width();

    let distance = this.calcNoteOffset(event.note + transpose, SCORE_REFERENCE_NOTE);

    if (distance) {

      var d = DISTANCE_SCORE_LINES / 2;
      var y = topOffset + d * distance;
    
      layer.add(new Konva.Rect({
        strokeStyle: strokeStyle,
        strokeWidth: 0.5,
        fill: fillStyle,
        x: this.sc(event.time, width),
        y: y - radius,
        width: this.sc(event.time + event.duration, width) - this.sc(event.time, width),
        height: radius * 2,
        fillEnabled: (fillStyle != 'none'),
        strokeEnabled: (strokeStyle != 'none'),
        cornerRadius: radius,
      }));

      var renderHelpLine = false;
      
      do {
        renderHelpLine = false;
        var offset = 0;

        if (distance < 0 && (distance % 2) == 0) {
          renderHelpLine = true;
          offset = 2;
        }
  
        if (distance > 8 && (distance % 2) == 0) {
          renderHelpLine = true;
          offset = -2;
        }
  
        if (renderHelpLine) {
          linesLayer.add(new Konva.Line({
            points: [
              this.sc(event.time, width) - 5,
              y,
              this.sc(event.time + event.duration, width) + 5,
              y
            ],
            strokeWidth: 1.5,
            stroke: "white"
          }));
        }    

        distance += offset;
        y = topOffset + d * distance;

      } while (renderHelpLine);
    }
  }

  private sc(time: number, width: number) : number {
    return Math.floor((time - this.startTime) / this.interval * width) + 0.5;

    // return Math.min(Math.max(time - this.startTime, 0), this.interval) / this.interval * width;
  }

  private doRenderTimeBar(layer: Konva.Layer, event: MetronomeEvent) {

    let width = layer.width();
    let height = layer.height();

    let x = (event.time - this.startTime) / this.interval * width;

    layer.add(new Konva.Line({
      stroke: "#ffffff",
      strokeWidth: (event.tick == 1 ? 1.5 : 0.5),
      points: [x, 0, x, height]
    }));


    if (event.tick == 1) {
      layer.add(new Konva.Text({
        stroke: "white",
        fill: "white",
        align: "left",
        fontFamily: "serif",
        fontSize: 15,
        verticalAlign: "ideographic",
        text: "" + event.measure,
        x: x + 5, 
        y: 10,
        fillEnabled: true,
        strokeEnabled: false
      }));
    }
  }

  //#endregion


  public get startTime() : number {
    return (this.slice - 1) * this.interval + (this.recording?.firstTimestamp || 0);
  }

  private _transposeVoice: number = 0;
  
  @Input()
  public get transposeVoice(): number {
    return this._transposeVoice;
  }
  public set transposeVoice(value: number) {
    this._transposeVoice = value;
  }


  private _transposeInstrument: number = 0;

  @Input()
  public get transposeInstrument(): number {
    return this._transposeInstrument;
  }
  public set transposeInstrument(value: number) {

    if (this._transposeInstrument === value) return;

    this._transposeInstrument = value;
    this.render();
  }

  private _slice: number = 0;
  
  @Input()
  public get slice(): number {
    return this._slice;
  }
  public set slice(value: number) {

    if (this._slice === value) return;

    this._slice = value;
    this.render();
  }

  private _interval: number = 5;

  @Input()
  public get interval(): number {
    return this._interval;
  }
  public set interval(value: number) {
    this._interval = value;
  }

  private _recording: Recording | undefined = undefined; 

  @Input()
  get recording(): Recording | undefined {
    return this._recording;
  }

  set recording(recording: Recording | undefined) {

    if (this._recording === recording) return;

    this._recording = recording;
    this.render();
  }  
}
