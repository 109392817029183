// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tape-display .tape-viewport {
  height: 530px;
  width: calc(100% - 100px);
  overflow-y: hidden;
}

.tape-slice {
  float: left;
  white-space: nowrap;
  height: 500px;
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/learn/tape-view/tape-view.component.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,yBAAA;EAEA,kBAAA;AAJF;;AAOA;EACE,WAAA;EACA,mBAAA;EAEA,aAbY;EAcZ,YAfW;AAUb","sourcesContent":["\n$sliceWidth: 100px;\n$sliceHeight: 500px;\n\n.tape-display .tape-viewport {\n  height: calc($sliceHeight + 30px);\n  width: calc(100% - $sliceWidth);\n\n  overflow-y: hidden;\n}\n\n.tape-slice {\n  float: left;\n  white-space: nowrap;\n\n  height: $sliceHeight;\n  width: $sliceWidth;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
