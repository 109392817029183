import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    let val: number = (value as number);
    let millis = Math.floor(val % 1000);

    let seconds = Math.floor(val / 1000);
    let minutes = Math.floor(seconds / 60);

    seconds -= minutes * 60;
    
    return minutes + "min " + seconds + "s " + millis + "ms";
  }

}
