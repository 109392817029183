import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureSelectorComponent } from './views/feature-selector/feature-selector.component';
import { MainComponent } from './views/main/main.component';

const routes: Routes = [
  { path: "feature", component: FeatureSelectorComponent },
  { path: "feature.html", component: FeatureSelectorComponent },
  { path: "", component: MainComponent },
  { path: "**", redirectTo: "/", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
