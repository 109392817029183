// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rhythm span {
  margin-left: 1em;
}

.score {
  min-height: 90vh;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.dialog {
  margin: 5px;
}

app-visualizer {
  width: 150px;
  height: 30px;
}

app-volume-meter {
  width: 10px;
  height: 30px;
}

app-score {
  min-height: calc(100% - 30px);
}

.bi-stop-circle {
  animation: blinker 1s ease-in-out infinite alternate;
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/views/learn/score-dialog/score-dialog.component.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,YAAA;EACA,YAnBS;AAkBX;;AAIA;EACE,WAAA;EACA,YAxBS;AAuBX;;AAIA;EACE,6BAAA;AADF;;AAIA;EACE,oDAAA;AADF;;AAIA;EAAqB;IAAK,UAAA;EACxB;AACF","sourcesContent":["$hToolbar: 30px;\n\n.rhythm span {\n  margin-left: 1em;\n}\n\n.score {\n  min-height: 90vh;\n  max-height: 90vh;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.dialog {\n  margin: 5px;\n}\n\napp-visualizer {\n  width: 150px;\n  height: $hToolbar;\n}\n\napp-volume-meter {\n  width:  10px;\n  height: $hToolbar;  \n}\n\napp-score {\n  min-height: calc(100% - $hToolbar);\n}\n\n.bi-stop-circle {\n  animation: blinker 1s ease-in-out infinite alternate;  \n}\n\n@keyframes blinker { to { opacity: 0; } }\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
