import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { FirmwareInfo } from 'src/app/model/firmware-info';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-device-summary',
  templateUrl: './device-summary.component.html',
  styleUrls: ['./device-summary.component.scss']
})
export class DeviceSummaryComponent extends InstrumentComponentBase implements OnInit {

  @Input()
  firmwares: FirmwareInfo[] = [];

  @Input()
  allowUpdates: boolean = false;
  
  constructor(
    protected cs: ConnectionService,
    private cd: ChangeDetectorRef) { 

    super(cs);
  }


  ngOnInit(): void {
  }

}
