

function bestRepresentation(value: number | string | undefined) : number | string | undefined {
  if (typeof(value) === 'string') {
    let temp = Number.parseInt(value);

    if (Number.isInteger(temp) && String(temp) === value) {
      value = temp;
    }
  }

  return value;
}

export function compareVersions(vs1: string, vs2: string) : number {
    
  let v1 = decomposeVersion(vs1);
  let v2 = decomposeVersion(vs2);

  for (var i = 0; i < v1.length; i++) {
    let left = v1[i];
    let right = v2[i];

    // Special treatment for string field if it is a number
    if (i == 2) {
      left = bestRepresentation(left);
      right = bestRepresentation(right);

      if (typeof(left) !== typeof(right)) {
        left = String(left); 
        right = String(right); 
      }
    }

    if (left) {

      if (right) {
        if (left > right) return -1;
        if (left < right) return 1;
      }
      else {
        return -1;
      }
    }
    else {
      if (right) {
        return 1;
      }
    }
  }

  return 0;
}

export function decomposeVersion(version: string) : [ number, number, string | number, number? ] {

  // Break the version into pieces, separated by dot
  let pieces = version.split('.');

  var major = Number.parseInt(pieces[0]);
  var minor = 0;
  var revision: number | string = '';

  if (pieces.length > 1) {
    minor = Number.parseInt(pieces[1]);
  
    if (pieces.length > 2) {
      revision = Number.parseInt(pieces[2]);
    }
    else {
      revision = pieces[1];
  
      while (revision.length > 0) {
        if (revision.charAt(0) >= '0' && revision.charAt(0) <= '9') {
          revision = revision.substring(1);
        }
        else 
          break;
      }
    }
  }

  major = (Number.isNaN(major) ? 0 : major);
  minor = (Number.isNaN(minor) ? 0 : minor);
  
  var result: [ number, number, string | number, number? ] = [ major, minor, revision ];

  if (pieces.length > 3) {
    let build = Number.parseInt(pieces[3]);
    if (!Number.isNaN(build)) {
      result.push(build);
    }
  }

  return result;
}

