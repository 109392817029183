// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicator {
  display: inline-block;
  width: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/progress/progress.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,WAAA;AACJ","sourcesContent":[".indicator {\n    display: inline-block;\n    width: 2rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
