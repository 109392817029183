import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OpenSheetMusicDisplay, Cursor, Note, PointF2D, CursorType } from "opensheetmusicdisplay";
import { rootViews } from 'src/app/helpers/log-config';
import { kgv } from 'src/app/helpers/helper';
import { trigger } from '@angular/animations';

const log = rootViews.getChildCategory("score");

export interface ScoreLoadedInfo { 
  bpm: number;
  
  rhythmDenominator: number; 
  rhythmNumerator: number;

  ticks: number;
}

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements AfterViewInit, OnChanges {

  /**
   * the URL to, or the contents of a valid MusicXML document.
   */
  @Input()
  private _source: string = "";
  public get source(): string {
    return this._source;
  }
  public set source(value: string) {

    if (this._source === value) return;

    this._source = value;
    if (this.openSheetMusicDisplay) {
      this.renderMusicXml(this.openSheetMusicDisplay, this.source);
    }
  }

  private openSheetMusicDisplay?: OpenSheetMusicDisplay;

  @ViewChild('scoreContainer')
  container! : ElementRef<HTMLDivElement>;

  constructor() {
  }

  ngAfterViewInit() {

    if (this.container?.nativeElement) {

      this.initRenderer(this.container?.nativeElement);

      if (this.openSheetMusicDisplay) {
        this.renderMusicXml(this.openSheetMusicDisplay, this.source);
      }
  
      let width = this.container.nativeElement.clientWidth;
      let height = this.container.nativeElement.clientHeight;

      if (width * height == 0) {

        log.debug("Score container is still too small");

        let self = this;
        const observer = new ResizeObserver(entries => {
          entries.forEach(entry => {
            this.render();
            log.debug("Score container was resized.")
          });
        });
        
        observer.observe(this.container.nativeElement);
        return;
      }
    };

  }

  /**
   * Initializes the renderer.
   */
  private initRenderer(canvas: HTMLDivElement) {
    if (this.container.nativeElement) {
      this.openSheetMusicDisplay = new OpenSheetMusicDisplay(canvas);
      this.openSheetMusicDisplay.setLogLevel('info');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.openSheetMusicDisplay) {
      this.renderMusicXml(this.openSheetMusicDisplay, this.source);
    }
  }

  private _cursor: Cursor | undefined = undefined;

  cursorShow() {
    this._cursor = this.openSheetMusicDisplay?.cursor;

    if (this._cursor) {
      (<any>this._cursor).cursorOptions = {
        alpha: 0.5,
        color: "#3333cc",
        follow: true,
        type: CursorType.ThinLeft
      }

      this._cursor.reset();
      this._cursor.show();
    }
  }
  
  cursorNotes() : Note[] | undefined {
    return this._cursor?.NotesUnderCursor();
  }

  cursorNext() : Note[] {
    this._cursor?.next();

    let notes = this._cursor?.NotesUnderCursor() || [];

    notes?.forEach(note => log.debug("Note: " + note.ToString()));

    return notes;
  }

  public bpm : number = 80;

  public rhythmDenominator: number = 4;
  public rhythmNumerator: number = 4;

  @Output()
  scoreLoaded = new EventEmitter<ScoreLoadedInfo>();

  public calculateTicks(osmd: OpenSheetMusicDisplay) : number {

    let measure = osmd.Sheet.SourceMeasures[0];
    let den = measure.ActiveTimeSignature.Denominator;
    let num = measure.ActiveTimeSignature.Numerator;
    let tempo = measure.TempoInBPM

    this.bpm = tempo;
    this.rhythmDenominator = den;
    this.rhythmNumerator = num;
    
    osmd.Sheet.SourceMeasures.forEach( (sm, index) => {
      let ki = sm.getKeyInstruction(0);
      let se = sm.FirstInstructionsStaffEntries[0];

      if (se && se.Instructions.length > 0) {
        se.Instructions.forEach( (i) => {
          log.debug("Measure #" + index + " instructions: " + i.constructor.name);
        })
      }
    });
    
    const denominators = new Set<number>();

    var allNotes = [] as any[];
    osmd.cursor.reset()
    const iterator = osmd.cursor.Iterator;

    while (!iterator.EndReached) {
      const voices = iterator.CurrentVoiceEntries;

      for(var i = 0; i < voices.length; i++){
      
        const v = voices[i];
        const notes = v.Notes;

        const bpm = iterator.CurrentMeasure.TempoInBPM || 60;
        for (var j = 0; j < notes.length; j++) {
              const note : Note = notes[j];
              // make sure our note is not silent
              if (note != null && note.halfTone != 0 && !note.isRest()) {
                  denominators.add(note.Length.Denominator);

                  allNotes.push({
                    "note": note.halfTone+12, // see issue #224
                    "time": iterator.currentTimeStamp.RealValue * 4 * 60/bpm,
                    "rv": iterator.currentTimeStamp.RealValue,
                    "bpm": bpm
                  })
              }
          }
      }
      iterator.moveToNext()
    }

    const denoms = Array.from(denominators.keys());

    let ticks = kgv(...denoms);

    this.scoreLoaded.emit({
      bpm: tempo,
      
      rhythmDenominator: den,
      rhythmNumerator: num,

      ticks: ticks || 4
    });

    return ticks || 4;
  }

  private _ticks: number = 4;

  get ticks(): number {
    return this._ticks;
  }

  public render() {
    if (this.openSheetMusicDisplay) {
      if (this.openSheetMusicDisplay.IsReadyToRender()) {

        let width = this.container.nativeElement.clientWidth;
        let height = this.container.nativeElement.clientHeight;
  
        if (width * height > 0) {
          this.openSheetMusicDisplay.render();
          this._ticks = this.calculateTicks(this.openSheetMusicDisplay);
        } 
      }
    }
  }

  /**
   * Renders the MusicXML file.
   *
   * @param osmd the renderer.
   * @param source the URL to, or the contents of a valid MusicXML document.
   */
  private renderMusicXml(osmd: OpenSheetMusicDisplay, source: string) {
    if (osmd && source != "") {
      log.debug("Loading score " + source);

      osmd.load(source).then(() => {
        osmd.setOptions({defaultColorMusic: "#FFFFFF"});
        osmd.EngravingRules.PageBackgroundColor = "#303030";

        if (this.container.nativeElement) {
          let canvas = this.container.nativeElement;

          osmd.render();  

          log.debug("Rendering completed");
        }
      });
    } 
    else {
      console.warn('MusicXML renderer not yet initialized.');
    }

  }


  public getOSMDCoordinates(clickLocation: PointF2D): PointF2D | undefined {

    if (this.container.nativeElement) {

      let container = this.container.nativeElement;

      const sheetX: number = (clickLocation.x - container.offsetLeft) / 10;
      const sheetY: number = (clickLocation.y - container.offsetTop) / 10;

      return new PointF2D(sheetX, sheetY);
    }

    return undefined;
  }



  public mouseClicked(mouseEvent: MouseEvent) {
    let osmd = this.openSheetMusicDisplay;
    if (!osmd) return;

    const clickLocation = new PointF2D(mouseEvent.offsetX, mouseEvent.offsetY);
    const sheetLocation = this.getOSMDCoordinates(clickLocation);
    const maxDist = new PointF2D(5, 5);

    if (sheetLocation) {

      const nearestNote = osmd.GraphicSheet.GetNearestNote(sheetLocation, maxDist);
  
      let note = osmd.GraphicSheet.GetNearestNote(sheetLocation, maxDist);
  
      if (note) {
        log.debug("Clicked note: " + note.sourceNote.ToString());
      }
    }
  }
}
