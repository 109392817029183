import { InstrumentConnection } from '../../model/instrument-connection';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Backup } from 'src/app/model/backup';
import { BackupService } from 'src/app/services/backup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionService } from 'src/app/services/connection.service';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { ConfirmationDialogService } from 'src/app/views/confirmation-dialog/confirmation-dialog.service';
import { BackupInfoComponent } from '../backup-info/backup-info.component';
import { ProgressObserver } from 'src/app/model/progress-observer';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent extends InstrumentComponentBase implements OnInit {

  constructor(
    protected cds: ConfirmationDialogService,
    protected cd: ChangeDetectorRef,
    protected cs: ConnectionService,
    public activeModal: NgbActiveModal,
    private backupService: BackupService) { 

    super(cs);
  }

  @Input()
  backup?: Backup;

  isSelecting: boolean = false;

  @ViewChild('backupInfo')
  backupInfo?: ElementRef;

  isRestoring: boolean = false;
  progress: number = 0;


  ngOnInit(): void {
  }

  delete() {
    this.isSelecting = false;

    if (this.backup) {
      let backup = this.backup;
      this.cds.confirm("Delete Backup", "Do you want to delete this backup?", "Yes", "No").then( flag => {
        if (flag) {
          this.backupService.deleteLocalBackup(backup.id);
          this.activeModal.close();
        }
      });
    }
  }

  async export() {
    this.isSelecting = false;

    if (this.backup) {
      this.backupService.export(this.backup);
      this.activeModal.close();
    }
  }

  async prepareRestore() {
    this.isSelecting = true;
    this.cd.detectChanges();
  }

  async executeRestore() {

    let restoreSettings = true;
    let restoreUnc = true;

    if (this.backupInfo) {
      let info = <BackupInfoComponent>(<any>this.backupInfo);

      restoreSettings = info.restoreSettings;
      restoreUnc = info.restoreUnc;
    }

    this.isRestoring = true;
    this.progress = 0;
    this.cd.detectChanges();

    let progress: ProgressObserver = {
      progress: (step: number, total: number): void => {
        this.progress = Math.round(100 * step / total);
        this.cd.detectChanges();
      }
    }
    if (this.backup && this.currentInstrument) {
      if (restoreSettings && this.backup.globalSettings) {
        await this.backupService.restoreGlobalSettings(this.currentInstrument, this.backup, progress);
      }

      if (restoreUnc && this.backup.unc) {
        await this.backupService.restoreUNC(this.currentInstrument, this.backup, progress);
      }

      this.isSelecting = false;

      this.activeModal.close();
    }
  }

  close() {
    this.activeModal.close();
  }
}
