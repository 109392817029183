// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  display: table;
  border-collapse: separate;
  border-spacing: 0 5px;
  max-width: 100rem;
}

.setting {
  width: 45em;
}

.form-switch, .form-check {
  width: 2em !important;
}

.form-row {
  display: table-row;
  width: 60em;
}

.form-row > label {
  display: table-cell;
  margin-left: 8px;
  width: 15em;
}

.form-row br {
  display: table-row;
}

.form-row .setting {
  display: table-cell;
  padding-right: 30px;
}

div.divider {
  height: 15px;
}

.form-row select {
  max-width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/settings/settings.component.scss"],"names":[],"mappings":"AAKA;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;EACA,iBAAA;AAJF;;AAOA;EACE,WAAA;AAJF;;AAOA;EACE,qBAAA;AAJF;;AAOA;EACE,kBAAA;EACA,WAAA;AAJF;;AAOA;EACE,mBAAA;EACA,gBAAA;EACA,WAAA;AAJF;;AAOA;EACE,kBAAA;AAJF;;AAOA;EACE,mBAAA;EACA,mBAAA;AAJF;;AAOA;EACE,YAAA;AAJF;;AAOA;EACE,gBAAA;AAJF","sourcesContent":["\n#globalSettings {\n\n}\n\nsection {\n  display: table;\n  border-collapse:separate;\n  border-spacing:0 5px;\n  max-width: 100rem;\n}\n\n.setting {\n  width: 45em;\n}\n\n.form-switch, .form-check {\n  width: 2em ! important;\n}\n\n.form-row {\n  display: table-row;\n  width: 60em;\n}\n\n.form-row > label {\n  display: table-cell;\n  margin-left: 8px;\n  width: 15em;\n}\n\n.form-row br {\n  display: table-row;\n}\n\n.form-row .setting {\n  display: table-cell;\n  padding-right: 30px;\n}\n\ndiv.divider {\n  height: 15px;\n}\n\n.form-row select {\n  max-width: 20rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
