// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featureSelector {
  width: 45vw;
  height: 40vh;
  border-radius: 10px;
  top: 5vh;
  left: 30vw;
  padding: 0px;
  position: relative;
}

.featureSelector > div {
  padding: 15px;
}

.header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #303030;
}

.content {
  flex-grow: 1;
  background-color: gray;
  color: #303030;
}

.footer {
  background-color: grey;
  text-align: right;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.footer button {
  margin-left: 15px;
  width: 6em;
}

.setting {
  width: 45em;
}

.form-switch, .form-check {
  width: 2em !important;
}

.form-row {
  display: table-row;
  width: 60em;
}

.form-row > label {
  display: table-cell;
  margin-left: 8px;
  width: 25em;
}

.form-row br {
  display: table-row;
}

.form-row .setting {
  display: table-cell;
  padding-right: 30px;
}

div.divider {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 300;
}

.form-row select {
  max-width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/feature-selector/feature-selector.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EAEA,mBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,6BAAA;EACA,4BAAA;EACA,yBAAA;AAFF;;AAKA;EACE,YAAA;EACA,sBAAA;EACA,cAAA;AAFF;;AAKA;EACE,sBAAA;EACA,iBAAA;EAEA,gCAAA;EACA,+BAAA;AAHF;;AAMA;EACE,iBAAA;EACA,UAAA;AAHF;;AAMA;EACE,WAAA;AAHF;;AAMA;EACE,qBAAA;AAHF;;AAMA;EACE,kBAAA;EACA,WAAA;AAHF;;AAMA;EACE,mBAAA;EACA,gBAAA;EACA,WAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;;AAMA;EACE,mBAAA;EACA,mBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,kBAAA;EAEA,eAAA;EACA,gBAAA;AAJF;;AAOA;EACE,gBAAA;AAJF","sourcesContent":["\n\n.featureSelector {\n  width: 45vw;\n  height: 40vh;\n\n  border-radius: 10px;\n  top: 5vh;\n  left: 30vw;\n  padding: 0px;\n  position: relative;\n}\n\n.featureSelector > div {\n  padding: 15px;\n}\n\n.header {\n  border-top-right-radius: 10px;\n  border-top-left-radius: 10px;\n  background-color: #303030;\n}\n\n.content {\n  flex-grow: 1;\n  background-color: gray;\n  color: #303030;\n}\n\n.footer {\n  background-color: grey;\n  text-align: right;\n\n  border-bottom-right-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n\n.footer button {\n  margin-left: 15px;\n  width: 6em\n}\n\n.setting {\n  width: 45em;\n}\n\n.form-switch, .form-check {\n  width: 2em ! important;\n}\n\n.form-row {\n  display: table-row;\n  width: 60em;\n}\n\n.form-row > label {\n  display: table-cell;\n  margin-left: 8px;\n  width: 25em;\n}\n\n.form-row br {\n  display: table-row;\n}\n\n.form-row .setting {\n  display: table-cell;\n  padding-right: 30px;\n}\n\ndiv.divider {\n  margin-top: 10px;\n  margin-bottom: 5px;\n\n  font-size: 20px;\n  font-weight: 300;\n}\n\n.form-row select {\n  max-width: 20rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
