import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';

import { InstrumentConnection } from 'src/app/model/instrument-connection';
import { GlobalSettings, EmeoGlobalSettings } from 'src/app/model/global-settings';
import { MidiCCTypes, CCType } from 'src/app/model/midi-cctypes';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { rootViews } from 'src/app/helpers/log-config';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

const log = rootViews.getChildCategory("settings");

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends InstrumentComponentBase implements OnInit {

  @Input()
  type: string = 'general';

  ccTypes: CCType[] = MidiCCTypes;
  
  settingsForm: FormGroup;

  constructor(
    protected cs: ConnectionService,
    public fs: FeatureSupportService,
    public cds: ConfirmationDialogService,
    private cd: ChangeDetectorRef) {

    super(cs);

    let settings = new GlobalSettings();
    this.settings = settings;

    this.settingsForm = new FormGroup({
      midiChannel: new FormControl<number>(settings.midiChannel, [ Validators.min(0), Validators.max(5), Validators.required ]),

      keyShift: new FormControl<number>(settings.keyShift),
      octaveShift: new FormControl(settings.octaveShift),

      breathResistanceLevel: new FormControl<number>(settings.breathResistanceLevel),
      pressureResistanceOffset: new FormControl<number>(settings.pressureResistanceOffset, [ Validators.min(0), Validators.max(127) ]),
      
      breathAftertouchEnabled: new FormControl<boolean>(settings.breathAftertouchEnabled),
      breathExpressionEnabled: new FormControl<boolean>(settings.breathExpressionEnabled),
      breathVolumeEnabled: new FormControl<boolean>(settings.breathVolumeEnabled),

      goSleepMultiplier: new FormControl<number>(settings.goSleepMultiplier),
      
      tonguingDetectionEnabled: new FormControl<boolean>(settings.tonguingDetectionEnabled),
      tonguingDetectionEnabledGroup: new FormGroup({
        tonguingCCNumber: new FormControl<number>(settings.tonguingCCNumber, [ Validators.min(0), Validators.max(127) ]),
        tonguingSensitivity: new FormControl<number>(settings.tonguingSensitivity, [ Validators.min(0), Validators.max(5) ]),
      }),

			vocalDetectionEnabled: new FormControl<boolean>(settings.vocalDetectionEnabled),
      vocalDetectionEnabledGroup: new FormGroup({
			  vocalCCNumber: new FormControl<number>(settings.vocalCCNumber, [ Validators.min(0), Validators.max(127) ]),
      }),

			overblowDetectionEnabled: new FormControl<boolean>(settings.overblowDetectionEnabled),
      overblowDetectionEnabledGroup: new FormGroup({
        overblowCCNumber: new FormControl<number>(settings.overblowCCNumber, [ Validators.min(0), Validators.max(127) ]),
        overblowMidiChannel: new FormControl<number>(settings.overblowMidiChannel, [ Validators.min(0), Validators.max(15) ]),
        overblowPressure: new FormControl<number>(settings.overblowPressure),
      }),

			playWithoutBlowLevel: new FormControl<number>(settings.playWithoutBlowLevel, [ Validators.min(0), Validators.max(127) ]),

      invalidNoteFeedback: new FormControl<number>(settings.invalidNoteFeedback, [ Validators.min(0), Validators.max(2) ]),
      
			glitchReducerEnabled: new FormControl<boolean>(settings.glitchReducerEnabled),
			glitchLearnEnabled: new FormControl<boolean>(settings.glitchLearnEnabled),

      advancedModeEnabled: new FormControl<boolean>(settings.advancedModeEnabled),

    });

    this.setupControlEvents(this.settingsForm);
  }

  loadSettingsFromEmeo() {
    this.currentInstrument?.loadGlobalSettings();
  }

  populatingForm: boolean = false;

  populateForm(formGroup: FormGroup) {

    let self = this;

    let currentFlag = this.populatingForm;

    this.populatingForm = true;

    for (var key in formGroup.controls) {
      let control = formGroup.controls[ key ];

      if (control instanceof FormGroup) {
        this.populateForm(<FormGroup>control);
      }
      else if (control instanceof FormControl) {
        let fc = <FormControl>control;

        let value = (<any>this.settings)[ key ];

        if (key.endsWith("Enabled")) {
          control.setValue(value ? true : false);         
        }
        else {
          control.setValue(String(value));
        }

        // is this a group toggle?
        if (this.settingsForm.contains(key + "Group")) {

          let formGroup = this.settingsForm.get(key + "Group");

          if (value == 1)
            formGroup?.enable();
          else
            formGroup?.disable();
        }

      }
    }

    if (!currentFlag) {
      this.cd.detectChanges();
    }

    this.populatingForm = currentFlag;
  }

  setupControlEvents(formGroup: FormGroup) {

    let self = this;

    Object.keys(formGroup.controls).forEach(key => {
      let control = formGroup.get(key);

      if (control instanceof FormControl) {
        let formControl = <FormControl>control;
        formControl.valueChanges.subscribe( (value:any) => {
          self.valueChanged(key, formControl, value);
        });

        // is this a group toggle?
        if (this.settingsForm.contains(key + "Group")) {

          let formGroup = this.settingsForm.get(key + "Group");

          formControl.valueChanges.subscribe( (value:any) => {
            if (value)
              formGroup?.enable();
            else
              formGroup?.disable();
          });
        }
      }
      else if (control instanceof FormGroup) {
        this.setupControlEvents(<FormGroup>control);
      }
    });
  }

  clearGlitchLearnings() {
    this.currentInstrument?.clearGlitchTable();
  }

  async valueChanged(settingName: string, control: FormControl, newValue: any) {
    
    if (this.populatingForm) {
      return;
    }

    log.debug("Setting " + settingName + " is now " + newValue);

    let settingDefinition = EmeoGlobalSettings.getSetting(settingName);

    if (settingDefinition) {

      let code = settingDefinition.code;
      let value: number = 0;

      if (newValue == true) {
        value = 1;
      }
      else  if (newValue == false) {
        value = 0;
      }
      else
        value = Number.parseInt(newValue);

      log.debug(`Setting ${settingName} to ${value}`);

      let message = [
        0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, // Header
        0x12, // Set data
        0x40, 0x00, // Global settings
        code, value,
        0xf7
      ];

      await this.currentInstrument?.sendMessage(message);
    }
  }

  @Input()
  settings: GlobalSettings;

  ngOnInit(): void {
  }

  private _settingsListener?: (data: Uint8Array, receivedTime: number | undefined) => void;

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    log.info("EMEO Connection changed");

    if (before) {
      if (this._settingsListener) {
        before.off("sysex", this._settingsListener);
        this._settingsListener = undefined;
      }
    }
    if (current) {
      let comp = this;

      this._settingsListener =  (data: Uint8Array, receivedTime: number | undefined) => {

          var validHeader = false;

          if (data.length > 6) {
            validHeader = true;
            const header =  [ 0xf0, 0x00, 0x21, 0x43, 0x00, 0x00, 0x12, 0x40, 0x00, 0x00 ];

            for (var i = 0; i < header.length; i++) {
              if (data[ i ] !== header[ i ]) {
                validHeader = false;
              }
            }
          }

          if (validHeader) {
            if (data.length >= 32) {
              comp.currentInstrument?.parseGlobalSettings(data, comp.settings);
              comp.populateForm(this.settingsForm);

             log.debug(JSON.stringify(this.settings, undefined, 3));
            }
          }
        }
      
      current.on("sysex", this._settingsListener);

      this.loadSettingsFromEmeo();
    }
  }

  get advancedMode() : boolean {
    return this.currentInstrument?.deviceState.advancedMode || false
  }

  async enableAdvancedMode() {
    let enabled = await this.cds.confirm(
                          "Advanced Mode", 
                          "Advanced mode allows modifications of the instrument settings that could render it unplayable. Do you really want to enable the advanced mode?", 
                          "Yes", "No", "lg");

    if (enabled) {
      await this.currentInstrument?.enableAdvancedMode();
      await this.currentInstrument?.fetchDeviceState();
    }
  }

  async disableAdvancedMode() {
    await this.currentInstrument?.disableAdvancedMode();
    await this.currentInstrument?.fetchDeviceState();
  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.currentInstrument }, ...features);
  }
}
