// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  display: table;
}

.form-row {
  display: table-row;
}

label,
input,
select {
  display: table-cell;
}

label {
  height: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/instrument-config/instrument-config.component.scss"],"names":[],"mappings":"AACA;EACI,cAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;;;EAGI,mBAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ","sourcesContent":["\nform {\n    display: table;\n}\n\n.form-row {\n    display: table-row;\n}\n\nlabel,\ninput,\nselect {\n    display: table-cell;\n} \n\nlabel {\n    height: 3rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
