
import { HardwareTypes } from "./device-info";
import { EMEO_SAX_V1_INFO } from "./instruments/emeo-sax-v1";
import { InstrumentInfo } from "./unc-types";



export function keySignature(keyState: string, instrument: InstrumentInfo = EMEO_SAX_V1_INFO): string {
  return decodeKeys(keyState, instrument, true).join(':');
}

export function isDefaultKey(keyState: string, instrument: InstrumentInfo = EMEO_SAX_V1_INFO) : boolean {
  return Object.keys(instrument.defaultFingerings).contains(keySignature(keyState));
}


export function decodeKeys(keyState : string, instrument: InstrumentInfo = EMEO_SAX_V1_INFO, sort: boolean = false) : string[] {

  let result: string[] = [];
  let others: string[] = [];

  while (keyState.length > 22) {
    keyState = keyState.substring(1);
  }
  
  for(var key = 0; key < keyState.length; key++) {

    let map = instrument.keyMap[key];

    if (map) {
      if (keyState[key] == (map.active ? '1' : '0')) {
        result.push(map.name);

        if (map.connectedKeys) {
          others.push( ...map.connectedKeys || [] );
        }

        if (map.sameKeys) {
          result.push( ...map.sameKeys || [] );
        }
      }
    }
  }

  if (instrument.hardware.type === HardwareTypes.Sax) {
    if ((keyState[19] == '1') && (keyState[15] == '0')) {
      result.push("AddtlHighF");
      others.push('HighF', 'B' );
    }
  }

  result = result.filter( (value) => { return !others.contains(value); } );

  if (sort) {
    result = result.sort( (a,b) => { return (a === b ? 0 : (a < b ? -1 : 1)) } );
  }

  return result;
}


export enum UNCRecordType {

    NOTE = 0b00,

    MENU = 0b01,

    NOTE_CONFIG = 0b10,

    NOTE_OVERBLOW = 0b11
}

export enum UNCType {

  INVALID = 0,

  USER = 1,

  FACTORY = 2
}

export class UNCRecord {

  attackDelay: number = 0;
  resistance: number = 0;

  bank: number = 0;
  
  pitchShift: number = 0;

  parameterType: number = 0;

  keyState: string = '';

  type: UNCType = UNCType.INVALID;
  
  recordType: UNCRecordType = UNCRecordType.NOTE;

  getPressedKeys() : string[] {
    return decodeKeys(this.keyState);
  }
}


export interface UNCRecords {
  [ address: string ]: UNCRecord;
}

export class UNCDump {

  constructor(count: number | undefined) {
    this.maxRecords = count || 0;
  }

  maxRecords: number = 0;
  recordCount: number = 0;
  records: UNCRecords = <UNCRecords>{};
}
export class UNCSettings {
  
  name: string = '';

  uncDump: UNCDump = <UNCDump>{};
  ksrDump: UNCDump = <UNCDump>{};
}
