// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-icons i {
  padding: 0 0.25rem;
}

tbody tr {
  cursor: pointer;
}

td {
  color: white;
}

td.name {
  width: 12em;
  max-width: 12em;
  overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/views/backup-list/backup-list.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,eAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".action-icons i {\n    padding: 0 0.25rem;\n}\n\ntbody tr {\n    cursor:pointer;\n}\n\ntd {\n    color: white;\n}\n\ntd.name {\n    width: 12em;\n    max-width: 12em;\n    overflow: clip;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
