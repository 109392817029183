// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unc-editor {
  max-width: 20rem;
  min-width: 15rem;
  max-height: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/user-defined-keys/note-config/note-config.component.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ","sourcesContent":["\n.unc-editor {\n    max-width: 20rem;\n    min-width: 15rem;\n    max-height: 10rem;\n}   \n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
