// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waiting span {
  vertical-align: middle;
}

.waiting {
  min-width: 30em;
}

.instrument-view {
  height: 80%;
  min-height: 35rem;
}

.instrument * {
  height: 35rem;
  display: block;
}

div.info {
  margin-left: 200px;
  display: table;
}

div.info > div {
  display: table-row;
  margin-bottom: 5em;
  height: 2.5em;
  min-height: 2.5em;
}

div.info div label,
div.info div span {
  display: table-cell;
  padding-right: 20px;
}

div.instrument {
  float: left;
  position: absolute;
}

ul {
  list-style: none;
}

ul li span img {
  max-height: 40px;
}

.waiting,
.rescueFlasher {
  padding: 1rem;
  max-width: 80%;
}

.flash {
  display: block;
  visibility: hidden;
}

.consent {
  visibility: visible;
}

button.connect {
  margin-top: 0em;
  margin-bottom: 1.5em;
}

div.keystates {
  margin-bottom: 0;
  display: table;
}

div.keystate {
  height: default !important;
  min-height: 0.5em !important;
  margin-bottom: 0;
  display: table-row;
}

span.selected {
  background: green;
}

span.index {
  text-align: right;
  width: 2em;
  display: table-cell;
}

span.key {
  display: table-cell;
}`, "",{"version":3,"sources":["webpack://./src/app/views/sensors/sensors.component.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,WAAA;EACA,iBAAA;AAAF;;AAGA;EACE,aAAA;EACA,cAAA;AAAF;;AAGA;EACE,kBAAA;EACA,cAAA;AAAF;;AAGA;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;AAAF;;AAGA;;EAEE,mBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,WAAA;EACA,kBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAIA;EACE,gBAAA;AADF;;AAIA;;EAEE,aAAA;EACA,cAAA;AADF;;AAIA;EACE,cAAA;EACA,kBAAA;AADF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,eAAA;EACA,oBAAA;AAFF;;AAMA;EACE,gBAAA;EAEA,cAAA;AAJF;;AAOA;EACE,0BAAA;EACA,4BAAA;EACA,gBAAA;EAEA,kBAAA;AALF;;AAQA;EACE,iBAAA;AALF;;AAQA;EACE,iBAAA;EACA,UAAA;EAEA,mBAAA;AANF;;AASA;EACE,mBAAA;AANF","sourcesContent":["\n.waiting span {\n  vertical-align: middle;\n}\n\n.waiting {\n  min-width: 30em;\n}\n\n.instrument-view {\n  height: 80%;\n  min-height: 35rem;\n}\n\n.instrument * {\n  height: 35rem;\n  display: block;\n}\n\ndiv.info {\n  margin-left: 200px;\n  display: table;\n}\n\ndiv.info > div {\n  display: table-row;\n  margin-bottom: 5em;\n  height: 2.5em;\n  min-height: 2.5em;\n}\n\ndiv.info div label,\ndiv.info div span {\n  display: table-cell;\n  padding-right: 20px ;\n}\n\ndiv.instrument {\n  float: left;\n  position: absolute;\n}\n\nul {\n  list-style: none;\n}\n\n\nul li span img {\n  max-height: 40px;\n}\n\n.waiting,\n.rescueFlasher {\n  padding: 1rem;\n  max-width: 80%;\n}\n\n.flash {\n  display: block;\n  visibility: hidden;\n}\n\n\n.consent {\n  visibility: visible;\n}\n\nbutton.connect {\n  margin-top: 0em;\n  margin-bottom: 1.5em;\n  \n}\n\ndiv.keystates {\n  margin-bottom: 0;\n\n  display: table;\n}\n\ndiv.keystate {\n  height: default ! important;\n  min-height: 0.5em ! important;\n  margin-bottom: 0;\n\n  display: table-row;\n}\n\nspan.selected { \n  background: green;\n}\n\nspan.index {\n  text-align: right;\n  width: 2em;\n\n  display: table-cell;\n}\n\nspan.key {\n  display: table-cell;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
