
export const EMEO_LOGO: string = 
  '<svg _ngcontent-pbd-c57="" preserveAspectRatio="xMidYMid meet"  \
    data-bbox="24.07 28.914 619.321 207.196" viewBox="24.07 28.914 619.321 207.196" \
    xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" \
    aria-labelledby="svgcid-fg4uy0u0tfuo"> \
    <title _ngcontent-pbd-c57="" id="svgcid-fg4uy0u0tfuo"></title> \
    <g _ngcontent-pbd-c57=""> \
      <path _ngcontent-pbd-c57="" fill="#ffd600" \
        d="M273.54 83.77H332a2.69 2.69 0 012.69 2.69v6.75A2.68 2.68 0 01332 95.9h-47.79v30.28h41.46a2.68 2.68 0 012.69 2.69v6.74a2.68 2.68 0 01-2.69 2.69h-41.46v33.14h48.07a2.68 2.68 0 012.69 2.69v6.74a2.68 2.68 0 01-2.69 2.69h-58.74a2.68 2.68 0 01-2.69-2.69V86.46a2.69 2.69 0 012.69-2.69z" \
        data-color="1"></path> \
      <path _ngcontent-pbd-c57="" fill="#ffd600" \
        d="M454.43 83.77H443.8a2.67 2.67 0 00-2.48 1.65l-32.47 77.4-32.34-77.39a2.67 2.67 0 00-2.51-1.66h-10.54a2.69 2.69 0 00-2.69 2.69v94.41a2.68 2.68 0 002.69 2.69h8a2.69 2.69 0 002.69-2.69v-41.41l-.44-27.72 29.63 70.18a2.7 2.7 0 002.48 1.64H412a2.7 2.7 0 002.48-1.64l29.76-70.51-.44 28.35v41.11a2.68 2.68 0 002.69 2.69h8a2.68 2.68 0 002.69-2.69V86.46a2.69 2.69 0 00-2.75-2.69z" \
        data-color="1"></path> \
      <path _ngcontent-pbd-c57="" fill="#ffd600" \
        d="M544.35 171.44h-48.08V138.3h41.46a2.68 2.68 0 002.69-2.69v-6.74a2.68 2.68 0 00-2.69-2.69h-41.46V95.9H544a2.68 2.68 0 002.69-2.69v-6.75a2.69 2.69 0 00-2.69-2.69h-58.4a2.7 2.7 0 00-2.69 2.69v94.41a2.69 2.69 0 002.69 2.69h58.75a2.69 2.69 0 002.69-2.69v-6.74a2.69 2.69 0 00-2.69-2.69z" \
        data-color="1"></path> \
      <path _ngcontent-pbd-c57="" fill="#ffd600" \
        d="M638.39 104.12a37.38 37.38 0 00-13.87-16 38.19 38.19 0 00-20.66-5.66 36.63 36.63 0 00-34.64 22.22 57.23 57.23 0 00-4.82 24v9.72c0 14 3.65 25.33 10.84 33.69s17 12.77 28.75 12.77a38 38 0 0020.77-5.74 37.2 37.2 0 0013.87-16.32 57.5 57.5 0 004.75-24v-11.33a56.57 56.57 0 00-4.99-23.35zM630 138.53c0 10.87-2.36 19.4-7 25.35s-10.78 8.66-19 8.66-14.34-2.88-19-8.8-7.2-14.43-7.2-25v-9.85c0-10.9 2.43-19.45 7.22-25.39s10.89-8.69 18.88-8.69c8.16 0 14.39 2.83 19 8.65s7.12 14.35 7.12 25z" \
        data-color="1"></path> \
      <path _ngcontent-pbd-c57="" fill="#ffd600" \
        d="M128.57 28.92C71.65 28.34 25.82 72.5 24.14 129.42a102.9 102.9 0 00101 106.69h2.64c55.47 0 101.52-44.57 103.22-100.55a104 104 0 00-28.7-74.91 102.59 102.59 0 00-73.73-31.73zm-76 52.79a54 54 0 0135.73 44.48H57.73c-5 0-5.94 2.35-5.95 6.4 0 2.07.2 3.67 1.22 4.79s2.9 1.43 4.63 1.43h30.68l-.12.95a39.9 39.9 0 01-1 5.42c-5.2 18.65-16.83 31.5-34.58 38.17l-.32.11-.11-.15A90.62 90.62 0 0137.31 143a88.3 88.3 0 018.23-49.27c1.69-3.49 3.75-6.92 5.74-10.24l1-1.68zm136.29-15.62q-30 29.66-59.94 59.3a15.62 15.62 0 01-1.32 1.2 14.33 14.33 0 01-1.28-1.17l-60-59.3-.3-.31a89.66 89.66 0 0161.51-24.16h.08a90 90 0 0161.52 24.13c-.06.1-.13.22-.27.31zm-89.35 50.64l2.65 2.59c5.46 5.33 11.11 10.85 16.47 16.53a27.62 27.62 0 016 9.49l.13.24c.06.1.12.22.18.34.36.68 1.09 2.1 2.7 2.1.94 0 2.22-.46 3-2.67a29 29 0 017.24-11c4.79-4.67 9.55-9.4 14.3-14.12l3.52-3.49c-6.16 34.8 6.84 60.78 38.69 77.29-15.13 17.94-39.2 28.93-64.83 29.5a89 89 0 01-68.79-29.43c31.99-16.56 45.02-42.57 38.74-77.37zm76.32 22.11h21.55c2 0 3.62-.23 4.71-1.33s1.34-2.71 1.33-4.92c0-4.83-1.51-6.38-6.19-6.38H167a51.17 51.17 0 014.23-15.55 53.94 53.94 0 0125.28-26.38c1.16-.59 2.38-1.07 3.68-1.58.87-.33 1.74-.67 2.7-1.09l.16.25.13.2a92.36 92.36 0 0111.52 24.68 90.29 90.29 0 01-1.18 55.33A95.13 95.13 0 01203 183.28a2 2 0 01-.15.22l-.22-.07c-20.3-7.94-31.91-22.05-35.51-43.16-.08-.46-.14-.93-.21-1.43z" \
        data-color="1"></path> \
    </g> \
  </svg>'