// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#globalSettings {
  background-color: white;
}

section {
  display: table;
  border-collapse: separate;
  border-spacing: 0 5px;
}

.form-row {
  display: table-row;
  width: 490px;
}

.form-row label {
  display: table-cell;
  margin-left: 8px;
  min-width: 160px;
}

.form-row .setting {
  display: table-cell;
  padding-right: 30px;
}

div.divider {
  height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/backup/backup.component.scss"],"names":[],"mappings":"AACA;EACE,uBAAA;AAAF;;AAGA;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,YAAA;AAAF;;AAGA;EACE,mBAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,mBAAA;AAAF;;AAIA;EACE,YAAA;AADF","sourcesContent":["\n#globalSettings {\n  background-color: white;\n}\n\nsection {\n  display: table;\n  border-collapse:separate;\n  border-spacing:0 5px;\n}\n\n.form-row {\n  display: table-row;\n  width: 490px;\n}\n\n.form-row label {\n  display: table-cell;\n  margin-left: 8px;\n  min-width: 160px;\n}\n\n.form-row .setting {\n  display: table-cell;\n  padding-right: 30px;\n}\n\n\ndiv.divider {\n  height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
