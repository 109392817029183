import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Recording } from 'src/app/model/recording';
import { Score } from 'src/app/model/score';
import { RecordingsService } from 'src/app/services/recordings.service';

@Component({
  selector: 'app-tape-dialog',
  templateUrl: './tape-dialog.component.html',
  styleUrls: ['./tape-dialog.component.scss']
})
export class TapeDialogComponent implements OnInit {

  constructor(
    protected rs: RecordingsService,
    protected cd: ChangeDetectorRef
  ) {
    this.rs.on('recordings-changed', this.recordingsChanged.bind(this));
  }

  ngOnInit(): void {
    
  }

  public recordingsChanged(recordings: Recording[]) {

    if (this._score && this._score.id) {
      let id = this._score.id;
      this._score.recordings = recordings.filter( (recording) => (recording.scoreId == id));
      this.cd.detectChanges();
    }
  }

  public loadRecording(recording: Recording) {
    this.recording = recording;
  }

  protected _recording : Recording | undefined = undefined;
  
  public get recording(): Recording | undefined {
    return this._recording;
  }

  public set recording(value : Recording | undefined) {
    
    if (this._recording === value) return;

    this._recording = value;
    this.zoomReset();
  }


  private _score: Score | undefined;

  public get score(): Score | undefined {
    return this._score;
  }
  public set score(value: Score | undefined) {
    this._score = value;
  }

  get recordings() : Recording[] {
    return this._score?.recordings || [];
  }

  scale: number = 1;

  zoomReset() {
    this.scale = 1;
  }

  zoomIn() {
    this.scale = this.scale * 0.5;
  }

  zoomOut() {
    this.scale = this.scale * 2;
  }

  transpose : number = -3;

  transposeUp() {
    this.transpose = this.transpose + 1;
  }

  transposeDown() {
    this.transpose = this.transpose - 1;
  }

  transposeVoice : number = -3;

  transposeVoiceUp() {
    this.transposeVoice = this.transposeVoice + 1;
  }

  transposeVoiceDown() {
    this.transposeVoice = this.transposeVoice - 1;
  }

  deleteRecording(id: string) {
    this.rs.deleteRecording(id);
  }

  downloadRecording(recording: Recording) {

    const suffix = new Date().toISOString();
    const filename = "recording";
    const extension = "json";

    let data = recording.toJSON();

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', `${filename}-${suffix}.${extension}`);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }  

}
