import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Backup } from '../model/backup';
import { IProgressWithInfo, PreparationCallback, ReportComponent } from '../views/report/report.component';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    public ms: NgbModal) { }

  get isFlasherOpen() : boolean {
    return (this._modalRef != undefined);
  }

  private _modalRef? : NgbModalRef = undefined;

  async openReportDialogWithBackup(backup: Backup) : Promise<ReportComponent> {
    return this.openDialog(backup, undefined);
  }

  async openReportDialogAndPrepareData(prepareCallback: PreparationCallback) : Promise<ReportComponent> {
    return this.openDialog(undefined, prepareCallback);
  }

  private async openDialog(backup: Backup | undefined, prepareCallback: PreparationCallback | undefined) : Promise<ReportComponent> {

    if (!this._modalRef) {

      this._modalRef = this.ms.open(ReportComponent, { centered: true, size: 'md', backdrop  : 'static', container: "#fullscreenContainer"  });
      let instance = <ReportComponent>this._modalRef.componentInstance;

      instance.closeCallback = this.closeReportDialog.bind(this);

      if (prepareCallback) {
        instance.prepareData(prepareCallback);
      }
      else {
        instance.backup = backup;
      }      

      this._modalRef.result.then( 
        () => { 
          this._modalRef = undefined; 
        }, 
        () => { 
          this._modalRef = undefined; 
        });

      return instance;
    }

    throw Error("Report-Modal is already open.");
  }  

  async closeReportDialog() {
    if (this._modalRef) {
      this._modalRef.close();
      this._modalRef = undefined;
    }
  }
}
