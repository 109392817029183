import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { rootViews } from 'src/app/helpers/log-config';
import { MidiNoteConfig, MIDI_NOTES } from 'src/app/helpers/notes';
import { InstrumentConnection } from 'src/app/model/instrument-connection';
import { UNCKeyMapping } from 'src/app/model/unckey-mapping';
import { UNCRecord, UNCSettings } from 'src/app/model/uncsettings';
import { ConnectionService } from 'src/app/services/connection.service';
import { EmeoSaxophoneComponentV1 } from '../../emeo-saxophone-v1/emeo-saxophone-v1.component';

const log = rootViews.getChildCategory("keyPicker");

@Component({
  selector: 'app-key-picker',
  templateUrl: './key-picker.component.html',
  styleUrls: ['./key-picker.component.scss']
})
export class KeyPickerComponent extends InstrumentComponentBase implements OnInit, OnDestroy {

  @ViewChild('instrumentView')
  instrumentView!: EmeoSaxophoneComponentV1;

  private _unc?: UNCKeyMapping[] = undefined;

  @Input()
  get unc(): UNCKeyMapping[] | undefined {
    return this._unc;
  }

  set unc(mappings: UNCKeyMapping[] | undefined) {
    this._unc = mappings;
  }
  
  constructor(
    protected cs: ConnectionService,
    protected modalService: NgbModal,
    protected cd: ChangeDetectorRef) { 

    super(cs);
  }

  getNote(code: number | undefined) : MidiNoteConfig | undefined {
    if (code) {
      let midiNote = MIDI_NOTES.find( note => (note.code == code));
      return midiNote;
    }

    return undefined;
  }

  get NOTES(): MidiNoteConfig[] {
    return MIDI_NOTES;
  }
    
  protected _trackUNCPresses: boolean = true;

  ngOnInit(): void {
    if (this.currentInstrument) {
      this.enableListeners(this.currentInstrument);
    }
  }

  async ngOnDestroy(): Promise<void> {
    await super.ngOnDestroy();
  }

  selectRecord(address : number) {
    if (this.unc) {
      let record = this.unc[ address ];
      this.instrumentView.showKeys(record.key);

      // this.currentKeyConfig = record;
      // this.cd.detectChanges();
    }

  }

  accept() {

  }

  dismiss() {
    this.modalService.dismissAll();
  }

  async enableListeners(instrument: InstrumentConnection) {
    await this.setupUNCPresses(this._trackUNCPresses, instrument);    
  }

  async disableListeners(instrument: InstrumentConnection) {
    await this.setupUNCPresses(false, instrument);
  }

  private _uncListener?: (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => void;

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {
  
    if (before) {
      await this.disableListeners(before);
    } 

    if (current) {
      await this.enableListeners(current);
    }
  }

  private _isUNCActive: boolean = false;
  
  private async setupUNCPresses(flag: boolean, instrument: InstrumentConnection) {
  
    if (!flag) {
      instrument.stopConditionalUNCMessages();
      // instrument.stopUNCMessages();
  
      if (this._uncListener) {
        instrument.off('unc', this._uncListener);
        this._uncListener = undefined;
      }
    }
    else {
      let comp = this;

      this._uncListener = (data: UNCRecord, validKey: boolean, receivedTime: number | undefined) => {

        log.debug(JSON.stringify(data));
        log.debug("Is Valid: " + validKey);

        
        if (data.keyState) {
          log.debug("XX KEYS: " + data.keyState);
          // let keys = decodeKeys(data.keyState);
          // this.pressed = keys;
          this.cd.detectChanges();
        }
      }

      instrument.on("unc", this._uncListener);

      // instrument.startUNCMessages();
      instrument.startConditionalUNCMessages();
    }    

    this._isUNCActive = flag;
  }  

  selectedKeyState?: string = undefined;

  pressed(keyState: string) {
    this.selectedKeyState = keyState;
  }
}
