import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DeviceInfo, EMEO_SAX_V1, HardwareInfo, HardwareTypes } from 'src/app/model/device-info';
import { ConnectionState, InstrumentConnection, InstrumentConnectionMode, KeyThreshold, KeyThresholds } from 'src/app/model/instrument-connection';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { ConnectionService } from 'src/app/services/connection.service';
import { FlasherService, FlasherType } from 'src/app/views/flasher/flasher.service';
import { Feature, FeatureSupportService } from 'src/app/services/feature-support.service';
import { InstrumentConfigComponent } from '../instrument-config/instrument-config.component';
import { rootViews } from 'src/app/helpers/log-config';
import { UNCRecord, UNCSettings, UNCType } from 'src/app/model/uncsettings';
import { UNCDump } from 'src/app/model/uncsettings';
import { getMidiNoteForCode, MidiNoteConfig } from 'src/app/helpers/notes';
import { InstrumentViewComponentBaseComponent, UNCKeyInfo } from 'src/app/helpers/instrument-view-component-base.component';
import { BackupService } from 'src/app/services/backup.service';
import { findDeviceInfo } from 'src/app/model/instruments/device-definitions';
import { KeyMapInfo } from 'src/app/model/unc-types';
import { sleep } from 'src/app/helpers/helper';
import { EMEO_SAX_V1_INFO_EX } from 'src/app/model/instruments/emeo-sax-v1-ex';
import { FormControl, Validators } from '@angular/forms';

const log = rootViews.getChildCategory("instrument");

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss'],
})
export class SensorsComponent extends InstrumentComponentBase implements OnInit {

  public get midiNote() : MidiNoteConfig | undefined {
    let result = getMidiNoteForCode(this.uncPressed?.parameterType);

    return result;
  }

  @ViewChild('instrumentView')
  instrumentView! : InstrumentViewComponentBaseComponent;
  
  get instrumentType() : HardwareTypes {
    return this._hardwareInfo?.type || HardwareTypes.NONE;
  }

  private _deviceInfo?: DeviceInfo;

  private _hardwareInfo?: HardwareInfo;

  get hardwareInfo(): HardwareInfo | undefined {
    return this._hardwareInfo;
  }

  protected get currentKeyIndexes() : number[] {

    let keymap = this.instrumentView?.instrument?.keyMap;

    return keymap ? Object.keys(keymap).map( k => Number.parseInt(k) ).sort( (a, b) => a-b) : [];
  }

  private set hardwareInfo(info: HardwareInfo | undefined) {
    this._hardwareInfo = info;

    if (info) {
      this.cd?.detectChanges();
    }
  } 

  get deviceInfo(): DeviceInfo | undefined {
    return this._deviceInfo;
  }

  private set deviceInfo(info: DeviceInfo | undefined) {
    this._deviceInfo = info;

    if (info) {
      this.cd?.detectChanges();
    }
  } 

  protected threshold = new FormControl(127, [ Validators.max(255), Validators.min(0), Validators.required ]);

  protected hysteresis = new FormControl(20);

  constructor(
    public fs: FeatureSupportService,
    private flasherService: FlasherService,
    protected cs: ConnectionService,
    protected bs: BackupService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef) { 

    super(cs);
  }
    
  async ngOnInit() : Promise<void> {

    if (this.currentInstrument) {
      await this.fetchDeviceInfo(this.currentInstrument);
      await this.loadThresholds();
    }

    this.clearCurrentKey();
  }

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    this._uncTable = undefined;
    
    if (current) {
      await this.fetchDeviceInfo(current);
      await this.loadThresholds();
    }
    else {
      this.hardwareInfo = undefined;
      this._deviceInfo = undefined;
      this.thresholds = null;
    }
  }

  private _uncTable: UNCSettings | undefined;

  private async fetchDeviceInfo(connection: InstrumentConnection) : Promise<void> {
    this.deviceInfo = connection.deviceInfo;
    this.hardwareInfo = connection.deviceInfo.hardware;

    if (this.instrumentView) {
      let temp = this.instrumentView?.trackUNCPresses;
      this.instrumentView.trackUNCPresses = false;
      
      if (this.can(Feature.UNCEditor) && this._uncTable === undefined ) {
        log.debug("Loading UNC-Table from instrument")
        this._uncTable = await connection.loadUNCTable()
      }
      
      this.instrumentView.trackUNCPresses = temp;
    }
  }

  private _status : ConnectionState = ConnectionState.READY;

  public get status() : ConnectionState {
    return this._status;
  }

  public get isLoadingInstrumentInfo(): boolean {
    return this.status === ConnectionState.LOADING;
  }

  public get hasInstrumentInfo(): boolean {
    return this.status === ConnectionState.READY;
  }

  configureInstrument() {
    this.modalService.open(InstrumentConfigComponent, { size: 'md', backdrop: 'static', centered: true, container: "#fullscreenContainer"  });
  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.cs.emeoConnection }, ...features);
  }

  async onThresholdChange() {
    console.log(this.threshold.value);

    let instrument = this.currentInstrument

    if (instrument) {
      await instrument.sendKeyThreshold(this.currentKey, Number(this.threshold.value))
      this.currentThreshold.threshold = Number(this.threshold.value)
    }
  }

  pressedKeys: string[] = [];

  sensorStates: boolean[] = [];

  private searchKeys(table: UNCDump, sensors: string): undefined | [ string, UNCRecord ] {

    for(var key in table.records) {
      let record = table.records[key];

      if (record.keyState === sensors) {
        return [ key, record ];
      }
    }

    return undefined;
  };

  uncPressed: UNCRecord | null = null;

  onKeysPressed(event: UNCKeyInfo) {
    this.pressedKeys = event.keys;
    
    let keyState = event.sensors;
    while (keyState.length > 22) {
      keyState = keyState.substring(1);
    }

    this.sensorStates = keyState.split("").map( v => ( v == "1"));
    
    if (this._uncTable) {

      var table: UNCDump = this._uncTable.ksrDump;

      if (event.type === UNCType.USER) {
        table = this._uncTable.uncDump;
      }

      let setting = this.searchKeys(table, event.sensors);

      if (setting) {
        this.uncPressed = setting[1];
      }
    }
  }

  protected thresholds: KeyThresholds | null = null

  async loadThresholds() : Promise<void> {

    if (this.currentInstrument) {
      this.thresholds = await this.currentInstrument.loadKeyThresholds()
    }
  }

  private clearCurrentKey() {
    this.currentKey = -1;
    this.currentThreshold = { threshold: -1 };
    this.instrumentView.selected = []

    this.threshold.disable();
  }

  private setCurrentKey(name: string, key: number, active: boolean, threshold: KeyThreshold) {
    this.currentKey = key;
    this.currentKeyName = name;
    this.currentKeyIsActive = active

    this.currentThreshold = threshold;

    this.threshold.setValue(threshold.threshold);
    this.threshold.enable();
  }

  async onKeyClicked(key: string) {

    if (this.instrumentView.selected.contains(key)) {
      this.clearCurrentKey()      
      return;
    }

    // TODO: Need new instrument definition, but hardware would need to return that info.
    let instrument = findDeviceInfo(this._deviceInfo?.hardware || EMEO_SAX_V1) || EMEO_SAX_V1_INFO_EX;

    if (this.thresholds && instrument) {
      let keyInfo: KeyMapInfo = Object.values(instrument.keyMap).find((entry) => { return entry.name == key });
      let keyId = keyInfo?.sensor;
      let threshold = (keyId === undefined ? undefined : this.thresholds[ Number(keyId) ]);

      
      if (keyId != undefined && threshold != undefined) {
        this.setCurrentKey( keyInfo.name, keyId, keyInfo.active, threshold );
        this.instrumentView.selected = [ key ];
        return
      }
    }
    
    this.instrumentView.selected = []
  }

  protected scanning: boolean = false

  protected page: number = 0;

  protected progressMessage: string = "Hold the key and klick on Start. Hold the key until the scan finished."
  
  currentKey: number = -1
  currentKeyName: string = ""
  currentKeyIsActive: boolean = true
  currentThreshold: KeyThreshold = { threshold: -1 }

  async startScan() {

    const numScans = 5
    var thresholds: number[] = []

    for (var i = 0; i < numScans; i++) {
      
      var lastThreshold: number | undefined = undefined

      if (this.currentKeyIsActive) {
        lastThreshold = await this.scanKey(0, 255)
      }
      else {
        lastThreshold = await this.scanKey(255, 0)
      }

      if (lastThreshold) {
        thresholds.push(lastThreshold);
      }
    }

    if (thresholds.length > (numScans / 2)) {
      let foundThreshold = thresholds.reduce( (total, value, index, array) => { return total += (value / array.length) })
      log.info("Determined new threshold for key " + this.currentKey + " to be " + foundThreshold);
      this.currentThreshold.threshold = foundThreshold
      await this.currentInstrument?.sendKeyThreshold(this.currentKey, this.currentThreshold.threshold);
      this.threshold.patchValue(this.currentThreshold.threshold);
    }
  }

  async collectStats() {
    const numScans = 1
    var statistics: boolean[][] = []

    for (var i = 0; i < numScans; i++) {
      
      var lastStatistics: boolean[]

      if (this.currentKeyIsActive) {
        lastStatistics = await this.scanAndCollect(0, 255)
      }
      else {
        lastStatistics = await this.scanAndCollect(255, 0)
      }

      statistics.push(lastStatistics);
    }

    console.log(statistics)
  }

  private async scanKey(start: number, end: number) : Promise<number | undefined> {
    
    var matches = 0
    var foundThreshold = -1
    const matchThreshold = 15
    
    var stepping = matchThreshold

    for (var newThreshold = start; newThreshold != end; newThreshold += Math.sign(end - start) * stepping) {

      if (newThreshold < 0) break;
      if (newThreshold > 255) break;
      
      this.threshold.patchValue(newThreshold);
      this.instrumentView.pressed = []

      await this.currentInstrument?.sendKeyThreshold(this.currentKey, newThreshold);
      await sleep(90);

      if (this.instrumentView.pressed.contains(this.currentKeyName)) {
        matches++;
        stepping = 1
      }
      else {
        matches = 0
        stepping = matchThreshold
      }

      if (matches >= matchThreshold) {
        foundThreshold = newThreshold;
        break;
      }
    } 

    if (foundThreshold > 0) {
      log.info("Measured threshold for key " + this.currentKey + " is " + foundThreshold );
      return foundThreshold
    }
    else {
      log.info("Could not find new threshold for key " + this.currentKey + ", will reset to initial threshold.")
      await this.currentInstrument?.sendKeyThreshold(this.currentKey, this.currentThreshold.threshold);
      this.threshold.patchValue(this.currentThreshold.threshold);
    }

    return undefined
  }

  private async scanAndCollect(start: number, end: number) : Promise<boolean[]> {
    
    var matches = 0
    var result: boolean[] = []

    for (var newThreshold = start; newThreshold != end; newThreshold += Math.sign(end - start)) {

      if (newThreshold < 0) break;
      if (newThreshold > 255) break;
      
      this.threshold.patchValue(newThreshold);

      await this.currentInstrument?.sendKeyThreshold(this.currentKey, newThreshold);
      await sleep(50);

      result.push(this.instrumentView.pressed.contains(this.currentKeyName));
    } 

    return result
  }
}
