import { Component, Input, OnInit } from '@angular/core';
import { MidiNoteConfig } from 'src/app/helpers/notes';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  private _note?: MidiNoteConfig = undefined;
   
  @Input()
  set note(note : MidiNoteConfig | undefined ) {
    this._note = note;
  }

  get note(): MidiNoteConfig | undefined {
    return this._note;
  }

  constructor() { }

  ngOnInit(): void {
  }

}

