import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pitch-indicator',
  templateUrl: './pitch-indicator.component.svg',
  styleUrls: ['./pitch-indicator.component.scss']
})
export class PitchIndicatorComponent implements OnInit {

  @Input()
  detuning: number = 8192;

  constructor() { }

  ngOnInit(): void {
  }

  public get offset() : number {
    return (this.detuning - 8192) / 8192;
  }
}
