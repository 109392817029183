// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
  min-width: 10em;
  width: 10em;
}`, "",{"version":3,"sources":["webpack://./src/app/views/learn/tuner/tuner.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF","sourcesContent":["span {\n  min-width: 10em;\n  width: 10em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
