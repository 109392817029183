import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { rootViews } from 'src/app/helpers/log-config';
import { MidiNoteConfig, MIDI_NOTES } from 'src/app/helpers/notes';
import { UNCRecord } from 'src/app/model/uncsettings';

const log = rootViews.getChildCategory("noteConfig");

@Component({
  selector: 'app-note-config',
  templateUrl: './note-config.component.html',
  styleUrls: ['./note-config.component.scss']
})
export class NoteConfigComponent implements OnInit {

  configForm: FormGroup;

  value: number = 0;

  /*
  options: Options = {
    floor: -8192,
    ceil: 8192,
    showSelectionBarFromValue: 0,
    tickStep: 16,
    tickValueStep: 4096,

    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '\u266d';
        case LabelType.High:
          return '<b>\u266f</b>';
        default:
          return (value < 0 ? '\u266d' : (value > 0 ? '\u266f' : ''));
      }
    },

    getLegend: (value: number): string => {
      switch(value) {
        case -8192: return "\u{1D12b}";
        case -4096: return "\u{266d}";
        case +4096: return "\u{266f}";
        case +8192: return "\u{1D12a}";
        default: return "";
      }
    },

    showTicks: true,

    ticksArray: [-8192, -4096, 0, 4096, 8192]
  }
  */

  private _config?: UNCRecord = undefined;

  private _disabled?: boolean = undefined;

  get NOTES(): MidiNoteConfig[] {
    return MIDI_NOTES;
  }

  @Input()
  set disabled(flag: boolean | undefined) {

    if (flag) {
      this.configForm.disable();
    }
    else {
      this.configForm.enable();
    }

    this._disabled = flag;
  }

  get disabled(): boolean | undefined {
    return this._disabled;
  }

  @Input()
  set config(config: UNCRecord | undefined) {
    this._config = config;

    this.populateForm();
  }

  get config(): UNCRecord | undefined {
    return this._config;
  }

  constructor() { 

    this.configForm = new UntypedFormGroup({
      note:   new UntypedFormControl(60, [ Validators.min(0), Validators.max(5), Validators.required ]),
      pitch:  new UntypedFormControl(0,  [ Validators.min(-8192), Validators.max(+8192), Validators.required ])
    });

    let self = this;

    Object.keys(this.configForm.controls).forEach( key => {
      let control = this.configForm.get(key);

      if (control instanceof UntypedFormControl) {
        let formControl = <UntypedFormControl>control;
        formControl.valueChanges.subscribe( (value:any) => {
          self.valueChanged(key, value);
        });
      }
    });
  }

  readonly tickLabels: string[] = ["\u{1D12b}", "\u266d", "", "\u266f", "\u{1D12a}"];

  private populatingForm: boolean = false;

  private populateForm() {
    let current = this.populatingForm;
    this.populatingForm = true;

    if (this._config) {
      this.configForm.setValue({ 
        "note": this._config.parameterType,
        "pitch": this._config.pitchShift
      });
    }
    this.populatingForm = current;
  }
  
  valueChanged(settingName: string, newValue: any) {
    
    if (this.populatingForm) {
      return;
    }

    log.debug("Setting " + settingName + " is now " + newValue);
  }

  ngOnInit(): void {
  }

  playTone() {
    
  }
}
