import { Feature, FeatureSupportService } from './../../services/feature-support.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConnectionState, InstrumentConnection, InstrumentConnectionMode } from 'src/app/model/instrument-connection';
import { DeviceInfo, HardwareTypes } from 'src/app/model/device-info';
import { ConnectionService } from 'src/app/services/connection.service';
import { FirmwareService } from 'src/app/services/firmware.service';
import { FirmwareInfo } from 'src/app/model/firmware-info';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { FlasherService } from 'src/app/views/flasher/flasher.service';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { WelcomeComponent } from '../welcome/welcome.component';
import { HardwareSupportGuard } from 'src/app/services/hardware-support-guard';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends InstrumentComponentBase implements OnInit, AfterViewInit {

  hasMidiPermission: boolean = false;

  updateAvailable: boolean = false;

  updateFirmwares: FirmwareInfo[] = [];

  allowDowngrades: boolean = false;

  constructor(
    private flasherService: FlasherService,    
    public fs: FeatureSupportService, 
    protected cs: ConnectionService,
    private firmwareService: FirmwareService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef) {
      
      super(cs);
    }
    
  get hasEMEOConnection(): boolean {
    return this.cs.hasEMEOConnection;
  }
  
  public get isFlasherOpen() : boolean {
    return this.flasherService.isFlasherOpen;
  }
    
  ngOnInit() {
    // this.fetchUpdates();
    
    this.cs.tryReconnection()
    .then( (connected) => {
      
      if (connected) {
        this.fs.welcomeShown = true;
      }
      else {
        this.showWelcomeIfNeeded();
      }
    });
  }
    
  private _resetView : boolean = false;

  ngAfterViewInit() {
    this.selectTab();
  }
  
  @ViewChild("nav") // Get a reference to the ngbNav
  nav! : NgbNav;
  
  selectTab() : boolean {
    if (this.nav && this._resetView) {
      console.log("Resetting view ");
      // Programatically select the third tab
      this.nav.select("instrument");
      this._resetView = false;
      return true;
    }
    else {
      this._resetView = true;
    }
    return false;
  }
 
  showWelcomeIfNeeded(): void {

    if (!this.fs.developmentMode) {
      if (!this.fs.welcomeShown) {
        this.showWelcome();
      }
    }
    else {
      this.fs.welcomeShown = true;      
    }
  }

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    this.selectTab();

    if (current) {
      await this.checkForUpdate();      
    }
  }

  firmwares: FirmwareInfo[] = [];
  commFirmwares: FirmwareInfo[] = [];

  async checkForUpdate() {

    if (this.currentInstrument) {

      if (this.currentInstrument.connectionMode === InstrumentConnectionMode.FLASH) {

      }

      if (this.currentInstrument.state === 'ready') {
        await this.findUpdates( this.currentInstrument.deviceInfo );
      }
      else {
        this.waitForInstrumentReady();
      }
    }
    else {
      if (this.fs.maintenanceMode) {
        this.updateFirmwares = this.firmwares;
      }
      else {
        this.updateFirmwares = [ this.firmwares[0] ];
      }
      
      this.updateAvailable = this.updateFirmwares.length > 0;
    }
  }

  private waitForInstrumentReady() {
    if (this.currentInstrument) {
      this.currentInstrument.once('statechange', (connection: InstrumentConnection, state: string) => {
        if (state === ConnectionState.ERROR) {
          return; 
        }

        if (state === ConnectionState.READY) {
          this.findUpdates( connection.deviceInfo );
        }
        else {
          this.waitForInstrumentReady();
        } 
      });
    }
  }
  
  private async findUpdates(info: DeviceInfo) {

    this.allowDowngrades = this.can(Feature.FreeFirmwareSelection);
    let onlyLatest = !this.can(Feature.FreeFirmwareSelection);

    if (info.hardware) {
      this.firmwares = await this.firmwareService.getFirmwareVersions( info.hardware.type, [ info.hardware.version ] ) || [];
      this.commFirmwares = await this.firmwareService.getFirmwareVersions( HardwareTypes.NINA ) || [];
    }

    this.updateFirmwares = this.firmwareService.findUpdates(
                                    info.hardware.type, info.hardware.version, 
                                    info.firmwareVersion,
                                    this.firmwares, 
                                    onlyLatest, this.allowDowngrades);

    this.commFirmwares = this.firmwareService.findUpdates(
                                    HardwareTypes.NINA, 1, 
                                    info.ninaVersion || "0",
                                    this.commFirmwares, 
                                    onlyLatest, this.allowDowngrades);

    this.updateAvailable = (this.updateFirmwares.length > 0) || (this.commFirmwares.length > 0);
    this.cd.detectChanges();
  }

  async fetchUpdates() {

    let data = await this.firmwareService.getFirmwareVersions() || [];

    this.firmwares = data;
    this.checkForUpdate();
  }  

  
  showWelcome() {
    if (!this.modalService.hasOpenModals()) {

      const modalRef = this.modalService.open(WelcomeComponent, { centered: true, size: 'xl', backdrop  : 'static', keyboard: false, container: "#fullscreenContainer"  });
      let instance = modalRef.componentInstance;
  
      modalRef.result.then( () => { this.fs.welcomeShown = true; }, () => { this.fs.welcomeShown = true; });
    }
  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.currentInstrument }, ...features);
  }
}
