import { UNCRecord } from "./uncsettings";

export class UNCKeyMapping {

  readonly key: string;

  factory: UNCRecord | undefined;
  
  normal: UNCRecord | undefined;

  overblow: UNCRecord | undefined;

  constructor(key: string) {
    this.key = key;
  }
}
