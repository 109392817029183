export class PitchNode extends AudioWorkletNode {

  audioSamplesPerAnalysis: number = 512;

  constructor(context: AudioContext) {
    super(
      context, 
      "pitch-processor", 
      {
        numberOfInputs: 1,
        outputChannelCount: [1],
      });
  }

}

export interface PitchMessage {
  cents: number;
  centsAngle: number;
  frequency: number;
  index: number;
  noteFrequency: number;
}