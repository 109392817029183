import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { Backup } from 'src/app/model/backup';
import { HardwareInfo, HardwareTypes } from 'src/app/model/device-info';
import { BackupService } from 'src/app/services/backup.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { SupportRequestService } from 'src/app/services/support-request.service';
import { VERSION } from 'src/environments/version';



@Component({
  selector: 'app-support-request',
  templateUrl: './support-request.component.html',
  styleUrls: ['./support-request.component.scss']
})
export class SupportRequestComponent extends InstrumentComponentBase implements OnInit {

  version = VERSION;

  CATEGORIES = [
    'Playing',
    'Connecting',
    'Configuration',
    'Backup',
    'Firmware update',
    'Other'
  ];

  protected request: Object;

  constructor(
    protected backupService: BackupService,
    protected modalService: NgbModal,
    protected supportService: SupportRequestService,
    protected fs: FeatureSupportService,
    protected cs: ConnectionService) {

      super(cs);
  
      let devInfo = this.currentInstrument?.deviceInfo;
      let hwInfo = devInfo?.hardware;
      let deviceDate = new Date(hwInfo?.deviceDate || Date.now());
  
      this.request = {
        hardware: {
          serial: hwInfo?.serialNumber || '',
          deviceDate: deviceDate,
          type: hwInfo?.type || HardwareTypes.Sax,
          version: hwInfo?.version || 1,
          boardSerial: hwInfo?.circuitSerialNumber || '',
          date: { year: deviceDate.getFullYear(), month: (1 + deviceDate.getMonth()), day: deviceDate.getDate() }
        },

        firmware: {
          ninaFirmware: devInfo?.ninaVersion || '',
          firmware: devInfo?.firmwareVersion || ''
        },

        webTool: {
          version: this.version.version,
          devMode: fs.developmentMode,
          maintenanceMode: fs.maintenanceMode
        }
      }

      let sendBackupControl = new UntypedFormControl(false);

      this.supportRequest = new UntypedFormGroup({

        requestType: new UntypedFormControl('Other'),
        description: new UntypedFormControl(''),
        name: new UntypedFormControl(''),
        phone: new UntypedFormControl(''),
        email: new UntypedFormControl(''),

        sendBackup: sendBackupControl
      });

    sendBackupControl.valueChanges.subscribe( (value : boolean) => {
      this.sendBackup = value;
    });

    backupService.getLocalBackups().then( (backups) => {
      this.backup = backups.length > 0 ? backups[0] : undefined;
      this.sendBackup = (this.backup ? true : false);

      this.supportRequest.patchValue( {
        sendBackup: this.sendBackup
      }, {onlySelf: true, emitEvent: true});

    });    
  }

  backup: Backup | undefined;
  sendBackup: boolean = false;

  supportRequest: UntypedFormGroup;

  ngOnInit(): void {
  }

  close() {
    this.modalService.dismissAll();
  }

  sendRequest() {
    let newRequest = Object.assign(
                        Object.assign(
                          {}, 
                          this.request),
                        {
                          name: this.supportRequest.value[ 'name' ],
                          email: this.supportRequest.value[ 'email' ],
                          phone: this.supportRequest.value[ 'phone' ],
                          requestType: this.supportRequest.value[ 'requestType' ],
                          description: this.supportRequest.value[ 'description' ],

                          backup: {}
                        });

    if (this.sendBackup && this.backup) {
      newRequest.backup = this.backup;
    }

    this.supportService.sendSupportRequest(newRequest)
  }

}
