// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
  max-height: 20rem;
  overflow-y: hidden;
}

.flash {
  display: block;
  visibility: hidden;
}

.consent {
  visibility: visible;
}

button.connect {
  margin-top: 0em;
  margin-bottom: 1.5em;
}

div.keystates {
  margin-bottom: 0;
  display: table;
}

div.keystate {
  height: default !important;
  min-height: 0.5em !important;
  margin-bottom: 0;
  display: table-row;
}

span.selected {
  background: green;
}

span.index {
  text-align: right;
  width: 2em;
  display: table-cell;
}

span.key {
  display: table-cell;
}`, "",{"version":3,"sources":["webpack://./src/app/views/recovery-mode/recovery-mode.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;AADF;;AAIA;EACE,cAAA;EACA,kBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,eAAA;EACA,oBAAA;AADF;;AAIA;EACE,gBAAA;EAEA,cAAA;AAFF;;AAKA;EACE,0BAAA;EACA,4BAAA;EACA,gBAAA;EAEA,kBAAA;AAHF;;AAMA;EACE,iBAAA;AAHF;;AAMA;EACE,iBAAA;EACA,UAAA;EAEA,mBAAA;AAJF;;AAOA;EACE,mBAAA;AAJF","sourcesContent":["\n\n.card-body {\n  max-height: 20rem;\n  overflow-y: hidden;\n}\n\n.flash {\n  display: block;\n  visibility: hidden;\n}\n\n.consent {\n  visibility: visible;\n}\n\nbutton.connect {\n  margin-top: 0em;\n  margin-bottom: 1.5em;\n}\n\ndiv.keystates {\n  margin-bottom: 0;\n\n  display: table;\n}\n\ndiv.keystate {\n  height: default ! important;\n  min-height: 0.5em ! important;\n  margin-bottom: 0;\n\n  display: table-row;\n}\n\nspan.selected { \n  background: green;\n}\n\nspan.index {\n  text-align: right;\n  width: 2em;\n\n  display: table-cell;\n}\n\nspan.key {\n  display: table-cell;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
