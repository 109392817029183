import { DeviceInfo } from "./device-info";
import { GlobalSettings } from "./global-settings";
import { UNCSettings } from "./uncsettings";
import { v4 as uuid } from 'uuid'; 
import { KeyThresholds } from "./instrument-connection";

export class Backup {

    id: string = uuid();
    name?: string = undefined;
    
    majorVersion: number = 0;  
    minorVersion: number = 0;  

    automatic: boolean = false;

    date: Date = new Date();

    device?: DeviceInfo;
    
    unc?: UNCSettings;

    sensorThresholds?: KeyThresholds;
    
    globalSettings?: GlobalSettings;
}
