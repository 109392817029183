import { Component, HostListener, OnDestroy } from "@angular/core";
import { INSTRUMENT_TYPES, lookupInstrument, DEV_NO_DEVICE } from "../model/device-info";
import { InstrumentConnection } from "../model/instrument-connection";
import { ConnectionService } from "../services/connection.service";

@Component({
    selector: 'app-instrument-base',
    template: ``
  })
export abstract class InstrumentComponentBase implements OnDestroy {

    private _currentInstrument: InstrumentConnection | undefined;

    private _connectListener = this.onInstrumentConnect.bind(this);
    private _disconnectListener = this.onInstrumentDisconnect.bind(this);

    constructor(
        protected cs: ConnectionService) {

        this.cs.on('instrument-connect', this._connectListener);
        this.cs.on('instrument-disconnect', this._disconnectListener);

        this.currentInstrument = this.cs.emeoConnection;
    }

    @HostListener('window:beforeunload', [ '$event' ])
    confirmLeaving(e: Event) : string {

        var confirmationMessage = 'It looks like you have been editing something. '
                                + 'If you leave before saving, your changes will be lost.';
                                
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }

    async ngOnDestroy() {
        this.cs.off('instrument-connect', this._connectListener);
        this.cs.off('instrument-disconnect', this._disconnectListener);
    }

    protected async refreshInstrumentInfo() {

        if (this.currentInstrument) {
            await this.currentInstrument.refreshInstrumentInfo();
        }
    }

    get currentInstrument() : InstrumentConnection | undefined {
        return this._currentInstrument;
    }

    private set currentInstrument(instrument: InstrumentConnection | undefined) {

        let before = this._currentInstrument;
  
        if (before !== instrument) {
            this._currentInstrument = instrument;

            this.onInstrumentChanged(instrument, before);
        }
    }

    async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    }

    private onInstrumentConnect(instrument: InstrumentConnection) {
        this.currentInstrument = instrument;        
    }

    private onInstrumentDisconnect(instrument: InstrumentConnection) {
        this.currentInstrument = undefined;
    }

    get hardwareString(): string {
    
        var result = "Unknown Instrument";
        let info = this.currentInstrument?.deviceInfo;

        if (info !== DEV_NO_DEVICE) {
            let hardware = info?.hardware;
        
            if (hardware) {
                let instrument = INSTRUMENT_TYPES.find( value => (value.id == 'EMEO_V1'));
                instrument = lookupInstrument(hardware.type, hardware.version) || instrument;
            
                if (instrument) {
                    result = instrument.title;
                }
            }
        }

        return result;
      }    
}
