import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  constructor() { }

  @Input()
  progress: any;
  
  @Input()
  key: string = '';

  ngOnInit(): void {
  }

  get isDone() : boolean {
    return this.isProgress('done');
  }

  get isWaiting() : boolean {
    return this.isProgress('wait');
  }

  get isActive() : boolean {
    return this.isProgress('active');
  }

  get isError() : boolean {
    return this.isProgress('error');
  }

  private isProgress(state: string) : boolean {

    if (this.progress) {
      if (this.progress[this.key]) {
        return (this.progress[this.key] == state);
      }
    }

    return false;
  }
}
