// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.versions {
  padding-left: 0;
  padding-inline-start: 0;
}

.versions li {
  list-style-type: none;
}

.infos li {
  list-style-type: square;
  font-size: 0.8em;
}

div.version {
  padding-top: 0.5rem;
  font-size: larger;
  font-weight: bold;
}

.card-body {
  max-height: 20rem;
  overflow-y: auto;
}

p {
  margin-bottom: 0.4em;
}

ul.versions li {
  margin-top: 0.3em;
}

ul.internal {
  margin-top: 0.4em;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/app/views/change-log/change-log.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,uBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;AACJ","sourcesContent":["ul.versions {\n    padding-left: 0;\n    padding-inline-start: 0;\n}\n\n.versions li {\n    list-style-type: none;\n}\n\n.infos li {\n    list-style-type: square;\n    font-size: 0.8em;\n}\n\ndiv.version {\n    padding-top: 0.5rem;\n    font-size: larger;\n    font-weight: bold;\n}\n\n.card-body {\n    max-height: 20rem;\n    overflow-y: auto;\n}\n\np {\n    margin-bottom: 0.4em;\n}\n\nul.versions li {\n    margin-top: 0.3em;\n}\n\nul.internal {\n    margin-top: 0.4em;\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
