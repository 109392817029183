import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { ProgressObserver } from 'src/app/model/progress-observer';
import { UNCRecordType, UNCSettings } from 'src/app/model/uncsettings';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { EmeoSaxophoneComponentV1 } from '../emeo-saxophone-v1/emeo-saxophone-v1.component';
import { UNCKeyMapping } from 'src/app/model/unckey-mapping';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyPickerComponent } from './key-picker/key-picker.component';

@Component({
  selector: 'app-user-defined-keys',
  templateUrl: './user-defined-keys.component.html',
  styleUrls: ['./user-defined-keys.component.scss'],
  providers: [  ]
})
export class UserDefinedKeysComponent extends InstrumentComponentBase implements OnInit {

  actionProgress: string = "0%";

  observer: ProgressObserver;

  @ViewChild('instrumentView')
  instrumentView!: EmeoSaxophoneComponentV1;


  constructor(    
    protected cs: ConnectionService,
    protected modalService: NgbModal,
    public fs: FeatureSupportService,
    private cd: ChangeDetectorRef) {

    super(cs);

    let comp = this;

    this.observer = <ProgressObserver> {

      progress(num: number, div: number) : void {
        comp.actionProgress = String(Math.trunc(100 * num / div)) + '%';
        comp.cd.detectChanges();
      }

    }    
  }

  currentKeyConfig? : UNCKeyMapping = undefined;

  unc?: UNCKeyMapping[] = undefined;

  async ngOnInit() {
    this.setProgress('loadUNCTable', 'wait');

    await this.fetchUNCRecords();
  }

  progress: any = {};

  get isActive() : boolean {

    if (this.progress) {

      for (var key of Object.keys(this.progress)) {
        if (this.progress[key] === 'active') {
          return true;
        }
      }
    }

    return false;
  }


  isProgress(key: string, state: string): boolean {
    return (this.progress ? this.progress[ key ] == state : false);
  }

  progressStyles(key: string): any {

    let styles = { 
        'bg-success': this.isProgress(key, 'done'), 
        'bg-danger': this.isProgress(key, 'error'), 
        'bg-primary': this.isProgress(key, 'active'),
        'progress-bar-animated': this.isProgress(key, 'active') 
      }

      return styles;
  }

  private setProgress(key: string, state: 'active'|'wait'|'done'|'error') {
    this.progress = this.progress || {};
    this.progress[key] = state;
    this.cd.detectChanges();
  }

  private async fetchUNCRecords() {

    if (this.currentInstrument) {

      this.unc = undefined;

      this.setProgress('loadUNCTable', 'wait');
      this.setProgress('loadUNCTable', 'active');

      let settings = await this.currentInstrument.loadUNCTable( this.observer );
      let mapping: UNCKeyMapping[] = [];

      let offset = 0;

      for (var source of [settings.uncDump, settings.ksrDump]) {

        for (var i = 0; i < source.recordCount; i++) {
          let index = offset + i;
          let setting = source.records[ index ];
  
          if (setting.parameterType < 36) continue;
          if (setting.parameterType > 95) continue;
  
          if (setting.recordType != 1) {
            let entry = mapping.find( v => (v.key === setting.keyState)) 
            
            if (!entry) {
              entry = new UNCKeyMapping( setting.keyState );
              mapping.push(entry);
            }
  
            switch (setting.recordType) {
              case UNCRecordType.NOTE:
                entry.factory = setting;
                break;
                
              case UNCRecordType.NOTE_CONFIG:
                entry.normal = setting;
                break;
                  
              case UNCRecordType.NOTE_OVERBLOW:
                entry.overblow = setting;
                break;
            }
          }
        }

        offset = source.maxRecords;
      }

      // mapping.sort( (left, right) => (left.factory?.parameterType - right.factory?.parameterType));

      this.unc = mapping;
      
      this.setProgress('loadUNCTable', 'done');

      this.setProgress('loadUNCTable', 'wait');

    }
  }

  pickFingering() {
    let dialog = this.modalService.open(KeyPickerComponent, { centered: true, size: 'lg', backdrop  : 'static', container: "#fullscreenContainer"  });

    let comp = <KeyPickerComponent>dialog.componentInstance
    comp.unc = this.unc;
  }
}
