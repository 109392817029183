import { BackupRestoreComponent } from '../backup-restore/backup-restore.component';
import { BackupService } from '../../services/backup.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionService } from 'src/app/services/connection.service';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss']
})
export class BackupComponent extends InstrumentComponentBase implements OnInit {

  showAutomaticBackups: boolean = false;

  toggleAutomaticBackups(e : Event) {
    this.showAutomaticBackups = (e.target as HTMLInputElement).checked;
    this.cd.detectChanges();
  }

  constructor(
    private modalService: NgbModal,
    protected cs: ConnectionService,
    protected cd: ChangeDetectorRef,
    private backupService: BackupService) { 

    super(cs);
  }

  ngOnInit(): void {
  }


  async performFullBackup() {
    if (this.currentInstrument) {
      let backup = await this.backupService.backupEverything(this.currentInstrument);

      backup.name = "Manual backup";
      backup.automatic = false;

      await this.backupService.addLocalBackup(backup);
    }
  }

  async performRestoreUNC() {

    const modalRef = this.modalService.open(BackupRestoreComponent, { centered: true, size: 'xl', backdrop  : 'static', container: "#fullscreenContainer"  });
    let instance = <BackupRestoreComponent>modalRef.componentInstance;

    modalRef.result.then( () => {}, () => {});

  }


  async importBackup() {
    await this.backupService.import();
  }
}
