import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportRequestService {

  private clientUrl = environment.supportApiURL ;

  constructor(
    private http: HttpClient) { 

  }

  sendSupportRequest(data: Object): void {

    const url = `${this.clientUrl}`;
    let payload = JSON.stringify(data);

    console.log(payload);

    this.http.post(url, payload ).subscribe(data => {
      console.log(JSON.stringify(data));
    });
  }

}
