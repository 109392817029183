// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.dialog {
  padding: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
}

.dialog h1 {
  font-weight: 500;
}

.connection-logo {
  fill: white;
  max-width: 100px;
}

.technologies,
.browsers {
  display: table;
  border-spacing: 0 10px;
}

.technology {
  display: table-row;
}

.technology span,
.technology p,
.technology div {
  vertical-align: middle;
  display: table-cell;
  padding-right: 20px;
  padding-bottom: 10px;
}

.technology div {
  font-size: xx-large;
  color: red;
}

.supported {
  color: green;
}

.browsers img {
  max-width: 48px;
  padding-right: 20px;
}

.browsers .selection {
  align-content: center;
}

a:focus,
img:focus {
  outline: none;
}

.start {
  padding-top: 20px;
  vertical-align: baseline;
  padding-right: 20px;
}

.bi-windows,
.bi-bluetooth,
.bi-usb-symbol {
  font-size: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/welcome/welcome.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,iCAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,WAAA;EACA,gBAAA;AAAF;;AAGA;;EAEE,cAAA;EACA,sBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;;;EAGE,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,UAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,eAAA;EACA,mBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;;EAEE,aAAA;AAAF;;AAGA;EACE,iBAAA;EACA,wBAAA;EACA,mBAAA;AAAF;;AAGA;;;EAGE,eAAA;AAAF","sourcesContent":["div.dialog {\n  padding: 30px;\n\n  font-family: 'Roboto', sans-serif;\n  font-weight: 200;\n}\n\n.dialog h1 {\n  font-weight: 500;\n}\n\n.connection-logo {\n  fill: white;\n  max-width: 100px;\n}\n\n.technologies,\n.browsers {\n  display: table;\n  border-spacing: 0 10px;\n}\n\n.technology {\n  display: table-row;\n}\n\n.technology span,\n.technology p,\n.technology div {\n  vertical-align: middle;\n  display: table-cell;\n  padding-right: 20px;\n  padding-bottom: 10px;\n}\n\n.technology div {\n  font-size: xx-large;\n  color: red;\n}\n\n.supported {\n  color: green;\n}\n\n.browsers img {\n  max-width: 48px;\n  padding-right: 20px;\n}\n\n.browsers .selection {\n  align-content: center;\n}\n\na:focus,\nimg:focus {\n  outline: none;\n}\n\n.start {\n  padding-top: 20px;\n  vertical-align: baseline;\n  padding-right: 20px;\n}\n\n.bi-windows,\n.bi-bluetooth,\n.bi-usb-symbol {\n  font-size: 4rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
