
export enum HardwareTypes {
    NONE = 0,

    Sax = 1,

    Clarinet = 2,

    NINA = 0x1001
}

export interface InstrumentType {

  id: string,

  type: HardwareTypes,
  version: number,

  title: string,

  ninaVersion?: string;
}

export function lookupInstrument(type: HardwareTypes, version: number) : InstrumentType | undefined {
  return INSTRUMENT_TYPES.find( value => ((value.type == type) && (value.version == version)));
}

export const INSTRUMENT_TYPES: InstrumentType[] = [

  {
    id: 'EMEO_V1',

    type: HardwareTypes.Sax,
    version: 1,
    
    title: 'EMEO Practice Saxophone'
  }, 

  {
    id: 'EMEO_V2',

    type: HardwareTypes.Clarinet,
    version: 1,

    title: 'EMEO Practice Clarinet'
  }
]

export class HardwareInfo {

  type: HardwareTypes;
  
  version: number;
  
  serialNumber: string;
  
  circuitSerialNumber: string;
  
  deviceDate: Date;

  constructor(
    hardwareType: HardwareTypes,
    hardwareVersion: number,
    hardwareSerialNumber: string,
    circuitSerialNumber: string,
    deviceDate: Date) {

    this.type = hardwareType;
    this.version = hardwareVersion;
    this.serialNumber = hardwareSerialNumber;
    this.circuitSerialNumber = circuitSerialNumber;
    this.deviceDate = deviceDate;
  }  
}

export class DeviceInfo {

  hardware: HardwareInfo;

  firmwareVersion: string;

  ninaVersion?: string;

  constructor(
    hardware: HardwareInfo,
    firmwareVersion: string) {

    this.hardware = hardware;
    this.firmwareVersion = firmwareVersion;
  }
}

export interface InstrumentConnectionInfo {
  get deviceInfo() : DeviceInfo;
}

export const NO_DEVICE = new HardwareInfo(HardwareTypes.NONE, 0, '', '', new Date(0));
export const EMEO_SAX_V1 = new HardwareInfo(HardwareTypes.Sax, 1, '', '', new Date(2021, 1, 1));
export const EMEO_SAX_V2 = new HardwareInfo(HardwareTypes.Sax, 2, '', '', new Date(2023, 3, 1));
export const EMEO_CLARINET_V1 = new HardwareInfo(HardwareTypes.Clarinet, 1, '', '', new Date(2024, 1, 1));

export const DEV_NO_DEVICE = new DeviceInfo(new HardwareInfo(HardwareTypes.NONE, 0, '', '', new Date(0)), '');
export const DEV_EMEO_SAX_V1_202 = new DeviceInfo(new HardwareInfo(HardwareTypes.Sax, 1, '', '', new Date(2020, 10, 1)), '<UNKNOWN>');
export const DEV_EMEO_CLARINET_V1 = new DeviceInfo(new HardwareInfo(HardwareTypes.Clarinet, 1, '', '', new Date(2024, 1, 1)), '<UNKNOWN>');


export const HARDWARE_INFOS: HardwareInfo[] = [
  
  EMEO_SAX_V1,
  EMEO_SAX_V2,
  
  EMEO_CLARINET_V1
];
