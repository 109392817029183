import { Component, OnInit } from '@angular/core';
import { CloseCallback } from '../../flasher/flasher.component';
import { SerialConnection } from 'src/app/services/connection.service';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { InstrumentConnection } from 'src/app/model/instrument-connection';
import { DeviceInfo, HardwareTypes, INSTRUMENT_TYPES, InstrumentType } from 'src/app/model/device-info';

import { Observable, from, switchMap } from "rxjs";
import { ipcMain } from 'electron';

interface PortData {
  port: PortInfo;
  name: string;
  serial: string | undefined;
}

export function lookupInstrument(type: HardwareTypes, version: number) : InstrumentType | undefined {
  return INSTRUMENT_TYPES.find( value => ((value.type == type) && (value.version == version)));
}

@Component({
  selector: 'app-serial-selection',
  templateUrl: './serial-selection.component.html',
  styleUrl: './serial-selection.component.scss'
})
export class SerialSelectionComponent implements OnInit {

  readonly json = JSON

  constructor(
    protected _fs: FeatureSupportService) {

  }

  closeCallback: CloseCallback | undefined = undefined;

  protected _portList: PortInfo[] = [];

  setPortList(portList: PortInfo[]) {
    this._portList = portList;

    this.ports = this.loadPorts();
  }

  ports: PortData[] = [];

  ngOnInit() {

    window.ipcRenderer.on("serial:portAdded", (event, port: PortInfo) => {
      this.ports.push( this.buildPortData( port ));
    });

    window.ipcRenderer.on("serial:portRemoved", (event, port: PortInfo) => {
      this.ports = this.ports.filter( entry => (entry.port.portId === port.portId));
    });
  }

  protected loadPorts() : PortData[] {
    return this._portList.map( (port) => this.buildPortData(port));
  }

  buildPortData(port: PortInfo) : PortData {
    return <PortData> { 
      port: port, 
      name: this.getHardwareString(port),
      serial: port.hardwareSerialNumber 
    }    
  }

  getHardwareString(info: PortInfo): string {
    
    var result = "EMEO";

    if (info.hardwareType && info.hardwareVersion) {
      let instrument = INSTRUMENT_TYPES.find( value => (value.id == 'EMEO_V1'));
      instrument = lookupInstrument(info.hardwareType, info.hardwareVersion) || instrument;
  
      if (instrument) {
          result = instrument.title;
      }
    }

    return result;
  }    
  
  protected connect(port: PortData) {

    window.ipcRenderer.send("serial:portSelected", port.port.portId);
    
    if (this.closeCallback) {
      this.closeCallback();
      this.closeCallback = undefined;
    }

  }
}
