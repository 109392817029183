import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {CATEGORY_LOG_CONTROL} from "typescript-logging-category-style";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (window !== undefined) {
  (window as any).appLogControl = CATEGORY_LOG_CONTROL;

  (window as any).enableDebug = () => {
    CATEGORY_LOG_CONTROL().getProvider("EMEO-Web").update('debug');
  }
}