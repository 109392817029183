import { ChangeDetectorRef, Component, ComponentFactory, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DeviceInfo, HardwareInfo, HardwareTypes } from 'src/app/model/device-info';
import { ConnectionState, InstrumentConnection, InstrumentConnectionMode } from 'src/app/model/instrument-connection';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirmwareInfo } from 'src/app/model/firmware-info';
import { FirmwareService } from 'src/app/services/firmware.service';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { ConnectionService } from 'src/app/services/connection.service';
import { FlasherService, FlasherType } from 'src/app/views/flasher/flasher.service';
import { Feature, FeatureSupportService } from 'src/app/services/feature-support.service';
import { InstrumentConfigComponent } from '../instrument-config/instrument-config.component';
import { rootViews } from 'src/app/helpers/log-config';
import { UNCRecord, UNCSettings, UNCType } from 'src/app/model/uncsettings';
import { UNCDump } from 'src/app/model/uncsettings';
import { getMidiNoteForCode, MidiNoteConfig } from 'src/app/helpers/notes';
import { InstrumentViewComponentBaseComponent, UNCKeyInfo } from 'src/app/helpers/instrument-view-component-base.component';
import { BackupService } from 'src/app/services/backup.service';
import { IProgressWithInfo } from '../report/report.component';
import { Backup } from 'src/app/model/backup';
import { ReportService } from 'src/app/services/report.service';
import { FingeringModalComponent } from '../fingering-modal/fingering-modal.component';

const log = rootViews.getChildCategory("instrument");

@Component({
  selector: 'app-instrument',
  templateUrl: './instrument.component.html',
  styleUrls: ['./instrument.component.scss']
})
export class InstrumentComponent extends InstrumentComponentBase implements OnInit {

  public get midiNote() : MidiNoteConfig | undefined {
    let result = getMidiNoteForCode(this.uncPressed?.parameterType);

    return result;
  }

  @Input()
  updateFirmwares: FirmwareInfo[] = [];

  @Input()
  updateCommFirmwares: FirmwareInfo[] = [];

  @ViewChild('instrumentView')
  instrumentView! : InstrumentViewComponentBaseComponent;
  
  get instrumentType() : HardwareTypes {
    return this._hardwareInfo?.type || HardwareTypes.NONE;
  }

  private _deviceInfo?: DeviceInfo;

  private _hardwareInfo?: HardwareInfo;

  get hardwareInfo(): HardwareInfo | undefined {
    return this._hardwareInfo;
  }

  protected currentKeyName(index: number) : string {

    let keymap = this.instrumentView?.instrument?.keyMap;

    if (keymap) {
      return keymap[index] ? keymap[index].name : '<unknown>';
    }

    return '<unknown>';
  }

  protected get currentKeyIndexes() : number[] {

    let keymap = this.instrumentView?.instrument?.keyMap;

    return keymap ? Object.keys(keymap).map( k => Number.parseInt(k) ).sort( (a, b) => a-b) : [];
  }

  async openInstrumentZoom() {
    let modal = this.modalService.open(FingeringModalComponent, { backdrop: true, fullscreen: true, scrollable: true, centered: true, size: 'lg', backdropClass: 'showToolbar', container: "#fullscreenContainer" });

    let component = modal.componentInstance as FingeringModalComponent

    component.hardwareInfo = this.hardwareInfo;
  }

  async connectSerial() {
    let connect = await this.cs.establishSerialConnection()
  }
  
  private set hardwareInfo(info: HardwareInfo | undefined) {
    this._hardwareInfo = info;

    if (info) {
      this.cd?.detectChanges();
    }
  } 

  get deviceInfo(): DeviceInfo | undefined {
    return this._deviceInfo;
  }

  private set deviceInfo(info: DeviceInfo | undefined) {
    this._deviceInfo = info;

    if (info) {
      this.cd?.detectChanges();
    }
  } 

  public get isFlashMode() : boolean {
    return (this.currentInstrument?.connectionMode === InstrumentConnectionMode.FLASH);
  }

  public get isFlasherOpen() : boolean {
    return this.flasherService.isFlasherOpen;
  }

  constructor(
    public fs: FeatureSupportService,
    private flasherService: FlasherService,
    protected cs: ConnectionService,
    protected bs: BackupService,
    private firmwareService: FirmwareService,
    private rs: ReportService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef) { 

    super(cs);
  }
    
  async ngOnInit() : Promise<void> {
    await this.loadDeviceInfo();
  }

  async generateDeviceReport() {
    if (this.currentInstrument) {
      await this.rs.openReportDialogAndPrepareData(this.prepareReportBackup.bind(this));
    }
  }

  private async prepareReportBackup(observer: IProgressWithInfo) : Promise<Backup> {

    var tempUnc = false;
    var tempKeys = false;

    if (this.currentInstrument) {

      if (this.instrumentView) {
        tempUnc = this.instrumentView?.trackUNCPresses || false;
        tempKeys = this.instrumentView?.trackKeyPresses || false;  

        await this.instrumentView.updateKeyPressesTracking(false);
        await this.instrumentView.updateUNCPressesTracking(false);
      }

      let backup = await this.bs.backupEverything(this.currentInstrument, {
            error: () =>    { throw "Could not read configuration from instrument" },
            started: () =>  { observer.progress(0, "Start loading configuration")  },
            finished: () => { observer.progress(1, "Done loading configuration")   },
            progress: (step: number, total: number) => { observer.progress(step/total, "Loading configuration...") },        
          });

      if (this.instrumentView) {
        await this.instrumentView.updateUNCPressesTracking(tempUnc);
        await this.instrumentView.updateKeyPressesTracking(tempKeys);
        this.instrumentView.pressed = [];
      }

      backup.name = "Connected Instrument"
      backup.automatic = true;

      return backup;
    }

    throw "No active connection";
  }

  override async onInstrumentChanged(current: InstrumentConnection | undefined, before: InstrumentConnection | undefined) : Promise<void> {

    this._uncTable = undefined;
    
    if (current) {
      this._status = ConnectionState.LOADING;
      await this.loadDeviceInfo();      
    }
    else {
      this.hardwareInfo = undefined;
      this._deviceInfo = undefined;

      this.updateCommFirmwares = [];
      this.updateFirmwares = [];
    }
  }

  private _uncTable: UNCSettings | undefined;

  private async fetchDeviceInfo(connection: InstrumentConnection) : Promise<void> {
    this.deviceInfo = connection.deviceInfo;
    this.hardwareInfo = connection.deviceInfo.hardware;

    if (this.instrumentView) {
      let temp = this.instrumentView?.trackUNCPresses;
      this.instrumentView.trackUNCPresses = false;
      
      if (this.can(Feature.UNCEditor) && this._uncTable === undefined ) {
        log.debug("Loading UNC-Table from instrument")
        this._uncTable = await connection.loadUNCTable()
      }
      
      this.instrumentView.trackUNCPresses = temp;
    }
  }

  private _status : ConnectionState = ConnectionState.READY;

  public get status() : ConnectionState {
    return this._status;
  }

  public get isLoadingInstrumentInfo(): boolean {
    return this.status === ConnectionState.LOADING;
  }

  public get hasInstrumentInfo(): boolean {
    return this.status === ConnectionState.READY;
  }

  async loadDeviceInfo() : Promise<void> {
    
    if (this.currentInstrument) {
      if (this.currentInstrument.state === 'ready') {
        await this.fetchDeviceInfo(this.currentInstrument);
      }
      else {
        this.currentInstrument.on('statechange', (connection: InstrumentConnection, state: ConnectionState) => {
          if (state === ConnectionState.READY) {
            this.fetchDeviceInfo(connection).then( () => {
              this._status = state;
            });
          }
          else {
            this._status = state;
          }
        });
      }
    }
    else {
      this.deviceInfo = undefined;
    }
  }

  async updateCommFirmware() {

    if (this.updateCommFirmwares.length == 0) {
  
      this.updateCommFirmwares = await this.firmwareService.getFirmwareVersions(HardwareTypes.NINA) || [];

      if (this.updateCommFirmwares.length > 0) {
        this.flashCommFirmware();
      }
    }
    else {
      this.flashCommFirmware();
    }
  }

  async updateFirmware() {

      if (this.updateFirmwares.length == 0) {
  
        let firmwareVersions = await this.firmwareService.getFirmwareVersions(HardwareTypes.Sax) || [];
  
        let allowDowngrades = this.can(Feature.FreeFirmwareSelection);
        let onlyLatest = !this.can(Feature.FreeFirmwareSelection);
    
        this.updateFirmwares = this.firmwareService.findUpdates(
          HardwareTypes.Sax, 1, 
          "",
          firmwareVersions, 
          onlyLatest, allowDowngrades);

        if (this.updateFirmwares.length > 0) {
          this.flashFirmware();
        }
      }
      else {
        this.flashFirmware();
      }
  }  

  async flashFirmware() {
    await this.flasherService.openFlasher(this.updateFirmwares);
  }

  async flashCommFirmware() {
    await this.flasherService.openFlasher(this.updateCommFirmwares, FlasherType.NINA);
  }

  configureInstrument() {
    this.modalService.open(InstrumentConfigComponent, { size: 'md', backdrop: 'static', centered: true, container: "#fullscreenContainer"  });
  }

  can(...features: string[]) : boolean {
    return this.fs.can({ connection: this.cs.emeoConnection }, ...features);
  }

  pressedKeys: string[] = [];

  sensorStates: boolean[] = [];

  private searchKeys(table: UNCDump, sensors: string): undefined | [ string, UNCRecord ] {

    for(var key in table.records) {
      let record = table.records[key];

      if (record.keyState === sensors) {
        return [ key, record ];
      }
    }

    return undefined;
  };

  uncPressed: UNCRecord | null = null;

  onKeysPressed(event: UNCKeyInfo) {
    this.pressedKeys = event.keys;
    
    let keyState = event.sensors;
    while (keyState.length > 22) {
      keyState = keyState.substring(1);
    }

    this.sensorStates = keyState.split("").map( v => ( v == "1"));

    if (this._uncTable) {

      var table: UNCDump = this._uncTable.ksrDump;

      if (event.type === UNCType.USER) {
        table = this._uncTable.uncDump;
      }

      let setting = this.searchKeys(table, event.sensors);

      if (setting) {
        this.uncPressed = setting[1];
      }
    }
  }

}
