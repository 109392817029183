import { Injectable } from '@angular/core';
import { TypedEmitter } from 'tiny-typed-emitter';
import { rootService } from '../helpers/log-config';
import { PitchMessage, PitchNode } from '../helpers/pitch-node';
import { VolumeMessage, VolumeNode } from '../helpers/volume-node';

const log = rootService.getChildCategory('listener');

const noteStrings = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

export interface ListenerServiceEvents {
  'volume': (volume: number) => void;
  'pitch-detected': (pitch: PitchMessage) => void;
}

@Injectable({
  providedIn: 'root'
})
export class ListenerService extends TypedEmitter<ListenerServiceEvents> {

  // audio stuff
  protected audioContext: AudioContext;
  protected source?: MediaStreamAudioSourceNode;
  protected stream?: MediaStream;

  protected analyser: AnalyserNode;
  protected gainNode: GainNode;
  
  protected pitchNode?: PitchNode;
  protected volumeNode?: VolumeNode;

  protected _visualizationWorker?: Worker;

  set visualizationWorker(worker: Worker | undefined) {
    this._visualizationWorker = worker;

    if (this._targetCanvas) {
      log.debug("Setting target canvas for visualizer with " + this._targetCanvas.width + " x " + this._targetCanvas.height);

      this._visualizationWorker?.postMessage(
        {
          message: 'set-canvas',
          canvas: this._offscreenCanvas,
          width: this._targetCanvas.width,
          height: this._targetCanvas.height
        },
        [this._offscreenCanvas]);
    }
  }

  get visualizationWorker() : Worker | undefined {
    return this._visualizationWorker;
  }

  constructor() {
    super();

    this.audioContext = new AudioContext();

    this.analyser = this.audioContext.createAnalyser();
    this.analyser.minDecibels = -100;
    this.analyser.maxDecibels = -10;
    this.analyser.smoothingTimeConstant = 0.85;
    this.analyser.fftSize = 2048;

    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = (this.gainNode.gain.minValue + this.gainNode.gain.maxValue) / 2;

    this.setupPitchNode(this.audioContext).then(
      (pitchNode: PitchNode) => {
        this.pitchNode = pitchNode;
        this.pitchNode.audioSamplesPerAnalysis = 1024;
      }
    );

    this.setupVolumeNode(this.audioContext).then(
      (volumeNode: VolumeNode) => {
        this.volumeNode = volumeNode;
      }
    );

    this.setupVisualizationWorker().then( 
      (worker: Worker) => {
        this.visualizationWorker = worker;

        worker.onmessage = ({ data }) => {
          console.log(`page got message: ${data}`);
        };
    
      } 
    );
  }

  private _renderLoop : FrameRequestCallback | undefined = undefined;

  protected _targetCanvas: HTMLCanvasElement | undefined = undefined;
  protected _offscreenCanvas: any | undefined = undefined;

  public get targetCanvas(): HTMLCanvasElement | undefined {
    return this._targetCanvas;
  }

  public set targetCanvas(canvas: HTMLCanvasElement | undefined) {
    this._targetCanvas = canvas;    
    // @ts-ignore
    this._offscreenCanvas = canvas?.transferControlToOffscreen();

    if (this._targetCanvas && this._offscreenCanvas) {

      if (this._targetCanvas) {
        log.debug("Setting target canvas for visualizer with " + this._targetCanvas.width + " x " + this._targetCanvas.height);
        
        this._visualizationWorker?.postMessage(
          {
            message: 'set-canvas',
            canvas: this._offscreenCanvas,
            width: this._targetCanvas.width,
            height: this._targetCanvas.height
          },
          [this._offscreenCanvas]);
      }
    }

    if (!this._renderLoop) {

      this._renderLoop = () => {
        if (this._offscreenCanvas) {
          if (this._renderLoop)
            requestAnimationFrame(this._renderLoop);
    
          if (this.visualizationWorker) {
            var bufferLength = this.analyser.fftSize;
            var fftDataArray = new Uint8Array(bufferLength);
            this.analyser.getByteTimeDomainData(fftDataArray);
        
            var bufferLengthAlt = this.analyser.frequencyBinCount;
            var dataArrayAlt = new Uint8Array(bufferLengthAlt);
            this.analyser.getByteFrequencyData(dataArrayAlt);
  
            this.visualizationWorker?.postMessage({
              message: 'render-visualization',
              fftData: fftDataArray,
              frequencyData: dataArrayAlt
            });
          }
        }
        else {
          this._renderLoop = undefined;
        }
      }
 
      this._renderLoop(performance.now());      
    }
  
  }

  private _visualizerStyle: "sine" | "bar" = "bar";

  public get visualizerStyle(): "sine" | "bar" {
    return this._visualizerStyle;
  }
  public set visualizerStyle(value: "sine" | "bar") {

    if (this._visualizerStyle === value) return;

    this._visualizerStyle = value; 

    this._visualizationWorker?.postMessage(
      {
        message: 'set-type',
        type: this._visualizerStyle,
      },
      []);
  }

  protected async setupVisualizationWorker() : Promise<Worker> {

    // Create a new
    const worker = new Worker(new URL('../visualization.worker', import.meta.url));

    return worker;
  }

  protected async setupPitchNode(context: AudioContext) : Promise<PitchNode> {
    
    try {
      await context.audioWorklet.addModule("app/worklets/pitch-processor.js")
    } 
    catch (e) {
      log.error("Failed to add AudioWorklet module", e)
    }
    
    return new PitchNode(context);
  }

  protected async setupVolumeNode(context: AudioContext) : Promise<VolumeNode> {
    
    try {
      await context.audioWorklet.addModule("app/worklets/volume-processor.js")
    } 
    catch (e) {
      log.error("Failed to add AudioWorklet module", e)
    }
    
    return new VolumeNode(context);
  }

  /**
   * enableAudioCapturing()
   * @returns Initialize the audio stream
   */
  enableAudioCapturing(): void {

    if (!navigator?.mediaDevices?.getUserMedia) {
      // No audio allowed
      alert('Sorry, getUserMedia is required for the app.')
      return;
    } 
    else {
      var constraints = {audio: true};
      navigator.mediaDevices.getUserMedia(constraints)
        .then( (stream) => {

          this.stream = stream;
            // Initialize the SourceNode
            this.source = this.audioContext.createMediaStreamSource(stream);
            // Connect the source node to the analyzer
            this.gainNode.gain.value = 1; // this.gainNode.gain.maxValue;

            this.source.connect(this.gainNode);
            var lastNode: AudioNode = this.gainNode;

            if (this.pitchNode) {
              lastNode.connect(this.pitchNode);

              this.pitchNode.port.addEventListener(
                "message",
                ({ data }: { data: PitchMessage }) => {   
                  this.emit('pitch-detected', data);
                },
              );

              this.pitchNode.port.start();  

              lastNode = this.pitchNode;
            }

            if (this.volumeNode) {
              lastNode.connect(this.volumeNode);

              this.volumeNode.port.addEventListener(
                "message",
                ({ data }: { data: VolumeMessage }) => {
                  this.emit('volume', data.volume);
                },
              );

              this.volumeNode.port.start();  

              lastNode = this.volumeNode;
            }

            lastNode.connect(this.analyser);
            lastNode = this.analyser;

            // lastNode?.connect(this.audioContext.destination);
        })
        .catch(function(err) {
          alert('Sorry, microphone permissions are required for the app. Feel free to read on without playing :)')
        });
    }
  }  

  public startListening() {
    this.enableAudioCapturing();
  }

  public stopListening() {
    this.emit('volume', 0);
    
    this.gainNode.disconnect();
    this.analyser.disconnect();
    this.pitchNode?.disconnect();
    this.volumeNode?.disconnect();

    this.source?.disconnect();

    this.stream = undefined;
    this.source = undefined;
  }
}
