import { Injectable } from '@angular/core';
import { Backup } from '../model/backup';
import { InstrumentConnection } from '../model/instrument-connection';
import { TypedEmitter } from 'tiny-typed-emitter';
import { v4 as uuid } from 'uuid'; 
import { ProgressObserver } from '../model/progress-observer';
import { DeviceRegistryService } from './device-registry.service';
import { sleep } from 'bossa-web';
import { UNCDump } from '../model/uncsettings';

export interface BackupServiceEvents {
  'backups-changed': (backups: Backup[]) => void;
}

export const BACKUP_MAJOR: number = 2;

export const BACKUP_MINOR: number = 0;

@Injectable({
  providedIn: 'root'
})
export class BackupService extends TypedEmitter<BackupServiceEvents> {

  private readonly allowMultipleAutomaticBackups: boolean = false;

  constructor(
    private drs : DeviceRegistryService) {

    super();

    window.addEventListener('storage', this.onStorageChangeEvent.bind(this));
  }

  private createBackup(emeoConnection: InstrumentConnection) : Backup {

    let backup = new Backup();

    backup.majorVersion = BACKUP_MAJOR;
    backup.minorVersion = BACKUP_MINOR;
    backup.date = new Date();

    backup.device = Object.assign({}, emeoConnection.deviceInfo );
    backup.device.hardware = Object.assign({}, emeoConnection.deviceInfo.hardware);
    
    if (backup.device) {
      this.drs.registerDevice(backup.device.hardware);
    }
    
    return backup;
  }

  async backupGlobalSettings(emeoConnection: InstrumentConnection, progress: ProgressObserver | undefined = undefined, backup: Backup | undefined = undefined) : Promise<Backup> {

    if (!backup) {
      backup = this.createBackup(emeoConnection);
    }

    let settings = await emeoConnection.loadGlobalSettings();

    backup.globalSettings = settings;

    return backup;
  }

  async restoreGlobalSettings(emeoConnection: InstrumentConnection, backup: Backup, progress: ProgressObserver | undefined = undefined) : Promise<void> {

    if (backup) {
      if (backup.globalSettings) {
        await emeoConnection.sendGlobalSettings(backup.globalSettings);
      }
    } 
  }

  async restoreUNC(emeoConnection: InstrumentConnection, backup: Backup, progress: ProgressObserver | undefined = undefined) : Promise<void> {

    if (backup) {
      if (backup.unc) {
        let dump = backup.unc.uncDump;

        if (dump) {
          let total = dump.recordCount;
          let index = 0;
  
          for(let key in dump.records) {
            let record = dump.records[key];
            await emeoConnection.sendUNCRecord(Number.parseInt(key), record);
            await sleep(50);
  
            if (progress?.progress) {
              progress.progress(index++, total);
            }
          }
        }
      }
    } 
  }     

  async backupEverything(emeoConnection: InstrumentConnection, progress: ProgressObserver | undefined = undefined, backup: Backup | undefined = undefined) : Promise<Backup> {

    backup = await this.backupGlobalSettings(emeoConnection, progress, backup);
    backup = await this.backupUNC(emeoConnection, progress, backup);

    backup = await this.backupSensorThresholds(emeoConnection, progress, backup);
    
    return backup;
  }

  async backupUNC(emeoConnection: InstrumentConnection, progress: ProgressObserver | undefined = undefined, backup: Backup | undefined = undefined) : Promise<Backup> {
    
    if (!backup) {
      backup = this.createBackup(emeoConnection);
    }

    let settings = await emeoConnection.loadUNCTable(progress);

    backup.unc = settings;

    return backup;
  }


  async backupSensorThresholds(emeoConnection: InstrumentConnection, progress: ProgressObserver | undefined = undefined, backup: Backup | undefined = undefined) : Promise<Backup> {
    
    if (!backup) {
      backup = this.createBackup(emeoConnection);
    }

    if (emeoConnection.deviceInfo.hardware.version == 2) {
      // TODO: This is hardwired and should be fixed.
      let settings = await emeoConnection.loadKeyThresholds(progress)

      backup.sensorThresholds = settings;
    }


    return backup;
  }

  async deleteLocalBackup(id: string) : Promise<Backup[]> {

    let backups = await this.getLocalBackups();

    backups = backups.filter( backup => {
      return (backup.id != id);
    });

    window.localStorage.backups = JSON.stringify(backups);

    this.emit('backups-changed', backups);

    return backups;

  }

  async addLocalBackups(newBackups: Backup[]) : Promise<Backup[]> {

    let backups = await this.getLocalBackups();

    for (let backup of newBackups) {

      if (backup.id == undefined || backup.id == '') {
        backup.id = uuid();
      }

      // Avoid storing a backup twice
      backups = backups.filter(item => (item.id != backup.id));
      
      if (backup.automatic && !this.allowMultipleAutomaticBackups) {

        let circuitSerial = backup.device?.hardware.circuitSerialNumber;

        if (circuitSerial) {
          backups = backups.filter(item => (!item.automatic || (item.device?.hardware.circuitSerialNumber != circuitSerial)));
        }
      }

      backups.push(backup);
    }

    backups.sort( (a, b) => (new Date(b.date).getTime() - new Date(a.date).getTime()));

    window.localStorage.backups = JSON.stringify(backups);

    this.emit('backups-changed', backups);

    return backups;

  }

  async addLocalBackup(backup: Backup) : Promise<Backup[]> {
    return await this.addLocalBackups([ backup ]);
  }

  async getLocalBackups() : Promise<Backup[]> {

    if (window.localStorage) {

      let json = window.localStorage.backups;

      if (json) {

        let backups = <Backup[]>JSON.parse(json);
        let modified = false;

        [ backups, modified ] = this.validateBackups(backups);

        if (modified) {
          window.localStorage.backups = JSON.stringify(backups);
        }
        
        return backups;
      }

    }

    return [];
  }

  async onStorageChangeEvent(ev: StorageEvent) {

    if (ev.key == "backups") {
      let backups = await this.getLocalBackups();
      this.emit('backups-changed', backups);
    }
  }

  async export(backup: Backup) : Promise<void> {

    let str = JSON.stringify(backup, undefined, 3);
    this.download("backup", "emeo", str);
  }

  async import() : Promise<void> {
    let backups = await this.getTheFile("EMEO Backup", "emeo");
    
    if (backups) {
      this.addLocalBackups(backups);
    }
  }  

  async getTheFile(description: string, extension: string) : Promise<Backup[] | undefined> {

    const pickerOpts = {
      types: [
        {
          description: description,
          accept: {
            'application/*': [`.${extension}`]
          }
        },
      ],
      excludeAcceptAllOption: true,
      multiple: true
    };

    let result: Backup[] = [];

    try {
      // open file picker
      let fileHandles = await (<any>window).showOpenFilePicker(pickerOpts);

      for (var fileHandle of fileHandles) {
        // get file contents
        const fileData = await fileHandle.getFile();
        const text = await fileData.text();
        const json = JSON.parse(text);

        const backup = <Backup>json;

        result.push(backup);
      }
      
      return result;
    }
    catch (e) {
      return undefined;
    }
  }  

  download(filename: string, extension: string, data: string) {

    const suffix = new Date().toISOString();

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', `${filename}-${suffix}.${extension}`);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }  

  private validateBackups(backups: Backup[]): [ Backup[], boolean ] {

    let modified = false;
    let result: Backup[] = [];

    for (var backup of backups) {
      let [ localResult, localModified ] = this.validateBackup(backup);

      result.push(localResult);
      modified ||= localModified;
    }

    return [ result, modified ];
  }

  private validateBackup(backup: Backup): [ Backup, boolean ] {

    let result = backup;
    let modified = false;

    if (backup.majorVersion !== BACKUP_MAJOR) {

      switch (backup.majorVersion) {
        case 1:
          if (backup.unc) {
            let unc: any = backup.unc;

            if (unc.records !== undefined) {
              modified = true;

              unc.uncDump = new UNCDump(100); 
              unc.ksrDump = new UNCDump(380); 

              for (var address = 0; address < 100; address++) {
                unc.uncDump.recordCount++;
                unc.uncDump.records[ address ] = unc.records[ address ];
              }

              for (var address = 100; address < 380; address++) {
                unc.ksrDump.recordCount++;
                unc.ksrDump.records[ address ] = unc.records[ address ];
              }

              delete unc.records; 
            }
          }
          break;
      }

      if (backup.minorVersion != BACKUP_MINOR) {
        // Do we need some fixup?
      }
    }

    return [ result, modified ];
  }
}
