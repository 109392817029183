import { Component, Input, OnInit } from '@angular/core';
import { UNCType } from 'src/app/model/uncsettings';

@Component({
  template: ''
})
export abstract class FingeringDiagramBaseComponent  {

  constructor() { }

  private _uncType: UNCType = UNCType.FACTORY; 

  get uncType(): UNCType {
    return this._uncType;
  }

  @Input()
  set uncType(value: UNCType) {
    this._uncType = value;
  }

  private _pressed: string[] = [];

  @Input()
  set pressed(value: string[]) {
    this._pressed = value;
  }

  get pressed(): string[] {
    return this._pressed;
  }

  isPressed(key: string) : string {
    return (this._pressed.contains(key) ? 'pressed' :  'unpressed');
  }

  private _isOverride: boolean = false;

  @Input()
  set isOverride(value: boolean) {
    this._isOverride = value;
  }

  get isOverride() : boolean {
    return this._isOverride;
  }
}
