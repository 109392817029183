import { EmeoDiagramSaxophoneV2Component } from "src/app/svg/emeo-saxophone-v2/emeo-diagram-saxophone-v2.component"
import { EmeoSaxophoneComponentV2 } from "src/app/views/emeo-saxophone-v2/emeo-saxophone-v2.component"
import { InstrumentInfoEx } from "../instrument-info-ex";
import { EMEO_SAX_V2_INFO } from "./emeo-sax-v2";

export const EMEO_SAX_V2_INFO_EX : InstrumentInfoEx = { 

  ...EMEO_SAX_V2_INFO,

  fingeringDiagram: EmeoDiagramSaxophoneV2Component,
  instrumentComponent: EmeoSaxophoneComponentV2,
}
