import { HardwareInfo } from "../device-info";
import { InstrumentInfoEx } from "../instrument-info-ex";
import { EMEO_CLARINET_V1_INFO_EX } from "./emeo-clarinet-v1-ex";
import { EMEO_SAX_V1_INFO_EX } from "./emeo-sax-v1-ex";
import { EMEO_SAX_V2_INFO_EX } from "./emeo-sax-v2-ex";

export const INSTRUMENT_INFOS_EX: InstrumentInfoEx[] = [
  
  EMEO_SAX_V1_INFO_EX,
  
  EMEO_SAX_V2_INFO_EX,
  
  EMEO_CLARINET_V1_INFO_EX,

]

export function findDeviceInfo( hw: HardwareInfo) : InstrumentInfoEx | undefined {

  return INSTRUMENT_INFOS_EX.find( info => {
    return (info.devices.find( dev => {
      return (hw.type == dev.type) && (hw.version == dev.version);
    }) != undefined);
  });
}