import { Component, Directive, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ListenerService } from 'src/app/services/listener.service';

@Directive({
  selector: '.pid'
})
export class PidElement {
}

@Component({
  selector: 'app-volume-meter',
  templateUrl: './volume-meter.component.html',
  styleUrls: ['./volume-meter.component.scss']
})
export class VolumeMeterComponent implements OnInit {

  constructor(
    protected ls: ListenerService
  ) { 

    ls.on('volume', (volume) => { this.volume = volume; })
  }

  private _volume: number = 0; 

  @Input()
  set volume(value: number) {
    this._volume = value;

    this.colorPids();
  }

  get volume() : number {
    return this._volume;
  }
  
  ngOnInit(): void {
  }

  @ViewChild('pidContainer') 
  private pidContainer!: ElementRef<HTMLDivElement>;

  
  private colorPids() {

    if (!this.pidContainer) 
      return;
  
    const allPids = Array.from(this.pidContainer.nativeElement.children).filter( (item) => item instanceof HTMLDivElement).map( (item) => item as HTMLElement);

    const numberOfPidsToColor = Math.round(allPids.length * this.volume * 8);

    allPids.forEach( (pid, index) => {
      pid.classList.remove("active", "inactive");
      pid.classList.add((index < (10 - numberOfPidsToColor) ? "inactive" : "active")); 
    });  
  }
}
