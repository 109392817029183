// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unc-list {
  height: 35rem;
  width: 100%;
  overflow: auto;
  padding-left: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/user-defined-keys/key-picker/key-picker.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;AAAJ","sourcesContent":["\n.unc-list {\n    height: 35rem;\n    width: 100%;\n    overflow: auto;\n    padding-left: 2rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
