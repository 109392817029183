import { Injectable } from '@angular/core';
import { HardwareInfo } from '../model/device-info';

@Injectable({
  providedIn: 'root'
})
export class DeviceRegistryService {

  constructor() { 
  }

  public async registerDevice(hardwareInfo: HardwareInfo) : Promise<boolean> {

    let result = false;

    let devices = await this.getLocalDevices();

    let registryDevice = devices.find( info => {
      return (info.circuitSerialNumber == hardwareInfo.circuitSerialNumber);
    });

    if (!registryDevice) {
      devices.push(hardwareInfo);
      await this.storeLocalDevices(devices);
      return true;
    }

    return false;
  }

  async getDeviceInfoForCircuit(serial: string, fallback: HardwareInfo | undefined = undefined ) : Promise<HardwareInfo> {

    let devices = await this.getLocalDevices();

    let registryDevice = devices.find( info => {
      return (info.circuitSerialNumber == serial);
    });

    if (!registryDevice) {
      registryDevice = Object.assign({}, fallback);
      registryDevice.circuitSerialNumber = serial;
    }

    if (registryDevice) {
      return registryDevice;
    }

    throw new Error('Cannot find device information for circuit ' + serial); 
  }

  async deleteDeviceBySerial(serial: string) : Promise<void> {

    let devices = await this.getLocalDevices();

    devices = devices.filter( info => {
      return (info.serialNumber != serial);
    });

    window.localStorage.devices = JSON.stringify(devices);
  }  

  async deleteDeviceByCircuit(serial: string) : Promise<void> {

    let devices = await this.getLocalDevices();

    devices = devices.filter( info => {
      return (info.circuitSerialNumber != serial);
    });

    window.localStorage.devices = JSON.stringify(devices);
  }  

  protected async storeLocalDevices(devices: HardwareInfo[]) : Promise<void> {
    window.localStorage.devices = JSON.stringify(devices);
  }

  protected async getLocalDevices() : Promise<HardwareInfo[]> {

    if (window.localStorage) {

      let json = window.localStorage.devices;

      if (json) {
        let devices = <HardwareInfo[]>JSON.parse(json);
        return devices;
      }
    }

    return [];
  }  
}