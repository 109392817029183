import { Component } from '@angular/core';
import { FingeringDiagramBaseComponent } from '../fingering-diagram-base/fingering-diagram-base.component';

@Component({
  selector: 'app-emeodiagram-saxophone-v2',
  templateUrl: './emeo-diagram-saxophone-v2.component.svg',
  styleUrls: ['./emeo-diagram-saxophone-v2.component.scss']
})
export class EmeoDiagramSaxophoneV2Component extends FingeringDiagramBaseComponent {

  constructor()
  { 
    super();
  }

}
