import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { InstrumentViewComponentBaseComponent } from 'src/app/helpers/instrument-view-component-base.component';
import { EMEO_CLARINET_V1_INFO } from 'src/app/model/instruments/emeo-clarinet-v1';
import { ConnectionService } from 'src/app/services/connection.service';
import { InstrumentInfo } from 'src/app/model/unc-types';

@Component({
  selector: 'app-emeo-clarinet',
  templateUrl: './emeo-clarinet.component.svg',
  styleUrls: ['./emeo-clarinet.component.scss']
})
export class EmeoClarinetComponent extends InstrumentViewComponentBaseComponent implements OnInit, OnDestroy {

  constructor(
    protected cs: ConnectionService,
    protected cd: ChangeDetectorRef) {
  
    super(cs, cd);
  }

  public get instrument() : InstrumentInfo {
    return EMEO_CLARINET_V1_INFO;
  }

}
