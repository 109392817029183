// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  display: table;
}

.form-row {
  display: table-row;
  min-height: 3em;
}

app-backup-info {
  display: block;
  border: 1px solid white;
  padding: 1em;
}

app-backup-info.disabled {
  color: grey;
}

input,
textarea,
select {
  display: table-cell;
  min-width: 15em;
}

textarea {
  width: 30em;
  margin-bottom: 2em;
}

label {
  display: table-cell;
  width: 10em;
  height: 3em;
}`, "",{"version":3,"sources":["webpack://./src/app/views/support-request/support-request.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;EACA,uBAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;AAEF;;AACA;;;EAGE,mBAAA;EACA,eAAA;AAEF;;AACA;EACE,WAAA;EACA,kBAAA;AAEF;;AACA;EACE,mBAAA;EACA,WAAA;EACA,WAAA;AAEF","sourcesContent":["form {\n  display: table;\n}\n\n.form-row {\n  display: table-row;\n  min-height: 3em;\n}\n\napp-backup-info {\n  display: block;\n  border: 1px solid white;\n  padding: 1em;\n}\napp-backup-info.disabled {\n  color: grey;\n}\n\ninput,\ntextarea,\nselect {\n  display: table-cell;\n  min-width: 15em;\n} \n\ntextarea {\n  width: 30em;\n  margin-bottom: 2em;\n}\n\nlabel {\n  display: table-cell;\n  width: 10em;\n  height: 3em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
