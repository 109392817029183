import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentComponentBase } from 'src/app/helpers/instrument-component-base';
import { HardwareTypes, INSTRUMENT_TYPES } from 'src/app/model/device-info';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-instrument-config',
  templateUrl: './instrument-config.component.html',
  styleUrls: ['./instrument-config.component.scss']
})
export class InstrumentConfigComponent extends InstrumentComponentBase implements OnInit {

  readonly INSTRUMENTS = INSTRUMENT_TYPES;

  constructor(
    protected activeModal: NgbActiveModal,
    protected cs: ConnectionService) {

    super(cs);

    let hwInfo = this.currentInstrument?.deviceInfo.hardware;
    let serial = hwInfo?.serialNumber || '';
    let deviceDate = new Date(hwInfo?.deviceDate || Date.now());
    let type = hwInfo?.type || HardwareTypes.Sax;
    let version = hwInfo?.version || 1;
    
    let instrument = this.INSTRUMENTS.find( (value) => (value.type == type) && (value.version == version));

    let instrumentType = instrument?.id || 'EMEO_V1';
    let manufacturingDate = { year: deviceDate.getFullYear(), month: (1+deviceDate.getMonth()), day: deviceDate.getDate() };

    this.settings = new UntypedFormGroup({

      instrumentType: new UntypedFormControl(instrumentType),
      serial: new UntypedFormControl(serial),
      manufacturingDate: new UntypedFormControl(manufacturingDate)
    });
  }

  settings: UntypedFormGroup;

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

  async updateInstrument() {

    let serial = this.settings.controls["serial"].value;
    let type = this.settings.controls["instrumentType"].value || 'EMEO_V1';
    let date = this.settings.controls["manufacturingDate"].value;

    let instrument = this.INSTRUMENTS.find( value => (value.id == type));

    if (!instrument) {
      return;
    }


    if (this.currentInstrument) {
      let info = this.currentInstrument.deviceInfo;

      let hardwareInfo = info.hardware;

      hardwareInfo.serialNumber = serial;
      hardwareInfo.type = instrument.type;
      hardwareInfo.version = instrument.version;
      hardwareInfo.deviceDate = new Date(date.year, date.month, date.day);

      this.currentInstrument.sendHardwareInfo(hardwareInfo);
    }
    
    this.close()
  }
}
