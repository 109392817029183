import { Injectable } from '@angular/core';
import { ConnectionState, InstrumentConnection, InstrumentConnectionMode, InstrumentConnectionType, InstrumentInterface } from 'src/app/model/instrument-connection';
import { TypedEmitter } from 'tiny-typed-emitter';
import { BackupService } from './backup.service';
import { hex } from '../helpers/helper';
import { sleep } from 'bossa-web';
import { FeatureSupportService } from './feature-support.service';
import { rootConnection } from '../helpers/log-config';
import { Mutex } from 'async-mutex';

const log = rootConnection.getChildCategory("connection");


const SERIAL_FILTERS : SerialPortFilter[] = [
  {
    usbVendorId: 0x239A,
    usbProductId: 0x0035
  },

  {
    usbVendorId: 0x2341,
    usbProductId: 0x8054
  },

  {
    usbVendorId: 0x2341,
    usbProductId: 0x0054
  }
];

export class SerialConnection extends InstrumentInterface {

  // define your services and characteristics
  protected _serialReadPromise : Promise<void> | undefined;

  constructor(
    protected _port: SerialPort,
    protected _baudrate: number = 115200) {

    super();

    _port.ondisconnect = this.onDisconnect.bind(this);
  }

  async open(): Promise<void> {
    await this.openPort();
  }

  private onDisconnect(event : Event) {
    this.emit('disconnected');
  }

  private async openPort() {
    if (this.mode === InstrumentConnectionMode.EMEO) {
      await this._port.open({ baudRate: this._baudrate });

      // start the listenForSerial function:
      this._serialReadPromise = this.listenForSerial();
    }    
    else {
      log.debug("Not opening port. Its in FLASH mode");
    }
  }

  protected isReading : boolean = false;

  async listenForSerial() : Promise<void> {
    
    let timeout = 1000;

    // if there's no serial port, return:
    if (!this._port) return;
    
    // while the port is open:
    while (this._port.readable) {
      // initialize the reader:
      this._reader = this._port.readable.getReader();
      this.isReading = true;

      try {
        while (!this.closePorts) {

          const timer = setTimeout( async () => {
            try { 
              // await this._reader?.cancel();
              // this._reader?.releaseLock(); 
            } 
            catch (e) {
              // We will ignore this.
              this.isReading = true;
            }
          }, timeout);

          // read incoming serial buffer:
          const { value, done } = await this._reader.read();
          clearTimeout(timer);

          if (done) {
            break;
          }

          if (this.closePorts) {
            break;
          }

          if (value) {
            // convert the input to a text string:
            this.messageReceived(value);
          }
          
        }
      } 
      catch (error) {
        // if there's an error reading the port:
        console.log(error);
      } 
      finally {
        this._reader.releaseLock();
      }

      if (this.closePorts) {
        break;
      }
    }

    this._reader = undefined;
    this.isReading = false;
  }

  override equals(connection: InstrumentInterface): boolean {
    if (connection instanceof SerialConnection) {
      return (connection._port === this._port);
    }

    return false;
  }

  protected buffer : Uint8Array = new Uint8Array();

  private messageReceived(data : Uint8Array) {
    log.debug("Serial Received: " + hex(data));

    if (this.buffer.length > 0) {
      let temp = this.buffer;
      this.buffer = new Uint8Array( this.buffer.length + data.length );
      this.buffer.set( temp, 0);
      this.buffer.set( data, temp.length);
    }
    else {
      this.buffer = data;
    }
    
    let messages : Uint8Array[] = [];

    let index = 0;
    while (index < this.buffer.length) {
      let ch = this.buffer[ index ];
      
      var end = index + 3;

      if (ch == 0xf0) { // SysEx message
        end = this.buffer.indexOf( 0xf7, index ) + 1;

        if (end <= index) {
          log.trace("SysEx message truncated");
          break;
        }
      }

      if ((end != 0) && (end <= this.buffer.length)) {
        let msg = new Uint8Array( this.buffer.slice( index, end ));

        messages.push( msg );
        
        index = end;
      }
      else {
        break;
      }

    }
    
    this.buffer = new Uint8Array( this.buffer.slice(index) );

    messages.forEach( msg => {
      log.debug("MIDI Message: " + hex(msg));
      let receivedTime = Date.now();
  
      this.emit('data-received', msg, receivedTime);
    });
  }

  private readonly _mutex = new Mutex();
      
  private  _reader : ReadableStreamDefaultReader<Uint8Array> | undefined;
  private  _writer : WritableStreamDefaultWriter<Uint8Array> | undefined;

  async sendMessage(data: number[]) : Promise<void> {
    
    if (this.closePorts) return;

    if (!this._port) return;

    if (!this._writer) {
      // while the port is open:
      if (this._port.writable) {
        // initialize the writer:
        this._writer = this._port.writable.getWriter();
      }
    }
  
    let ua = Uint8Array.from( data ); 

    const release = await this._mutex.acquire();
 
    try {
      log.debug("Serial Sending: " + hex(ua));

      if (this._writer) {
        await this._writer.write( ua );
      }
      else {
        log.debug("Writer not available.");
      }

      release();
    } 
    catch (e) {
      release();
      throw e;
    }
  }  

  protected closePorts : boolean = false;

  override async close() {
    
    this.closePorts = true;

    try {
      await this.closeStreams();
    }
    finally {
      var isOpen = (this._port.readable || this._port.writable);
      
      if (isOpen) {
        if (this._port.readable?.locked || this._port.writable?.locked) {
          log.error("Cannot close open serial port.");
        }
        else {
          await this._port.close();
        }
      }
    }
  }

  override get port(): SerialPort {
    return this._port;
  }

  override get type(): InstrumentConnectionType {
    return InstrumentConnectionType.SERIAL;
  }

  override get mode(): InstrumentConnectionMode {
    if (this._port) {
      let info = this._port.getInfo();

      if ((info.usbVendorId === 0x2341) && (info.usbProductId === 0x0054)) {
        return InstrumentConnectionMode.FLASH;
      }

      if ((info.usbVendorId === 0x2341) && (info.usbProductId === 0x8054)) {
        return InstrumentConnectionMode.EMEO;
      }
    }

    return InstrumentConnectionMode.EMEO;
  }

  async enableFlashMode(): Promise<void> {

    await this._port.open({
      dataBits: 8,
      stopBits: 1,
      parity: 'none',
      bufferSize: 63,
      flowControl: 'hardware',
      baudRate: 1200 });

    await sleep(500);

    // await this.close();
  }

  private async closeStreams() : Promise<void> {

    let cp = this.closePorts;

    this.closePorts = true;

    if (this._port.readable) {
      if (this._port.readable.locked) {
        this._reader?.releaseLock();
      }
    }

    while (this.isReading) {
      await sleep(50);
    }

    if (this._port.writable) {
      this._writer?.releaseLock();
    }   
    
    this.closePorts = cp;
  }
}


export interface ConnectionServiceEvents {
  'sysex': (data: Uint8Array, receivedTime: number | undefined) => void;
  'midi': (data: Uint8Array, receivedTime: number | undefined) => void;
  'instrument-connect': (instrument: InstrumentConnection) => void; 
  'instrument-disconnect': (instrument: InstrumentConnection) => void; 
}

@Injectable({
  providedIn: 'root'
})
export class ConnectionService extends TypedEmitter<ConnectionServiceEvents> {


  constructor(
    protected _fs: FeatureSupportService,
    protected backupService: BackupService) {

    super();

    this.prepareConnection();
  }

    
  private prepareConnection() {
    if (navigator.serial) {
      navigator.serial.addEventListener("connect", this.serialConnect.bind(this));
      navigator.serial.addEventListener("disconnect", this.serialDisconnect.bind(this));
    }
  }

  protected _autoconnectEnabled : boolean = true;

  public disableAutoconnect() {
    this._autoconnectEnabled = false;
  }

  public enableAutoconnect() {
    this._autoconnectEnabled = true;
  }

  // this event occurs every time a new serial device
  // connects via USB:
  private serialConnect(event: Event) {
    log.info("Serial Connect");

    if (event.target) {
      let port : SerialPort = event.target as SerialPort;

      log.info("Serial port available");

      if (!this._currentInstrumentConnection) {
        log.debug("Trying to connect new serial port.");
        this.openSerialPort(port).then( connected => {
          log.debug("Connection established");
        });
      }
      else {
        log.info("Instrument connection already exists.");
      }
    }
  }

  // this event occurs every time a new serial device
  // disconnects via USB:
  private serialDisconnect(event: Event) {
    log.info("Serial Disconnect");

    if (event.target) {
      let port : SerialPort = event.target as SerialPort;

      let connection = new SerialConnection(port, this._fs.baudrate);
    
      // Do not fire changes if everything is the same!
      if (this._currentInstrumentConnection && this._currentInstrumentConnection.isSameConnection(connection)) {
        this.disconnect(true).then( () => { });
      }      
    }
  }


  async disconnect(isDisconnected: boolean = false) {
    log.info("EMEO Lost");
    let oldConnection = this._currentInstrumentConnection;
    this._currentInstrumentConnection = undefined;

    if (oldConnection) {
      this.emit('instrument-disconnect', oldConnection);

      if (!isDisconnected) {
        await oldConnection.disconnect();
      }
    }
  }
  
  get hasEMEOConnection() : boolean {
    return (this._currentInstrumentConnection != undefined);
  }

  private _currentInstrumentConnection?: InstrumentConnection;

  get emeoConnection() : InstrumentConnection | undefined {
    return this._currentInstrumentConnection;
  }

  async tryReconnection() : Promise<boolean> {

    log.debug("Trying reconnection.");

    if (!navigator.serial) {
      log.debug("No serial.");
      return false;
    }

    let ports = await navigator.serial.getPorts();
    var connected = false;

    for (var port of ports) {
      let info = port.getInfo();

      log.info("Port is : " + hex([ info.usbProductId || 0 ]) + " " + hex([ info.usbVendorId || 0 ]));

      let hit = SERIAL_FILTERS.find( (filter) => {
        return (filter.usbProductId === info.usbProductId) && (filter.usbVendorId === info.usbVendorId);
      });

      if (hit) {
        log.debug("Port is acceptable.");
        connected = await this.openSerialPort(port);
      }

      if (connected) {
        break;
      }
    }

    return connected;
  }


  async establishSerialConnection() : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.doEstablishSerialConnectionEX()
            .then( (value) => resolve(value) )
            .catch(e => { 
              log.error(e);
              reject(e);
            });
    });
  }

  async doEstablishSerialConnectionEX() : Promise<boolean> {

    log.info("Start Serial Connect");

    const status: boolean = await new Promise<boolean>((resolve, reject) => {
      
      navigator.serial
        .requestPort(
          { 
            filters: SERIAL_FILTERS 
        })
        .catch( e => {
          log.warn("Cancelled device connection request", e);
          return undefined;
        })
        .then( port => {

          if (port) {
            this.openSerialPort(port).then (connected => {
              resolve(connected);
            });
          }
          else {
            resolve(false);
          }
        })
        .catch( e => { throw new Error("Could not establish serial connection with device"); } )
        .finally( () => {  } )
      });

    return status;
  }

  async openSerialPort( port: SerialPort ) : Promise<boolean> {
    let connection = new SerialConnection(port, this._fs.baudrate);
    
    // Do not fire changes if everything is the same!
    //if (this._currentInstrumentConnection && this._currentInstrumentConnection.isSameConnection(connection)) {
    //  return true;
    //}

    try {
      if (this._autoconnectEnabled) {
        await connection.open();
      }

      log.info("EMEO Found");
      this._currentInstrumentConnection = new InstrumentConnection(connection, this._fs);
      this._currentInstrumentConnection.on('statechange', this.onInstrumentStateChanged.bind(this));
  
      this.emit('instrument-connect', this._currentInstrumentConnection);
  
      return true; 
    }
    catch (e) {
      log.error("Error occurred %s", e);
      return false;
    }
  }

  private onInstrumentStateChanged(connection: InstrumentConnection, state: ConnectionState) {
    log.debug("onInstrumentStateChanged", state);

    if (state === ConnectionState.ERROR) {
      this.disconnect();
    }
  }
}
