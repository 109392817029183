import { Component, OnInit } from '@angular/core';
import { ListenerService } from 'src/app/services/listener.service';

@Component({
  selector: 'app-listener-control',
  templateUrl: './listener-control.component.html',
  styleUrls: ['./listener-control.component.scss']
})
export class ListenerControlComponent implements OnInit {

  constructor(protected ls: ListenerService) { }

  ngOnInit(): void {
  }


  private _isListening: boolean = false;

  get isListening() : boolean {
    return this._isListening;
  }

  public toggleMicrophone() {

    this._isListening = !this._isListening;

    if (this._isListening) {
      this.ls.startListening();
    }
    else {
      this.ls.stopListening();
    }
  }
}
