import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pressure-indicator',
  templateUrl: './pressure-indicator.component.svg',
  styleUrls: ['./pressure-indicator.component.scss']
})
export class PressureIndicatorComponent implements OnInit {

  @Input()
  resistance: number = 10;

  constructor() { }

  ngOnInit(): void {
  }

  public get offset() : number {
    return ( this.resistance == 10 ? 0 : 
           ( this.resistance < 5 ? (this.resistance / 5) : (this.resistance - 5) / 5));
  }  
}
