import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { InstrumentViewComponentBaseComponent } from 'src/app/helpers/instrument-view-component-base.component';
import { EMEO_SAX_V2_INFO } from 'src/app/model/instruments/emeo-sax-v2';
import { ConnectionService } from 'src/app/services/connection.service';
import { InstrumentInfo } from 'src/app/model/unc-types';

@Component({
  selector: 'app-emeo-saxophone-v2',
  templateUrl: './emeo-saxophone-v2.component.svg',
  styleUrls: ['./emeo-saxophone-v2.component.scss']
})
export class EmeoSaxophoneComponentV2 extends InstrumentViewComponentBaseComponent implements OnInit, OnDestroy {

  constructor(
    protected cs: ConnectionService,
    protected cd: ChangeDetectorRef) {

    super(cs,cd);
  }

  public get instrument() : InstrumentInfo {
    return EMEO_SAX_V2_INFO;
  }
}
