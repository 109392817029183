import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-backup-restore',
  templateUrl: './backup-restore.component.html',
  styleUrls: ['./backup-restore.component.scss']
})
export class BackupRestoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
