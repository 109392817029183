import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirmwareInfo } from 'src/app/model/firmware-info';
import { HardwareTypes } from 'src/app/model/device-info';
import { FeatureSupportService } from './feature-support.service';
import { defaultIfEmpty, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { firstValueFrom, forkJoin } from 'rxjs';
import { compareVersions } from '../helpers/version-helpers';

@Injectable()
export class FirmwareService {

  private clientUrl = 'assets/updates' ;

  constructor(
    private fs: FeatureSupportService,
    private http: HttpClient) { 

      if (environment.releasesUrl) {
        this.clientUrl = environment.releasesUrl;
      }
    }


  async getFirmwareVersions(hardwareType: HardwareTypes | undefined = undefined, hardwareVersions: number[] = [] ): Promise<FirmwareInfo[]> {

    var urls = [ `${this.clientUrl}/updates.json` ];

    var releaseTypes = [ 'release', 'hotfix' ]

    if (this.fs.developmentMode) {
      releaseTypes.push( 'dev' )
      releaseTypes.push( 'alpha' )
    }
    
    // We will also pull the builds
    if (this.fs.hasFeature('builds')) {
      releaseTypes.push( 'build' )
      urls.push(`${this.clientUrl}/builds.json`);
    }

    if (this.fs.maintenanceMode) {
      releaseTypes.push( 'test')
    }

    let firmwares: FirmwareInfo[] = []

    for (var url of urls) {

      let downloader$ = this.http
          .get<FirmwareInfo[]>(url, { responseType: 'json' })
          .pipe(
            defaultIfEmpty([])
          )
          .pipe(
            map( (infos: FirmwareInfo[]) => {
              return infos
                        .filter( (info: FirmwareInfo) => this.filterType(info.type))
                        .filter( (info: FirmwareInfo) => this.filterHardware(info, hardwareType, hardwareVersions))
                        .filter( (info: FirmwareInfo) => releaseTypes.contains( info.type ));
            })
          );

      let temp = await firstValueFrom(downloader$);
      firmwares.push(...temp)
    }

    return firmwares;
  }
  
  protected filterHardware(info: FirmwareInfo, hardwareType: HardwareTypes | undefined = undefined, hardwareVersions: number[] = [] ) : Boolean {

    if (hardwareType) {
      if (info.hardwareTypes.indexOf(hardwareType) >= 0) {

        if (hardwareVersions.length > 0) {
          if (info.hardwareVersions.find( (value) => (hardwareVersions.indexOf(value) >= 0))) {
            return true;
          }
        }
        else {
          return true;
        }
      }

      return false;
    }

    return true;
  }

  getFirmwareImage(name: string): Promise<Blob | undefined> {

    const url = `${this.clientUrl}/${name}`;
		return this.http
      .get(url, { responseType: 'blob' }).toPromise();
  }

  private filterType(type: 'alpha' | 'beta' | 'release' | 'hotfix') : boolean {

    switch (type) {
      case 'alpha':
        if (!(this.fs.developmentMode || this.fs.alphaMode)) return false;
        break;
        
      case 'beta':
        if (!(this.fs.developmentMode || this.fs.maintenanceMode || this.fs.betaMode)) return false;
        break;

      case 'hotfix':
      case 'release':
        // This is always OK
        break;
    }

    return true;
  }

  findUpdates(hardwareType: HardwareTypes, hardwareVersion: number, firmwareVersion: string, firmwares: FirmwareInfo[], allowOnlyLatest: boolean = true, allowDowngrades: boolean = false): FirmwareInfo[] {

    let result = firmwares.filter( (value: FirmwareInfo) => {

      if (!value.hardwareTypes.includes(hardwareType)) {
        return false;
      }

      if (!value.hardwareVersions.includes(hardwareVersion)) {
        return false;
      }

      if (!allowDowngrades) {
        return (compareVersions(value.version, firmwareVersion) < 0);
      } 

      return true;
    });

    result.sort( this.compareFirmwareVersions.bind(this) );
    
    if (allowOnlyLatest) {
      result = (result.length > 0 ? [ result[0] ] : [] );
    }

    return result;
  }

  private compareFirmwareVersions(fw1: FirmwareInfo, fw2: FirmwareInfo) : number {
    return compareVersions(fw1.version, fw2.version);
  }
  
}
